import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CourseCategoryForm from "../../../components/Forms/CourseCategoryForm";
import {
  GetCourseCategory,
  UpadateCourseCategory,
} from "../../../store/coursecategory-store/coursecategory-actions";

const CourseCategoryEdit = () => {
  const param = useParams();
  const dispatch = useDispatch();
  const courseCategory = useSelector(
    (state) => state.courseCategory.courseCategory
  );
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(GetCourseCategory(param.courseCategoryId, auth.UserInfo.email));
  }, [param.courseCategoryId, auth.UserInfo.email, dispatch]);

  return (
    <>
      <CourseCategoryForm
        actionCourseCategory={UpadateCourseCategory}
        courseCategory={courseCategory}
      />
    </>
  );
};
export default CourseCategoryEdit;
