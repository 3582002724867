import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Message } from "primereact/message";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { chargeActions } from "../../store/charge-store/charge-slice";
import { uiActions } from "../../store/ui-slice";
import { InputTextarea } from "primereact/inputtextarea";
import { useEffect } from "react";

let restartPage = false;

const ChargeForms = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isValid = useSelector((state) => state.charge.chargeValidation);
  const auth = useSelector((state) => state.auth);
  const charge = props.charge;
  const notification = useSelector((state) => state.ui.notification);

  const onChangeHandler = (id, value) => {
    dispatch(chargeActions.modifyPropertyValue({ id, value }));
    if (value !== "") {
      dispatch(chargeActions.setFieldValid({ id, value: true }));
    } else {
      dispatch(chargeActions.setFieldValid({ id, value: false }));
    }
  };
  useEffect(() => {
    if (
      notification &&
      notification.status === "success" &&
      charge.id > 0 &&
      restartPage
    ) {
      dispatch(chargeActions.restartState());
      window.location.href = "/charges";
    }
  }, [charge.id, dispatch, navigate, notification]);
  const onSubmitHandler = (event) => {
    event.preventDefault();
    if (charge.name === "") {
      dispatch(chargeActions.setFieldValid({ id: "name", value: false }));
      dispatch(
        uiActions.showNotification({
          status: "warning",
          title: "Advertencia",
          message: "Existen valores invalidos, favor de revisar.",
        })
      );
    } else {
      dispatch(props.actionCharge(charge, auth.UserInfo.email));
      restartPage = true;
    }
  };
  const getFormErrorMessage = (id) => {
    return !isValid[id].isValid ? (
      <Message severity="warn" text={isValid[id].errorMessage} />
    ) : (
      <small className="p-warn">&nbsp;</small>
    );
  };
  return (
    <>
      <Card
        style={{
          backgroundColor: "#142f47",
          marginLeft: "1%",
          marginTop: "-16px",
          boxShadow: "none",
          height: "100%",
          width: "99%",
        }}
      >
        <form onSubmit={onSubmitHandler}>
          <div className="formgrid grid">
            <div className="field col-12 md:col-12">
              <h2 className="card-header" style={{ color: "#fff" }}>
                Cargo:
                <span style={{ color: "lightskyblue", marginLeft: "1%" }}>
                  {charge && charge.name && charge.name.toUpperCase()}
                </span>
              </h2>
            </div>
            <div className="field col-12 md:col-6">
              <label
                htmlFor="name"
                style={{ fontSize: "large", color: "#35c4dc" }}
              >
                Cargo:
              </label>
              <InputText
                id="name"
                value={charge.name}
                onChange={(e) => onChangeHandler("name", e.target.value)}
                placeholder=""
                style={{ width: "100%" }}
              />
              <div>{getFormErrorMessage("name")}</div>
            </div>
            <div className="field col-12 md:col-8">
              <label
                htmlFor="description"
                style={{ fontSize: "large", color: "#35c4dc" }}
              >
                Descripción:
              </label>
              <InputTextarea
                id="description"
                rows={4}
                cols={10}
                value={charge.description}
                onChange={(e) => onChangeHandler("description", e.target.value)}
                placeholder=""
                style={{ width: "100%" }}
              />
            </div>
          </div>
          <div className="formgrid grid">
            <Button
              rounded
              text
              raised
              style={{ backgroundColor: "#fff" }}
              label="Regresar"
              icon="pi pi-arrow-left"
              className="p-button-danger"
              onClick={() => {
                navigate("/charges");
              }}
            />
            <Button
              rounded
              text
              raised
              style={{ backgroundColor: "#fff", marginLeft: "1%"}}
              label="Guardar"
              className="p-button-success"
              icon={
                notification && notification.status === "pending"
                  ? "pi pi-spin pi-spinner"
                  : "pi pi-save"
              }
              disabled={notification && notification.status === "pending"}
            />
          </div>
        </form>
      </Card>
    </>
  );
};
export default ChargeForms;
