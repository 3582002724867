import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Message } from "primereact/message";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Dropdown } from "primereact/dropdown";
import { sponsorActions } from "../../store/sponsor-store/sponsor-slice";
import { uiActions } from "../../store/ui-slice";

let runGetActiveJustOne = true;
const SponsorshipForm = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isValid = useSelector(
    (state) => state.sponsorship.sponsorshipValidation
  );
  const auth = useSelector((state) => state.auth);
  const sponsorship = { ...props.sponsorship };
  const notification = useSelector((state) => state.ui.notification);
  const conferences = useSelector((state) => state.conference.conferences);
  const [conferenceSelected, setConferenceSelected] = useState("");

  let conferenceSelectOptions = [];
  if (conferences) {
    conferenceSelectOptions = conferences.map((conference) => {
      return { code: conference.id, name: conference.name };
    });
  }
  useEffect(() => {
    if (runGetActiveJustOne) {
      // dispatch(GetConferencesActive(auth.UserInfo.email));
      runGetActiveJustOne = false;
    }
  }, [dispatch, auth.UserInfo.email]);
  useEffect(() => {
    if (sponsorship.Id >= 1 && notification.status !== "pending") {
      navigate("/admin/sponsorships");
    }
  }, [
    dispatch,
    auth.UserInfo.email,
    sponsorship.Id,
    notification,
    navigate,
  ]);

  const onChangeHandler = (id, value) => {
    dispatch(sponsorActions.modifyPropertyValue({ id, value }));
    if (value !== "") {
      dispatch(sponsorActions.setFieldValid({ id, value: true }));
    } else {
      dispatch(sponsorActions.setFieldValid({ id, value: false }));
    }
  };
  const onChangeUsersHandler = (id, value) => {
    dispatch(sponsorActions.modifyPropertyValue({ id, value }));
  };

  const getNameConference = (code) => {
    if (code !== undefined && code !== "") {
      const value = conferenceSelectOptions.find((c) => c.code === code);
      return value.name;
    }
  };

  const onSubmitHandler = (event) => {
    event.preventDefault();

    if (!isValid.isValid) {
      dispatch(
        uiActions.showNotification({
          status: "warning",
          title: "Advertencia",
          message: "Existen valores invalidos, favor de revisar.",
        })
      );
    } else {
      sponsorship.ConferenceId = conferenceSelected;
      dispatch(props.actionSponsorship(sponsorship, auth.UserInfo.email));
    }
  };
  const getFormErrorMessage = (id) => {
    return isValid !== undefined &&
      isValid[id] !== undefined &&
      !isValid[id].isValid ? (
      <Message severity="warn" text={isValid[id].errorMessage} />
    ) : (
      <small className="p-warn">&nbsp;</small>
    );
  };

  return (
    <>
      <Card
        style={{
          backgroundColor: "#142f47",
          marginTop: "-16px",
          boxShadow: "none",
          height: "100%",
          width: "99%",
        }}
      >
        <div className="mb-4 bg-blue-900 p-3">
          <form onSubmit={onSubmitHandler}>
            <div className="field col-12">
              <label
                htmlFor="type"
                style={{ fontSize: "xx-large", color: "#fff" }}
              >
                Evento
              </label>
              <Dropdown
                value={{
                  code: conferenceSelected,
                  name: getNameConference(conferenceSelected),
                }}
                onChange={(e) => {
                  setConferenceSelected(e.value.code);
                }}
                options={conferenceSelectOptions}
                optionLabel="name"
                placeholder="Seleccionar Congreso"
                filter
                style={{
                  width: "100%",
                  height: "65px",
                  borderRadius: "10px",
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                }}
              />
            </div>
            <div className="formgrid grid">
              <div className="field col-12 md:col-6">
                <label
                  htmlFor="CompanyName"
                  style={{ fontSize: "large", color: "#35c4dc" }}
                >
                  Nombre Empresa
                </label>
                <InputText
                  id="CompanyName"
                  value={sponsorship.CompanyName}
                  onChange={(e) =>
                    onChangeHandler("CompanyName", e.target.value)
                  }
                  placeholder="Nombre Empresa"
                  style={{ width: "100%" }}
                />
                <div>{getFormErrorMessage("CompanyName")}</div>
              </div>
              <div className="field col-12 md:col-6">
                <label
                  htmlFor="Email"
                  style={{ fontSize: "large", color: "#35c4dc" }}
                >
                  Correo de contacto
                </label>
                <InputText
                  id="Email"
                  type="email"
                  value={sponsorship.Email}
                  onChange={(e) => onChangeHandler("Email", e.target.value)}
                  placeholder="example@example.com"
                  style={{ width: "100%" }}
                />
                <div>{getFormErrorMessage("Email")}</div>
              </div>
              <div className="field col-12 md:col-2">
                <label
                  htmlFor="MaxUsers"
                  style={{ fontSize: "large", color: "#35c4dc" }}
                >
                  Máximo de usuarios
                </label>
                <InputNumber
                  id="MaxUsers"
                  min={1}
                  max={5}
                  value={sponsorship.MaxUsers}
                  onChange={(e) => onChangeUsersHandler("MaxUsers", e.value)}
                  style={{ width: "100%" }}
                />
              </div>

              <div className="field col-12 md:col-12">
                <Button
                  label="Guardar"
                  className="p-button-success"
                  icon={
                    notification && notification.status === "pending"
                      ? "pi pi-spin pi-spinner"
                      : "pi pi-save"
                  }
                  disabled={notification && notification.status === "pending"}
                />
                <Button
                  label="Regresar"
icon="pi pi-arrow-left"
                  className="p-button-danger"
                  style={{ marginLeft: "1%" }}
                  onClick={() => {
                    navigate("/admin/sponsorships");
                  }}
                />
              </div>
            </div>
          </form>
        </div>
      </Card>
    </>
  );
};
export default SponsorshipForm;
