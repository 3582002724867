import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import "primeicons/primeicons.css";
import "./Loading.css";

const Loading = (props) => {
  const [visible] = useState(props.visible);

  return (
    <div className="card flex justify-content-center">
      <Dialog visible={visible} style={{ width: "25vw" }}>
        <div className="grid">
          <div className="col"></div>
          <div className="col">
            <div className="loader"></div>
          </div>
          <div class="col"></div>
        </div>
        <div className="grid">
          <div
            className="col "
            style={{ textAlign: "center", marginTop: "5px" }}
          >
            <span
              style={{ fontSize: "20px", color: "#4AA9DC", fontWeight: "bold" }}
            >
              Espere un momento cargando información
            </span>
          </div>
        </div>
        <div className="grid">
          <div
            className="col "
            style={{ textAlign: "center", marginTop: "5px" }}
          >
            {props.children && <div className="col">{props.children}</div>}
          </div>
        </div>
      </Dialog>
    </div>
  );
};
export default Loading;
