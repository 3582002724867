import { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Card } from "primereact/card";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

const ABADatatableExpandable = (props) => {
  const [globalFilter2, setGlobalFilter2] = useState(null);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [expandedRows, setExpandedRows] = useState(null);

  const allowExpansion = (rowData) => {
    return rowData.orders.length > 0;
  };

  const rowExpansionTemplate = (data) => {
    return (
      <div className="p-3" style={{ border: "1px solid" }}>
        <h3>Participantes orden: {data.id}</h3>
        <div className="field col-12 md:col-12">
          <i className="pi pi-search" style={{ margin: "8px" }}></i>
          <input
            type="search"
            className="p-inputtext p-component p-column-filter"
            onChange={(e) => setGlobalFilter(e.target.value)}
            placeholder="Buscar"
            style={{ width: "98%" }}
          />
        </div>

        <DataTable
          stripedRows
          paginator
          tableStyle={{ minWidth: "50rem" }}
          filterDisplay={props.dontshowMenu === undefined ? "menu" : ""}
          globalFilter={globalFilter}
          value={data.orders}
          dataKey={props.title}
          rows={props.rows}
          rowsPerPageOptions={props.rowsPerPageOptions}
          emptyMessage={`No se encontraron registros`}
        >
          {props.columExpandable.map((col, i) => (
            <Column
              key={i}
              field={col.field !== undefined ? col.field : ""}
              header={col.header}
              filterMatchMode={col.filterMatchMode}
              body={col.body}
              sortable={col.sortable !== undefined ? col.filter : true}
              filter={col.filter !== undefined ? col.filter : true}
              style={{ width: props.width ? props.width : "35%" }}
            />
          ))}
          {props.actionsExpandable.length > 0 &&
            props.actionsExpandable.map((b, i) => {
              return (
                <Column
                  key={i}
                  body={(rowData) => {
                    return <div className="flex">{b(rowData)}</div>;
                  }}
                  style={{ textAlign: "center", width: "2%" }}
                />
              );
            })}
        </DataTable>
      </div>
    );
  };
  const header = (
    <>
      <Card
        className="mt-3  bg-blue-900"
        title={props.subtitle}
        style={{
          color: "#fff",
          width: "100%",
          marginBottom:
            props.marginBottom !== undefined ? props.marginBottom : "10px",
        }}
      ></Card>
      <div className="formgrid grid">
        <div className="field col-12 md:col-12">
          <i
            className="pi pi-search"
            style={{ margin: "8px", marginTop: "2%" }}
          ></i>
          <input
            type="search"
            className="p-inputtext p-component p-column-filter"
            onChange={(e) => {
              const id = parseInt(e.target.value);
              if (!isNaN(id)) {
                setGlobalFilter2(e.target.value);
              } else {
                setGlobalFilter2(e.target.value);
              }
            }}
            placeholder="Buscar"
            style={{ width: "89%" }}
          />
        </div>
        <div className="field col-12 md:col-12">{props.actionsTable}</div>
      </div>
    </>
  );

  return (
    <>
      <Card
        className="mt-3  bg-blue-900"
        title={props.title}
        style={{
          color: "#fff",
          height: props.height !== undefined ? props.height : "100%",
          width: "100%",

          marginBottom:
            props.marginBottom !== undefined ? props.marginBottom : "0px",
        }}
      >
        <DataTable
          stripedRows
          paginator
          tableStyle={{ minWidth: "50rem" }}
          filterDisplay={props.dontshowMenu === undefined ? "menu" : ""}
          expandedRows={expandedRows}
          onRowToggle={(e) => setExpandedRows(e.data)}
          rowExpansionTemplate={rowExpansionTemplate}
          globalFilter={props.filter ? props.filter : globalFilter2}
          dataKey="id"
          header={header}
          value={props.values}
          rows={props.rows}
          rowsPerPageOptions={props.rowsPerPageOptions}
          emptyMessage={`No se encontraron registros`}
        >
          <Column expander={allowExpansion} style={{ width: "1%" }} />
          {props.Columns.map((col, i) => (
            <Column
              key={i}
              field={col.field !== undefined ? col.field : ""}
              header={col.header}
              filterMatchMode={col.filterMatchMode}
              body={col.body}
              sortable={col.sortable !== undefined ? col.filter : true}
              filter={col.filter !== undefined ? col.filter : true}
              style={{ width: props.width ? props.width : "35%" }}
              hidden={col.field !== "participantsNames" ? col.hidden : true}
            />
          ))}

          {props.actions.length > 0 &&
            props.actions.map((b, i) => {
              const col = (
                <Column
                  key={i}
                  body={(rowData) => {
                    return <div>{b(rowData)}</div>;
                  }}
                />
              );

              return col;
            })}
        </DataTable>
      </Card>
    </>
  );
};
export default ABADatatableExpandable;
