import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GetPaymentMethod, UpadatePaymentMethod } from "../../../store/paymentmethod-store/paymentmethod-actions";
import PaymentMethodForm from "../../../components/Forms/PaymentMethodForm";

const PaymentMethodEdit = () => {
  const param = useParams();
  const dispatch = useDispatch();
  const paymentMethod = useSelector((state) => state.paymentMethod.paymentMethod);
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(GetPaymentMethod(param.paymentMethodId, auth.UserInfo.email));
  }, [param.paymentMethodId, auth.UserInfo.email, dispatch]);

  return (
    <>
      <PaymentMethodForm actionPaymentMethod={UpadatePaymentMethod} paymentMethod={paymentMethod} />
    </>
  );
};
export default PaymentMethodEdit;
