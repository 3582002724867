import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  file: {
    isLoad: false,
  },
};

const fileSlice = createSlice({
  name: "file",
  initialState,
  reducers: {
    setIsload(state, actions) {
      state.file = actions.payload.file;
    },
  },
});

export const fileActions = fileSlice.actions;
export default fileSlice;
