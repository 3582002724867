import selloABA from "../assets/img/Sello_ABA.png";
import ABAColor from "../assets/img/ABAColor.png";

const Quote = (props) => {

  return (
    <>
      {props.quote !== undefined &&
        props.quote.billQuoteNumber !== undefined && (
          <div
            className="card-container"
            style={{ border: "solid 1px", paddingTop: "10px" }}
          >
            <div className="formgrid grid" style={{ width: "100%" }}>
              {/* Header */}
              <div className="field col-12 md:col-4"></div>
              <div className="field col-12 md:col-4 ml-3">
                <h1 style={{ fontSize: "xxx-large", color: "black" }}>
                  Cotización
                </h1>
              </div>
              <div
                className="field col-12 md:col-3"
                style={{ paddingLeft: "150px" }}
              >
                <img
                  src={ABAColor}
                  alt="Sample"
                  className="card-image"
                  width={125}
                />
              </div>
            </div>
            {/* Address */}
            <div className="formgrid grid" style={{ width: "100%" }}>
              <div
                className="field col-12 md:col-4"
                style={{ marginLeft: "2%" }}
              >
                <span style={{ fontWeight: "bold", color: "black" }}>
                  Av. Winsto Churchill Esq. Paseo de los Locutores, Piso 6,
                  Torre Habitat Center. Sto. Dgo.{" "}
                </span>
                <br />
                <span style={{ fontWeight: "bolder", color: "black" }}>
                  Tel:
                </span>
                <span style={{ color: "black" }}> (809) 541-2211 Ext.0000</span>{" "}
                <br />
                <span style={{ fontWeight: "bolder", color: "black" }}>
                  RNC:{" "}
                </span>
                <span style={{ color: "black" }}>401053365 </span>
              </div>
            </div>

            <br />
            {/* QuoteInfo */}

            <div className="formgrid grid" style={{ width: "100%" }}>
              <div
                className="field col-12 md:col-6"
                style={{
                  fontWeight: "bolder",
                  color: "black",
                  border: "solid 0.5px",
                  borderTop: "solid 0.5px",
                  borderBottom: "none",
                  backgroundColor: "#c0c0c0",
                  width: "180px",
                  height: "30px",
                  marginLeft: "2.9%",
                  marginTop: "-16px",
                  textAlign: "center",
                }}
              >
                Num. Orden
              </div>
              <div
                className="field col-12 md:col-6"
                style={{
                  color: "black",
                  border: "solid 0.5px",
                  borderTop: "solid 0.5px",
                  marginTop: "-16px",
                  width: "180px",
                  height: "30px",
                  textAlign: "center",
                }}
              >
                {props.quote.billQuoteNumber}
              </div>
            </div>
            <div className="formgrid grid" style={{ width: "100%" }}>
              <div
                className="field col-12 md:col-6"
                style={{
                  fontWeight: "bolder",
                  color: "black",
                  border: "solid 0.5px",
                  borderBottom: "solid 0.5px",
                  borderTop: "none",
                  backgroundColor: "#c0c0c0",
                  width: "180px",
                  height: "30px",
                  marginLeft: "2.9%",
                  marginTop: "-16px",
                  textAlign: "center",
                }}
              >
                Fecha
              </div>
              <div
                className="field col-12 md:col-6"
                style={{
                  color: "black",
                  border: "solid 0.5px",
                  borderTop: "none",
                  marginTop: "-16px",
                  width: "180px",
                  height: "30px",
                  textAlign: "center",
                }}
              >
                {props.quote.date}
              </div>
            </div>

            <div className="formgrid grid" style={{ width: "100%" }}>
              <div
                className="field col-12 md:col-12"
                style={{ marginLeft: "1%", color: "black" }}
              >
                Destino de envío:
                <div style={{ border: "solid 0.5px", paddingLeft: "5px" }}>
                  {props.quote.user.company && (
                    <>
                      <span
                        style={{
                          fontWeight: "bolder",
                          width: "100%",
                          color: "black",
                        }}
                      >
                        Empresa:
                      </span>{" "}
                      <span
                        style={{
                          fontWeight: "bolder",
                          width: "100%",
                          color: "#163D4F",
                        }}
                      >
                        {props.quote.user.company}
                      </span>
                      <br />
                    </>
                  )}

                  {props.quote.user.name && (
                    <>
                      <span
                        style={{
                          fontWeight: "bolder",
                          width: "100%",
                          color: "black",
                        }}
                      >
                        Nombre:
                      </span>{" "}
                      <span
                        style={{
                          fontWeight: "bolder",
                          width: "100%",
                          color: "#163D4F",
                        }}
                      >
                        {props.quote.user.name +
                          " " +
                          props.quote.user.lastName}
                      </span>
                      <br />
                    </>
                  )}
                  {props.quote.user.email && (
                    <>
                      <span
                        style={{
                          fontWeight: "bolder",
                          width: "100%",
                          color: "black",
                        }}
                      >
                        Email:
                      </span>{" "}
                      <span
                        style={{
                          fontWeight: "bolder",
                          width: "100%",
                          color: "#163D4F",
                        }}
                      >
                        {props.quote.user.email}
                      </span>
                      <br />
                    </>
                  )}
                  {props.quote.user.address && (
                    <>
                      <span
                        style={{
                          fontWeight: "bolder",
                          width: "100%",
                          color: "black",
                        }}
                      >
                        Dirección:
                      </span>{" "}
                      <span
                        style={{
                          fontWeight: "bolder",
                          width: "100%",
                          color: "#163D4F",
                        }}
                      >
                        {props.quote.user.address}
                      </span>
                      <br />
                    </>
                  )}
                </div>
              </div>
            </div>
            {/* 1st table */}
            <div
              className="formgrid grid"
              style={{
                width: "100%",
                textAlign: "center",
                marginLeft: "2%",
                marginTop: "10%",
              }}
            >
              <div
                className="field col-12 md:col-3"
                style={{
                  marginLeft: "1%",
                  marginTop: "20px",
                  backgroundColor: "#c0c0c0",
                  color: "black",
                  border: "solid 0.5px",
                  width: "115px",
                  textAlign: "center",
                }}
              >
                Núm. orden
              </div>
              <div
                className="field col-12 md:col-3"
                style={{
                  marginLeft: "0px",
                  marginTop: "20px",
                  backgroundColor: "#c0c0c0",
                  color: "black",
                  border: "solid 0.5px",
                  width: "334px",
                  textAlign: "center",
                }}
              >
                Método de envío
              </div>
              <div
                className="field col-12 md:col-3"
                style={{
                  marginLeft: "0px",
                  marginTop: "20px",
                  backgroundColor: "#c0c0c0",
                  color: "black",
                  border: "solid 0.5px",
                  width: "200px",
                  textAlign: "center",
                }}
              >
                Código Descuento
              </div>
              <div
                className="field col-12 md:col-3"
                style={{
                  marginLeft: "0px",
                  marginTop: "20px",
                  backgroundColor: "#c0c0c0",
                  color: "black",
                  border: "solid 0.5px",
                  width: "200px",
                  textAlign: "center",
                }}
              >
                Fecha de envío
              </div>
              <div
                className="formgrid grid"
                style={{
                  width: "100%",
                  textAlign: "center",
                  margingtTop: "-20px",
                  marginLeft: "0px",
                }}
              >
                <div
                  className="field col-12 md:col-3"
                  style={{
                    marginLeft: "1%",
                    marginTop: "-16px",
                    color: "black",
                    border: "solid 0.5px",
                    width: "115px",
                    textAlign: "center",
                  }}
                >
                  {props.quote.orderNumber}
                </div>
                <div
                  className="field col-12 md:col-3"
                  style={{
                    marginLeft: "0px",
                    marginTop: "-16px",
                    color: "black",
                    border: "solid 0.5px",
                    width: "334px",
                    textAlign: "center",
                  }}
                >
                  {props.quote.shippingMethod}
                </div>

                <div
                  className="field col-12 md:col-3"
                  style={{
                    marginLeft: "0px",
                    marginTop: "-16px",
                    color: "black",
                    border: "solid 0.5px",
                    width: "200px",
                    textAlign: "center",
                  }}
                >
                  {props.quote.discountCode}
                </div>

                <div
                  className="field col-12 md:col-3"
                  style={{
                    marginLeft: "0px",
                    marginTop: "-16px",
                    color: "black",
                    border: "solid 0.5px",
                    width: "200px",
                    textAlign: "center",
                  }}
                >
                  {props.quote.requestedShipDate}
                </div>
              </div>
            </div>
            <div
              className="formgrid grid"
              style={{
                width: "100%",
                textAlign: "center",
                margingtTop: "-20px",
                marginLeft: "1%",
              }}
            >
              <div
                className="field col-12 md:col-1"
                style={{
                  marginLeft: "2%",
                  marginTop: "0px",
                  backgroundColor: "#c0c0c0",
                  color: "black",
                  border: "solid 0.5px",
                  width: "100px",
                  textAlign: "center",
                }}
              >
                Cantidad
              </div>

              <div
                className="field col-12 md:col-5"
                style={{
                  marginLeft: "0px",
                  marginTop: "0px",
                  backgroundColor: "#c0c0c0",
                  color: "black",
                  border: "solid 0.5px",
                  width: "300px",
                  textAlign: "center",
                }}
              >
                Descripción
              </div>
              <div
                className="field col-12 md:col-2"
                style={{
                  marginLeft: "0px",
                  marginTop: "0px",
                  backgroundColor: "#c0c0c0",
                  color: "black",
                  border: "solid 0.5px",
                  width: "150px",
                  textAlign: "center",
                }}
              >
                Descuento
              </div>

              <div
                className="field col-12 md:col-1"
                style={{
                  marginLeft: "0px",
                  marginTop: "0px",
                  backgroundColor: "#c0c0c0",
                  color: "black",
                  border: "solid 0.5px",
                  width: "150px",
                  textAlign: "center",
                }}
              >
                Precio
              </div>
              <div
                className="field col-12 md:col-2"
                style={{
                  marginLeft: "0px",
                  marginTop: "0px",
                  backgroundColor: "#c0c0c0",
                  color: "black",
                  border: "solid 0.5px",
                  width: "150px",
                  textAlign: "center",
                }}
              >
                Total
              </div>
            </div>
            {/* 2nd table */}
            <div
              className="formgrid grid"
              style={{
                width: "100%",
                textAlign: "center",
                marginLeft: "1%",
              }}
            >
              <div
                className="field col-12 md:col-1"
                style={{
                  marginLeft: "2%",
                  marginTop: "-17px",
                  color: "black",
                  border: "solid 0.5px",
                  width: "100px",
                  textAlign: "center",
                }}
              >
                {props.quote.quantity}
              </div>

              <div
                className="field col-12 md:col-5"
                style={{
                  marginLeft: "0px",
                  marginTop: "-17px",
                  color: "black",
                  border: "solid 0.5px",
                  width: "300px",
                  textAlign: "center",
                }}
              >
                {props.quote.description}
              </div>
              <div
                className="field col-12 md:col-2"
                style={{
                  marginLeft: "0px",
                  marginTop: "-17px",
                  color: "black",
                  border: "solid 0.5px",
                  width: "150px",
                  textAlign: "center",
                }}
              >
                {props.quote.discount}
              </div>

              <div
                className="field col-12 md:col-1"
                style={{
                  marginLeft: "0px",
                  marginTop: "-17px",
                  color: "black",
                  border: "solid 0.5px",
                  width: "150px",
                  textAlign: "center",
                }}
              >
                {props.quote.price}
              </div>
              <div
                className="field col-12 md:col-2"
                style={{
                  marginLeft: "0px",
                  marginTop: "-17px",
                  color: "black",
                  border: "solid 0.5px",
                  width: "150px",
                  textAlign: "center",
                }}
              >
                {props.quote.totalBillQuote}
              </div>
            </div>

            <br />
            <div
              className="formgrid grid"
              style={{ width: "100%", marginTop: "10%" }}
            >
              <div className="field col-12 md:col-4"></div>
              <div
                className="field col-12 md:col-4"
                style={{ paddingLeft: "80px" }}
              >
                <img src={selloABA} alt="Sample" width={200} />
              </div>
              <div className="field col-12 md:col-4"></div>
            </div>
            <div className="formgrid grid" style={{ width: "100%" }}>
              <div className="field col-12 md:col-3"></div>
              <div className="field col-12 md:col-8">
                <span
                  style={{ color: "#908A8A", borderTop: "solid 0.5px black" }}
                >
                  {props.quote.prepaidBy}
                </span>
              </div>
              <div className="field col-12 md:col-1"></div>
            </div>
          </div>
        )}
    </>
  );
};

export default Quote;
