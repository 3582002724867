import { createSlice } from "@reduxjs/toolkit";
import { getCurrentDate } from "../../utils/CurrentDate";

const initialState = {
  conferences: [],
  conference: {
    id: 0,
    name: "",
    conferecenCodeName: "",
    description: "",
    location: "",
    price: 0,
    soldOut: 0,
    status: false,
    file: null,
    imageUrl: "",
    startDate: "",
    endDate: "",
    informativeTitle: "",
    informativeBody: "",
    conferenceMenuInfoes: [],
    ternmConditions: "",
    creditCardOnly: false,
  },
  conferenceValidation: {
    isValid: true,
    name: {
      isValid: true,
      errorMessage: "El campo nombre no debe estar vacio",
    },
    conferecenCodeName: {
      isValid: true,
      errorMessage: "El campo código no debe estar vacio",
    },
    description: {
      isValid: true,
      errorMessage: "El campo descripción no debe estar vacio",
    },
    location: {
      isValid: true,
      errorMessage: "Debe digitar un lugar para el congreso",
    },
    price: {
      isValid: true,
      errorMessage: "Debe digitar un precio para el congreso",
    },
    startDate: {
      isValid: true,
      errorMessage: "El fecha inicio Docente no debe estar vacio",
    },
    endDate: {
      isValid: true,
      errorMessage: "El campo fecha Fin no debe estar vacio",
    },
    ternmConditions:{
      isValid: true,
      errorMessage: "El campo condiciones no debe estar vacio",
    }
  },
};

const conferenceSlice = createSlice({
  name: "conference",
  initialState,
  reducers: {
    restartState(state) {
      state.conference = {};
      state.conferences = [];
    },
    setConferences(state, actions) {
      state.conferences = actions.payload.conferences;
    },
    setConference(state, actions) {
      state.conference = actions.payload.conference;
    },
    setConferenceMenuInfoes(state, actions) {
      state.conference.conferenceMenuInfoes = actions.payload.conferenceMenuInfoes;
    },
    modifyPropertyValue(state, actions) {
      state.conference[actions.payload.id] = actions.payload.value;
    },
    modifyFile(state, actions) {
      state.conference.file = actions.payload.value;
    },
    setConferenceValidation(state, actions) {
      state.conferenceValidation = {
        name: {
          ...state.conferenceValidation.name,
          isValid: actions.payload.name !== "",
        },
        description: {
          ...state.conferenceValidation.description,
          isValid: actions.payload.description !== "",
        },
        startDate: {
          ...state.conferenceValidation.startDate,
          isValid: actions.payload.startDate !== "",
        },
        endDate: {
          ...state.conferenceValidation.endDate,
          isValid: actions.payload.endDate !== "",
        },
        location: {
          ...state.conferenceValidation.location,
          isValid: actions.payload.location !== "",
        },
        conferecenCodeName: {
          ...state.conferenceValidation.conferecenCodeName,
          isValid: actions.payload.conferecenCodeName !== "",
        },
        price: {
          ...state.conferenceValidation.price,
          isValid: actions.payload.price >= 1,
        },
        ternmConditions: {
          ...state.conferenceValidation.ternmConditions,
          isValid: actions.payload.ternmConditions !== "",
        }
      };

      state.conferenceValidation.isValid =
        state.conferenceValidation.name.isValid &&
        state.conferenceValidation.description.isValid &&
        state.conferenceValidation.startDate.isValid &&
        state.conferenceValidation.endDate.isValid &&
        state.conferenceValidation.location.isValid &&
        state.conferenceValidation.price.isValid &&
        state.conferenceValidation.conferecenCodeName.isValid &&
        state.conferenceValidation.ternmConditions.isValid;
    },
    setConferenceValid(state, actions) {
      state.conferenceValidation.DiscountCode.isValid = actions.payload.isValid;
      state.conferenceValidation.DiscountCode.errorMessage =
        actions.payload.errorMessage;
    },
    setFieldValid(state, actions) {
      var options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      const CurrentDate = new Date(getCurrentDate("/")).toLocaleDateString(
        "es-ES",
        options
      );
      if (actions.payload.id === "startDate") {
        if (
          new Date(state.conference.startDate) < new Date(getCurrentDate("/"))
        ) {
          state.conferenceValidation[actions.payload.id].isValid = false;
          state.conferenceValidation[
            actions.payload.id
          ].errorMessage = `La fecha no puede ser menor a ${CurrentDate}`;
          state.conference[actions.payload.id] = Date.now();
        } else {
          state.conferenceValidation[actions.payload.id].isValid =
            actions.payload.value;
        }
      } else if (actions.payload.id === "endDate") {
        if (
          new Date(state.conference.endDate) < new Date(getCurrentDate("/"))
        ) {
          state.conferenceValidation[actions.payload.id].isValid = false;
          state.conferenceValidation[
            actions.payload.id
          ].errorMessage = `La fecha no puede ser menor a ${CurrentDate}`;
          state.conference[actions.payload.id] = Date.now();
        } else {
          state.conferenceValidation[actions.payload.id].isValid =
            actions.payload.value;
        }
      } else {
        state.conferenceValidation[actions.payload.id].isValid =
          actions.payload.value;
      }
    },
  },
});

export const conferenceActions = conferenceSlice.actions;
export default conferenceSlice;
