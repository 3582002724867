import { useSelector } from "react-redux";
import { CreateOrdenType } from "../../../store/ordentype-store/ordentype-actions";
import OrdenTypeForms from "../../../components/Forms/OrdenTypeForm";

const OrdenTypeCreate = () => {
  const ordenType = useSelector((state) => state.ordenType.ordenType);

  return (
    <>
      <OrdenTypeForms actionOrdenType={CreateOrdenType} ordenType={ordenType} />
    </>
  );
};
export default OrdenTypeCreate;
