import { Accordion, AccordionTab } from "primereact/accordion";
import UserDetailComponentnent from "../UserDetailComponent";
const UserAccordion = (props) => {
  const headerP = (
    <span style={{ color: "black", fontSize: "large" }}>Usuarios</span>
  );
  const headerSe = (usuario) => {
    return (
      <span style={{ color: "black", fontSize: "large" }}>
        {usuario.nombres + " " + usuario.apellidos}
      </span>
    );
  };
  return (
    <>
      <div
        className="card flex justify-content-center "
        style={{ marginTop: "10px", backgroundColor: "transparent" }}
      >
        <Accordion
          activeIndex={0}
          style={{
            marginTop: "2%",
            marginBottom: "2%",
            width: "90%",
            backgroundColor: "#fff",
            borderRadius: "8px",
          }}
        >
          <AccordionTab
            header={headerP}
            style={{ border: "solid 0.5px #2196F3" }}
          >
            <Accordion multiple activeIndex={0}>
              {props.usuarios.length > 0 &&
                props.usuarios.map((usuario) => {
                  return (
                    <AccordionTab
                      key={usuario.email}
                      header={headerSe(usuario)}
                      style={{
                        border: "solid 0.5px #2196F3",
                        borderRadius: "8px",
                      }}
                    >
                      <UserDetailComponentnent
                        buttons={props.buttons}
                        usuario={usuario}
                      />
                    </AccordionTab>
                  );
                })}
            </Accordion>
          </AccordionTab>
        </Accordion>
      </div>
    </>
  );
};
export default UserAccordion;
