import { useSelector } from "react-redux";
import CourseTypeForm from "../../../components/Forms/CourseTypeForm";
import { CreateCourseType } from "../../../store/coursetype-store/coursetype-actions";


const CourseTypeCrete = () => {
  const courseType = useSelector((state) => state.courseType.courseType);

  return (
    <>
      <CourseTypeForm actionCourseType={CreateCourseType} courseType={courseType} />
    </>
  );
};
export default CourseTypeCrete;
