import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  GetDiscountById,
  UpadateDiscount,
} from "../../../store/discount-store/discount-actions";
import DiscountForm from "../../../components/Forms/DiscountForm";
import { GetConferences } from "../../../store/conferences-store/conference-actions";

const DiscountEdit = () => {
  const param = useParams();
  const dispatch = useDispatch();
  const discount = useSelector((state) => state.discount.discount);
  const courses = useSelector((state) => state.course.courses);
  const conferences = useSelector((state) => state.conference.conferences);
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    if (discount && discount.id !== undefined && discount.id > 0) {
      dispatch(GetConferences(auth.UserInfo.email, false));
    }
  }, [auth.UserInfo.email, auth.roles, discount, dispatch]);

  useEffect(() => {
    if (discount && (discount.id === 0 || discount.id === undefined)) {
      dispatch(GetDiscountById(param.discountId, auth.UserInfo.email));
    }
  }, [param.discountId, auth.UserInfo.email, dispatch, discount]);

  return (
    <>
      <DiscountForm
        actionDiscount={UpadateDiscount}
        discount={discount}
        courses={courses}
        conferences={conferences}
      />
    </>
  );
};
export default DiscountEdit;
