import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import store from "./store/index";
import "./assets/Fonts/Boing-Bold_0.ttf";

import App from "./App";
import "./index.css";
import "primeicons/primeicons.css";
import "/node_modules/primeflex/primeflex.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
