import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  departments: [],
  department: {
    id: 0,
    name: "",
    description: "",
    status: false,
  },
  departmentValidation: {
    isValid: true,
    name: {
      isValid: true,
      errorMessage: "El campo nombre no debe estar vacio",
    },
    description: {
      isValid: true,
      errorMessage: "El campo descripción no debe estar vacio",
    },
  },
};

const departmentSlice = createSlice({
  name: "department",
  initialState,
  reducers: {
    restartState(state) {
      state.department = {};
      state.departments = [];
    },
    setDepartments(state, actions) {
      state.departments = actions.payload.departments;
      state.department = {};
    },
    setDepartment(state, actions) {
      state.department = actions.payload.department;
      state.departments = [];
    },
    modifyPropertyValue(state, actions) {
      state.department[actions.payload.id] = actions.payload.value;
    },
    setDepartmentValidation(state, actions) {
      state.departmentValidation = {
        name: {
          ...state.departmentValidation.description,
          isValid: actions.payload.description !== "",
        },
        description: {
          ...state.departmentValidation.description,
          isValid: actions.payload.description !== "",
        },
      };
      state.departmentValidation.isValid =
        state.departmentValidation.description.isValid;
    },
    setFieldValid(state, actions) {
      state.departmentValidation[actions.payload.id].isValid = actions.payload.value;
    },
  },
});

export const departmentActions = departmentSlice.actions;
export default departmentSlice;
