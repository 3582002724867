import { Button } from "primereact/button";
import { useRef } from "react";
import QRCode from "react-qr-code";
import ReactToPrint from "react-to-print";

const Badges = (props) => {
  const ref = useRef(null);
  const pageStyle = `{size: Landscape}`;

  return (
    <>
      <div
        style={{
          textAlign: "center",
          width: "89mm",
          height: "36mm",
          border: "black solid",
        }}
        id={props.usuario.id}
        ref={ref}
      >
        <div
          style={{
            height: "auto",
            marginTop: "26px",
            marginLeft: "10px",
            float: "left",
          }}
        >
          <QRCode
            size={256}
            style={{
              height: "auto",
              // maxWidth: "100%",
              width: "80px",
            }}
            value={
              process.env.REACT_APP_USERINFO_URL +
              "/" +
              props.orden.conferences.id +
              "/" +
              props.usuario.id
            }
            viewBox={`0 0 256 256`}
          />
        </div>
        <div style={{ padding: "0px", marginTop: "22px" }}>
          <label
            style={{
              color: "black",
              marginBottom: "0px",
            }}
          >
            {props.usuario.nombres} {props.usuario.apellidos}
          </label>
        </div>
        <div>
          <label
            style={{
              color: "black",
              textAlign: "center",
              marginBottom: "0px",
            }}
          >
            {props.usuario.cargo}
          </label>
        </div>
        <div>
          <label
            style={{
              color: "black",
              textAlign: "center",
              marginBottom: "0px",
            }}
          >
            {props.usuario.entidadLabora}
          </label>
        </div>
      </div>
      <div style={{ marginTop: "25%" }}>
        <ReactToPrint
          pageStyle={pageStyle}
          copyStyles={true}
          content={() => ref.current}
          trigger={() => (
            <Button
              type="button"
              label="Imprimir"
              className="p-button-warning"
              style={{
                width: "90%",
                border: "solid 0.5px #2196F3",
                borderRadius: "7px",
              }}
            />
          )}
        />
      </div>
    </>
  );
};
export default Badges;
