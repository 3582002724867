import { configureStore } from "@reduxjs/toolkit";
import AuthSlice from "./auth-slice";
import UISlice from "./ui-slice";

import dolarDiarioSlice from "./dolar-store/dolardiario-slice";
import discountSlice from "./discount-store/discount-slice";
import ordenStatusSlice from "./ordenstatus-store/ordenstatus-slice";
import ordenTypeSlice from "./ordentype-store/ordentype-slice";
import ordenSlice from "./orden-store/orden-slice";

import userSlice from "./user-store/user-slice";
import chargeSlice from "./charge-store/charge-slice";
import departmentSlice from "./department-store/department-slice";

import fileSlice from "./file-slice";
import conferenceSlice from "./conferences-store/conference-slice";

import sponsorshipSlice from "./sponsorships-store/sponsorship-slice";
import sponsorSlice from "./sponsor-store/sponsor-slice";
import sponsorAgendaSlice from "./sponsor-store/sponsoragenda-slice";
import conferenceEventSlice from "./conferencesEvent-store/conferenceEvent-slice";

import paymentMethodSlice from "./paymentmethod-store/paymentmethod-slice";

import courseSlice from "./courses-store/course-slice";
import courseTypeSlice from "./coursetype-store/coursetype-slice";
import courseCategorySlice from "./coursecategory-store/coursecategory-slice";
import companySlice from "./company-store/company-slice";

const store = configureStore({
  reducer: {
    auth: AuthSlice.reducer,
    ui: UISlice.reducer,

    course: courseSlice.reducer,
    courseType: courseTypeSlice.reducer,
    courseCategory: courseCategorySlice.reducer,

    user: userSlice.reducer,
    charge: chargeSlice.reducer,
    department: departmentSlice.reducer,
    company: companySlice.reducer,

    ordenStatus: ordenStatusSlice.reducer,
    ordenType: ordenTypeSlice.reducer,
    orden: ordenSlice.reducer,
    discount: discountSlice.reducer,
    dolarDiario: dolarDiarioSlice.reducer,
    paymentMethod: paymentMethodSlice.reducer,

    file: fileSlice.reducer,
    conference: conferenceSlice.reducer,

    sponsor: sponsorSlice.reducer,
    sponsorAgenda: sponsorAgendaSlice.reducer,
    sponsorShip: sponsorshipSlice.reducer,

    conferenceEvent: conferenceEventSlice.reducer,
  },
});

export default store;
