import { useDispatch, useSelector } from "react-redux";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { FileUpload } from "primereact/fileupload";
import { UploadReports } from "../../store/datalab-store/datalab-actions";

const DataLabUpladFileForms = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  const [file, setFile] = useState(null);

  const notification = useSelector((state) => state.ui.notification);

  const onSubmitHandler = (event) => {
    event.preventDefault();
    dispatch(UploadReports(file, auth.UserInfo.email ));
  };
  const onSelectHandler = (file) => {
    setFile(file.files[0]);
  };

  const chooseOptions = {
    icon: "pi pi-fw pi-images",
    iconOnly: true,
    className: "custom-choose-btn p-button-rounded p-button-outlined",
  };
  const uploadOptions = {
    icon: "pi pi-fw pi-cloud-upload",
    iconOnly: true,
    className:
      "custom-upload-btn p-button-success p-button-rounded p-button-outlined",
  };
  const cancelOptions = {
    icon: "pi pi-fw pi-times",
    iconOnly: true,
    className:
      "custom-cancel-btn p-button-danger p-button-rounded p-button-outlined ",
  };
  const fileUpload = (
    <FileUpload
      style={{ width: "100%", borderRadius: "12px" }}
      name="fileUpload"
      accept="pdf"
      customUpload={true}
      onSelect={onSelectHandler}
      mode="advanced"
      chooseOptions={chooseOptions}
      uploadOptions={uploadOptions}
      cancelOptions={cancelOptions}
      emptyTemplate={<p className="m-0">Seleccione el logo del congreso.</p>}
    />
  );

  return (
    <>
      <Card
        style={{
          backgroundColor: "#142f47",
          marginLeft: "1%",
          marginTop: "-16px",
          boxShadow: "none",
          height: "100%",
          width: "99%",
        }}
      >
        <form onSubmit={onSubmitHandler}>
          <div className="formgrid grid">
            <div className="field col-12 md:col-6">
              <label
                htmlFor="name"
                style={{ fontSize: "large", color: "#35c4dc" }}
              >
                Subir Archivo:
              </label>
              {fileUpload}
            </div>
          </div>
          <div className="formgrid grid">
            <Button
              rounded
              text
              raised
              style={{ backgroundColor: "#fff" }}
              label="Regresar"
              icon="pi pi-arrow-left"
              className="p-button-danger"
              onClick={() => {
                navigate("/charges");
              }}
            />
            <Button
              rounded
              text
              raised
              style={{ backgroundColor: "#fff", marginLeft: "1%" }}
              label="Guardar"
              className="p-button-success"
              icon={
                notification && notification.status === "pending"
                  ? "pi pi-spin pi-spinner"
                  : "pi pi-save"
              }
              disabled={notification && notification.status === "pending"}
            />
          </div>
        </form>
      </Card>
    </>
  );
};
export default DataLabUpladFileForms;
