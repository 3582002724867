import { companyActions } from "./company-slice";
import { uiActions } from "../ui-slice";

export const GetCompanies = (email, showNotification, getAll) => {
  return async (dispatch) => {
    
    if (showNotification) {
      dispatch(
        uiActions.showNotification({
          status: "pending",
          title: "Cargando inforamción",
          message: "Se esta solicitando la información al servidor.",
        })
      );
    }
    const GetCompaniesInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/user/company/getall`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo obtener la información solicitada");
      }
      const data = await response.json();
      return data;
    };

    try {
      const response = await GetCompaniesInfo();
      if (response.result.errorCode === "OK") {
        if (getAll) {
          dispatch(
            companyActions.setCompanies({
              companies: response.data.map((company) => {
                return {
                  ...company,
                  status: company.status === true ? "Activo" : "Inactivo",
                };
              }),
            })
          );
        } else {
          dispatch(
            companyActions.setCompanies({
              companies: response.data
                .filter((company) => company.status === true)
                .map((company) => {
                  return {
                    ...company,
                  };
                }),
            })
          );
        }
        if (showNotification) {
          dispatch(
            uiActions.showNotification({
              status: "success",
              title: "Datos Cargados",
              message: response.result.message,
            })
          );
        }
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          companyActions.setCompanies({
            statues: [],
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo obtener la información solicitada",
        })
      );
    }
  };
};
export const GetCompany = (id, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Solicitando empresa",
        message: "Solicitando empresa al servidor.",
      })
    );
    const getCompanyInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/user/company/getById`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            id: id,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo obtener la empresa");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await getCompanyInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          companyActions.setCompany({
            company: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Datos Cargados",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          companyActions.setCompany({
            company: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo obtener la empresa",
        })
      );
    }
  };
};
export const UpdateCompany = (company, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Actualizando empresa",
        message: "Solicitando actualización de la empresa al servidor.",
      })
    );
    const updateCompanyInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/user/company/update`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            Id: company.id,
            Name: company.name,
            RNC: company.rnc,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo actualizar la empresa");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await updateCompanyInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          companyActions.setCompany({
            company: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Cargo Actualizado",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          companyActions.setCompany({
            company: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo actualizar la empresa",
        })
      );
    }
  };
};
export const CreateCompany = (company, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Creando empresa",
        message: "Solicitando creación de la empresa al servidor.",
      })
    );
    const createCompanyInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/user/company/create`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            Name: company.name,
            RNC: company.rnc,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo crear la empresa");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await createCompanyInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          companyActions.setCompany({
            company: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Cargo Creado",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          companyActions.setCompany({
            company: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo crear la empresa",
        })
      );
    }
  };
};
export const ActiveCompany = (id, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Activando empresa",
        message: "Solicitando activación de la empresa al servidor.",
      })
    );
    const activeCompanyInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/user/company/active`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            id: id,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo activar la empresa");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await activeCompanyInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          companyActions.setCompany({
            company: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Cargo Activado",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          companyActions.setCompany({
            company: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo activar la empresa",
        })
      );
    }
  };
};
export const InactiveCompany = (id, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Inactivando empresa",
        message: "Solicitando activación de la empresa al servidor.",
      })
    );
    const inactiveCompanyInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/user/company/inactive`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            Id: id,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo inactivar la empresa");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await inactiveCompanyInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          companyActions.setCompany({
            company: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Cargo Inactivado",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          companyActions.setCompany({
            company: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo inactivar la empresa",
        })
      );
    }
  };
};
