import { sponsorActions } from "./sponsor-slice";
import { sponsorAgendaActions } from "./sponsoragenda-slice";
import { uiActions } from "../ui-slice";
import { fileActions } from "../file-slice";

export const GetSponsorships = (email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Solicitando Patrocinadores",
        message: "Solicitando patrocinadores al servidor",
      })
    );
    const GetsponsorshipInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}sponsorship/getall`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo obtener Patrocinadores");
      }
      const data = await response.json();
      return data;
    };

    try {
      const response = await GetsponsorshipInfo();
      if (response.result.errorCode === "OK") {
        dispatch(sponsorActions.setsponsorships(response.data));
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Datos Cargados",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo obtener Patrocinadores",
        })
      );
    }
  };
};
export const GetSponsorshipsByOrdenId = (email, id) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Solicitando Patrocinadores",
        message: "Solicitando patrocinadores al servidor",
      })
    );
    const GetsponsorshipInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}sponsorship/getallByOrderId`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            Payload: {
              OrdenId: id,
            },
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo obtener Patrocinadores");
      }
      const data = await response.json();
      return data;
    };

    try {
      const response = await GetsponsorshipInfo();
      if (response.result.errorCode === "OK") {
        dispatch(sponsorActions.setsponsorships(response.data));
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Datos Cargados",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo obtener Patrocinadores",
        })
      );
    }
  };
};
export const GetSponsorshipById = (id, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Solicitando Patrocinador",
        message: "Solicitando Patrocinador al servidor.",
      })
    );
    const getsponsorshipInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}sponsorship/getById`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            Payload: {
              Id: id,
            },
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo obtener el Patrocinador");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await getsponsorshipInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          sponsorActions.setsponsorship({
            sponsorship: {
              Id: response.data.id,
              CompanyName: response.data.companyName,
              WebUrl: response.data.webUrl,
              Country: response.data.country,
              City: response.data.city,
              Phone: response.data.phone,
              Email: response.data.email,
              ConferenceId: response.data.conferenceId,
              Description: response.data.description,
              Multinational: response.data.multinational,
              Conference: response.data.conference,
              HasMax: response.data.hasMax,
              Users: response.data.usuarios,
            },
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Datos Cargados",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          sponsorActions.setsponsorship({
            sponsorship: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo obtener el patrocinador",
        })
      );
    }
  };
};
export const GetByUserId = (id, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Solicitando Patrocinador",
        message: "Solicitando Patrocinador al servidor.",
      })
    );
    const getsponsorshipInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}sponsorship/getByUserId`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            Payload: {
              Id: id,
            },
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo obtener el Patrocinador");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await getsponsorshipInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          sponsorActions.setsponsorship({
            sponsorship: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Datos Cargados",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          sponsorActions.setsponsorship({
            sponsorship: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo obtener el patrocinador",
        })
      );
    }
  };
};
export const GetByUserEmail = (email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Solicitando Patrocinador",
        message: "Solicitando Patrocinador al servidor.",
      })
    );
    const getsponsorshipInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}sponsorship/getByEmail`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            Payload: {
              Email: email,
            },
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo obtener el Patrocinador");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await getsponsorshipInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          sponsorActions.setsponsorship({
            sponsorship: { ...response.data, Id: response.data.id },
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Datos Cargados",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          sponsorActions.setsponsorship({
            sponsorship: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo obtener el patrocinador",
        })
      );
    }
  };
};
export const GetAgendas = (
  sponsorShipId,
  conferenceId,
  conferenceNeworkingId,
  email
) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Solicitando Patrocinadores",
        message: "Solicitando patrocinadores al servidor",
      })
    );
    const GetsponsorshipInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}sponsorship/getAgendaBySponsor`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            Payload: {
              Id: sponsorShipId,
              ConferenceId: conferenceId,
              ConferenceNeworkingId: conferenceNeworkingId,
            },
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );

      if (!response.ok) {
        throw new Error("No se pudo obtener Patrocinadores");
      }
      const data = await response.json();
      return data;
    };

    try {
      const response = await GetsponsorshipInfo();
      if (response.result.errorCode === "OK") {
        dispatch(sponsorAgendaActions.setsponsorshipAgendas(response.data));
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Datos Cargados",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo obtener Patrocinadores",
        })
      );
    }
  };
};
export const SetNoAvailable = (id, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Solicitando No disponibilidad",
        message: "Solicitando no disponibiliad al servidor",
      })
    );
    const GetsponsorshipInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}sponsorship/setNoAvailable`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            Payload: {
              Id: id,
            },
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );

      if (!response.ok) {
        throw new Error("No se pudo obtener actualizar la agenda");
      }
      const data = await response.json();
      return data;
    };

    try {
      const response = await GetsponsorshipInfo();
      if (response.result.errorCode === "OK") {
        dispatch(sponsorAgendaActions.setsponsorshipAgendas(response.data));
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Datos Cargados",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo obtener la agenda",
        })
      );
    }
  };
};
export const SetAvailable = (id, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Solicitando No disponibilidad",
        message: "Solicitando disponibiliad al servidor",
      })
    );
    const GetsponsorshipInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}sponsorship/setAvailable`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            Payload: {
              Id: id,
            },
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );

      if (!response.ok) {
        throw new Error("No se pudo obtener actualizar la agenda");
      }
      const data = await response.json();
      return data;
    };

    try {
      const response = await GetsponsorshipInfo();
      if (response.result.errorCode === "OK") {
        dispatch(sponsorAgendaActions.setsponsorshipAgendas(response.data));
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Datos Cargados",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo obtener la agenda",
        })
      );
    }
  };
};
export const ToSchedule = (id, email, userName, extended) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Agendando",
        message: "Agendado en el servidor",
      })
    );
    const GetsponsorshipInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}sponsorship/toSchedule`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: userName,
            Payload: {
              Id: id,
              Extended: extended,
            },
            Usuario: {
              Email: email,
            },
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );

      if (!response.ok) {
        throw new Error("No se pudo obtener actualizar la agenda");
      }
      const data = await response.json();
      return data;
    };

    try {
      const response = await GetsponsorshipInfo();
      if (response.result.errorCode === "OK") {
        dispatch(sponsorAgendaActions.setsponsorshipAgendas(response.data));
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Datos Cargados",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo obtener la agenda",
        })
      );
    }
  };
};
export const CancelSchedule = (id, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Cancelando",
        message: "Cancelando reserva en el servidor",
      })
    );
    const GetsponsorshipInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}sponsorship/cancelSchedule`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            Payload: {
              Id: id,
            },
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );

      if (!response.ok) {
        throw new Error("No se pudo obtener actualizar la agenda");
      }
      const data = await response.json();
      return data;
    };

    try {
      const response = await GetsponsorshipInfo();
      if (response.result.errorCode === "OK") {
        dispatch(sponsorAgendaActions.setsponsorshipAgendas(response.data));
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Datos Cargados",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo obtener la agenda",
        })
      );
    }
  };
};
export const UpadateSponsorship = (sponsorship, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Actualizando el Patrocinador",
        message: "Solicitando Actualización del Patrocinador al servidor.",
      })
    );

    const creationDate = new Date(sponsorship.creationDate);
    const Updatesponsorship = {
      Id: sponsorship.Id,
      CompanyName: sponsorship.CompanyName,
      WebUrl: sponsorship.WebUrl,
      Country: sponsorship.Country,
      City: sponsorship.City,
      Phone: sponsorship.Phone,
      Email: sponsorship.Email,
      ConferenceId: sponsorship.ConferenceId,
      Description: sponsorship.Description,
      Multinational: sponsorship.Multinational,
      CreationDate: creationDate,
    };

    const updatesponsorshipInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}sponsorship/update`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            Payload: {
              ...Updatesponsorship,
            },
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo actualizar el Patrocinador");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await updatesponsorshipInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          sponsorActions.setsponsorship({
            sponsorship: {
              Id: response.data.id,
              CompanyName: response.data.companyName,
              WebUrl: response.data.webUrl,
              Country: response.data.country,
              City: response.data.city,
              Phone: response.data.phone,
              Email: response.data.email,
              ConferenceId: response.data.conferenceId,
              Description: response.data.description,
              Multinational: response.data.multinational,
            },
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Patrocinador Actualizado",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          sponsorActions.setsponsorship({
            sponsorship: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo actualizar el Patrocinador",
        })
      );
    }
  };
};
export const CreateSponsorship = (sponsorship, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Creando Patrocinador",
        message: "Solicitando Creación del Patrocinador al servidor.",
      })
    );
    const newsponsorship = {
      CompanyName: sponsorship.CompanyName,
      WebUrl: sponsorship.WebUrl,
      Country: sponsorship.Country,
      City: sponsorship.City,
      Phone: sponsorship.Phone,
      Email: sponsorship.Email,
      ConferenceId: sponsorship.ConferenceId,
      Description: sponsorship.Description,
      Multinational: sponsorship.Multinational,
      MaxUsers: sponsorship.MaxUsers,
    };

    const createsponsorshipInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}sponsorship/register`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            Payload: {
              ...newsponsorship,
            },
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo crear el Patrocinador");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await createsponsorshipInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          sponsorActions.setsponsorship({
            sponsorship: {
              Id: response.data.id,
              CompanyName: response.data.companyName,
              WebUrl: response.data.webUrl,
              Country: response.data.country,
              City: response.data.city,
              Phone: response.data.phone,
              Email: response.data.email,
              ConferenceId: response.data.conferenceId,
              Description: response.data.description,
              Multinational: response.data.multinational,
            },
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Patrocinador Creado",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          sponsorActions.setsponsorship({
            sponsorship: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo crear el Patrocinador",
        })
      );
    }
  };
};
export const UpdateRegister = (sponsorship) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Actualizando patrocinio",
        message:
          "Se esta realizanod la actualización del perfil de este patrocinio.",
      })
    );
    const updateSponsorshipInfo = async () => {
      let formData = new FormData();
      formData.append("SponsorshipId", sponsorship.id);
      formData.append("ConferenceId", sponsorship.conference.id);
      formData.append("CompanyName", sponsorship.companyName);
      formData.append("Description", sponsorship.description);
      formData.append("Multinational", sponsorship.multinational);

      formData.append("Nombres", sponsorship.usuario.nombres);
      formData.append("Apellidos", sponsorship.usuario.apellidos);
      formData.append("Identificacion", sponsorship.usuario.identificacion);
      formData.append("Email", sponsorship.usuario.email);
      formData.append("Password", sponsorship.usuario.password);
      formData.append("TelefonoCelular", sponsorship.usuario.telefonoCelular);
      formData.append("Pais", sponsorship.usuario.pais);
      formData.append("Ciudad", sponsorship.usuario.ciudad);
      formData.append("Departamento", sponsorship.usuario.departamento);
      formData.append("Cargo", sponsorship.usuario.cargo);
      formData.append("File", sponsorship.file);

      const response = await fetch(
        `${process.env.REACT_APP_URL}sponsorship/updateRegister`,
        {
          method: "POST",
          body: formData,
          headers: {
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo actualizar el patrocinio");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await updateSponsorshipInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Patrocinio actualizado",
            message: response.result.message,
          })
        );

        dispatch(
          fileActions.setIsload({
            file: {
              isLoad: true,
            },
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo actualizar el patrocinio",
        })
      );
    }
  };
};

export const RemoveUser = (sponsorId, userId, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Solicitando No disponibilidad",
        message: "Solicitando disponibiliad al servidor",
      })
    );
    const GetsponsorshipInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}sponsorship/removeUser`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            Payload: {
              SponsorShipId: sponsorId,
              UserId: userId,
            },
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );

      if (!response.ok) {
        throw new Error("No se pudo obtener actualizar la agenda");
      }
      const data = await response.json();
      return data;
    };

    try {
      const response = await GetsponsorshipInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Datos Cargados",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo obtener la agenda",
        })
      );
    }
  };
};
