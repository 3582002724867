import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Card } from "primereact/card";
import { Button } from "primereact/button";

import { Accordion, AccordionTab } from "primereact/accordion";

import {
  GetConferenceAndOrdens,
  ActiveConference,
  InactiveConference,
} from "../../../store/conferences-store/conference-actions";
import ConferenceDetailComponent from "../../../components/conferences/ConferenceDetailComponent";
import { Tag } from "primereact/tag";
import {
  RemoveUser,
  DownloadPaymentTransfer,
} from "../../../store/orden-store/orden-actions";

import Loading from "../../../components/UI/Loading";
import ABADatatableExpandable from "../../../components/ABADatatableExpandable";
import { arregloPaises } from "../../../components/List";

import { ValidateDiscountByCode } from "../../../store/discount-store/discount-actions";

import OrdenDetailComponent from "../../../components/Orden/OrdenDetailComponent";
import NewQuoteComponent from "../../../components/Orden/NewQuoteComponent";
import CertificatesComponent from "../../../components/Orden/CertificatesComponent";
import AttendedComponent from "../../../components/Orden/AttendedComponent";
import SendBiillComponent from "../../../components/Orden/SendBillComponent";
import NewOrderComponent from "../../../components/Orden/NewOrderComponent";
import StatusComponent from "../../../components/Orden/StatusComponent";
import PaymentMethodComponent from "../../../components/Orden/PaymentMethodComponent";
import DiscountComponent from "../../../components/Orden/DiscountComponent";
import TaxBillComponent from "../../../components/Orden/TaxBillComponent";
import AddUserComponent from "../../../components/Orden/AddUserComponent";
import { getBalanceReport, getListUserReport } from "../../../utils/Reports";
import {
  DownloadCertificate,
  SendAgain,
} from "../../../store/user-store/user-actions";
import UserViewComponent from "../../../components/Orden/UserViewComponent";

let getUsers = true;

const ConferenceDetail = () => {
  const param = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const conference = useSelector((state) => state.conference.conference);

  const notification = useSelector((state) => state.ui.notification);

  const auth = useSelector((state) => state.auth);
  const user = useSelector((state) => state.user);

  const [visibleNewOrden, setVisibleNewOrden] = useState(false);
  const [visibleOrdenDetail, setVisibleOrdenDetail] = useState(false);
  const [visibleUser, setVisibleUser] = useState(false);
  const [visibleStatus, setVisibleStatus] = useState(false);
  const [visiblePaymentMethod, setVisiblePaymentMethod] = useState(false);
  const [visibleDiscountCode, setVisiblDiscountCode] = useState(false);
  const [visibleCertificate, setVisibleCertificate] = useState(false);
  const [visibleAttended, setVisibleAttended] = useState(false);
  const [visibleNewQuote, setVisibleNewQuote] = useState(false);
  const [visibleSendBill, setVisibleSendBill] = useState(false);
  const [visibleTaxBill, setVisibleTaxBill] = useState(false);
  const [visibleUserView, setVisibleUserView] = useState(false);
  const [refresh, setRefresh] = useState(true);

  const [statusFilter, setStatusFilter] = useState(null);
  const [totalSubscribed, setTotalSubscribed] = useState(0);
  const [totalBillSent, setTotalBillSent] = useState(0);
  const [totalBillNoSent, setTotalBillNoSent] = useState(0);
  const [userId, setUserId] = useState(0);
  const [users, setUsers] = useState([]);

  const [orden, setOrden] = useState({
    conference: { id: parseInt(param.conferenceId) },
    ordenType: { id: 2 },
    paymentMethod: { id: 1 },
    status: { id: 1 },
    amount: 0,
    discountCode: "",
    Category: "",
    taxBill: false,
    RNC: "",
    users: [],
  });
  const [ordenId, setOrdenId] = useState(0);
  const [updateOrden, setUpdateOrden] = useState({
    id: 0,
    paymentMethod: { id: 0, name: "" },
    status: {
      id: 0,
      description: "",
      color: "",
    },
  });

  useEffect(() => {
    if (conference && (conference.id === 0 || conference.id === undefined)) {
      dispatch(GetConferenceAndOrdens(param.conferenceId, auth.UserInfo.email));
    }
  }, [auth.UserInfo.email, conference, dispatch, param.conferenceId]);

  useEffect(() => {
    if (
      orden.discountCode !== undefined &&
      orden.discountCode !== "" &&
      visibleNewOrden === true
    ) {
      dispatch(
        ValidateDiscountByCode(
          orden.discountCode,
          parseInt(param.conferenceId),
          orden.users.length,
          auth.UserInfo.email
        )
      );
    }
  }, [
    auth.UserInfo.email,
    dispatch,
    visibleNewOrden,
    orden.discountCode,
    orden.users.length,
    param.conferenceId,
  ]);

  useEffect(() => {
    if (
      refresh === true &&
      notification !== null &&
      notification.status !== "pending"
    ) {
      setVisibleNewOrden(false);
      setVisibleUser(false);
      setVisibleStatus(false);
      setVisibleTaxBill(false);
      setVisiblePaymentMethod(false);
      setVisiblDiscountCode(false);
      setRefresh(false);
    }
  }, [auth.UserInfo.email, notification, refresh, user.user]);

  useEffect(() => {
    if (
      conference !== undefined &&
      conference !== null &&
      conference.orders !== undefined &&
      conference.orders !== null &&
      conference.orders.length > 0 &&
      users.length === 0 &&
      getUsers
    ) {
      let userList = [];
      // eslint-disable-next-line array-callback-return
      conference.orders.map((e) => {
        if (
          e.ordenStatusId === 2 ||
          e.ordenStatusId === 10 ||
          e.ordenStatusId === 17 ||
          e.ordenStatusId === 18 ||
          e.ordenStatusId === 19 ||
          e.ordenStatusId === 20
        ) {
          // eslint-disable-next-line array-callback-return
          e.orders.map((order) => {
            userList.push({
              ...order.user,
              ordenId: order.id,
            });
          });
        }
      });

      setUsers(userList);
      getUsers = false;
    }
  }, [conference, users]);

  useEffect(() => {
    if (conference.orders !== undefined) {
      let ts = 0;
      for (const orden of conference.orders) {
        let quantity = orden.orders.length;
        ts += quantity;
      }
      setTotalSubscribed(ts);
    }
  }, [conference.orders]);

  useEffect(() => {
    if (conference.orders !== undefined) {
      let sb = 0;
      for (const orden of conference.orders) {
        if (orden.billSend === true) {
          sb++;
        }
      }
      setTotalBillSent(sb);
    }
  }, [conference.orders]);

  useEffect(() => {
    if (conference.orders !== undefined) {
      let sb = 0;
      for (const orden of conference.orders) {
        if (orden.billSend === false) {
          sb++;
        }
      }
      setTotalBillNoSent(sb);
    }
  }, [conference.orders]);

  const activeHandler = () => {
    dispatch(ActiveConference(param.conferenceId, auth.UserInfo.email));
  };
  const inactiveHandler = () => {
    dispatch(InactiveConference(param.conferenceId, auth.UserInfo.email));
  };

  let statusConference = (
    <>
      <span className="fw-bold me-2" style={{ color: "#000" }}>
        Estatus:&nbsp;
      </span>
      <Tag
        value="Inactivo"
        severity={"warning"}
        style={{ fontSize: "medium" }}
      ></Tag>
    </>
  );
  let activeSuspend;
  if (auth.roles !== undefined && auth.roles.includes("conferences.active")) {
    activeSuspend = (
      <Button
        label="Activar"
        rounded
        text
        raised
        className="p-button-success"
        style={{ marginLeft: "1%" }}
        onClick={activeHandler}
        icon={
          notification && notification.status === "pending"
            ? "pi pi-spin pi-spinner"
            : "pi pi-check"
        }
        disabled={notification && notification.status === "pending"}
      />
    );
  }
  if (conference.status) {
    if (
      auth.roles !== undefined &&
      auth.roles.includes("conferences.inactive")
    ) {
      activeSuspend = (
        <Button
          rounded
          text
          raised
          label="Suspender"
          className="p-button-warning"
          style={{ marginLeft: "1%" }}
          onClick={inactiveHandler}
          icon={
            notification && notification.status === "pending"
              ? "pi pi-spin pi-spinner"
              : "pi pi-ban"
          }
          disabled={notification && notification.status === "pending"}
        />
      );
    }
    statusConference = (
      <>
        <span className="fw-bold me-2" style={{ color: "#000" }}>
          Estatus:&nbsp;
        </span>
        <Tag
          value="Activo"
          severity={"success"}
          style={{ fontSize: "medium" }}
        ></Tag>
      </>
    );
  }

  let USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const buttonsDetails = (
    <>
      <div className="formgrid grid">
        <div className="field col-12 md:col-12">
          <Button
            rounded
            text
            raised
            label="Regresar"
            icon="pi pi-arrow-left"
            className="p-button-danger"
            style={{}}
            onClick={() => {
              navigate("/conferences");
            }}
          />
          {auth.roles !== undefined &&
            auth.roles.includes("conferences.edit") && (
              <Button
                rounded
                text
                raised
                label="Editar"
                icon="pi pi-file-edit"
                className="p-button-primary"
                style={{ marginLeft: "1%" }}
                onClick={() => {
                  navigate(`/conference/edit/${param.conferenceId}`);
                }}
              />
            )}
          {activeSuspend}
        </div>
      </div>
    </>
  );
  const actionsTable = (
    <>
      <div className="formgrid grid">
        {auth.roles !== undefined &&
          auth.roles.includes("conferences.report") && (
            <>
              <div className="field col-12 md:col-2">
                <Button
                  label="Ver Pagos"
                  rounded
                  text
                  raised
                  icon="pi pi-money-bill"
                  className="p-button-help"
                  style={{ width: "180px" }}
                  onClick={async () => {
                    await getBalanceReport(conference);
                  }}
                />
              </div>
              <div className="field col-12 md:col-2">
                <Button
                  label="Lista de usuarios"
                  rounded
                  text
                  raised
                  icon="pi pi-list"
                  className="p-button-success"
                  style={{ width: "180px" }}
                  onClick={async () => {
                    await getListUserReport(conference);
                  }}
                />
              </div>
              {auth.roles !== undefined &&
                auth.roles.includes("conferences.sendCertificate") && (
                  <div className="field col-12 md:col-2">
                    <Button
                      label="Certificados"
                      rounded
                      text
                      raised
                      icon="pi pi-list"
                      className="p-button-info"
                      style={{ width: "180px" }}
                      onClick={async () => {
                        setVisibleCertificate(true);
                      }}
                    />
                  </div>
                )}
            </>
          )}
        {auth.roles !== undefined &&
          auth.roles.includes("conferences.createOrden") && (
            <div className="field col-12 md:col-2">
              <Button
                label="Crear Orden"
                rounded
                text
                raised
                icon="pi pi-box"
                className="p-button-warning"
                style={{ width: "180px" }}
                onClick={() => {
                  // setUpdateOrden({ id: 0, status: { id: 1 } });
                  setVisibleNewOrden(true);
                }}
              />
            </div>
          )}
        {auth.roles !== undefined &&
          auth.roles.includes("conferences.quote") && (
            <div className="field col-12 md:col-2">
              <Button
                rounded
                text
                raised
                label="Cotizar"
                icon="pi pi-money-bill"
                severity="help"
                style={{ width: "180px" }}
                onClick={() => setVisibleNewQuote(true)}
              />
            </div>
          )}
        <div className="field col-12 md:col-2">
          <Button
            label="Asistencia"
            rounded
            text
            raised
            icon="pi pi-user-edit"
            className="p-button-info"
            style={{ width: "180px" }}
            onClick={async () => {
              setVisibleAttended(true);
            }}
          />
        </div>

        {auth.roles !== undefined &&
          auth.roles.includes("conferenceEvent.menu") && (
            <div className="field col-12 md:col-2">
              <Button
                rounded
                text
                raised
                label="Conferencias"
                icon="pi pi-calendar-plus"
                style={{
                  width: "180px",
                  color: "salmon",
                }}
                onClick={() => {
                  navigate(`/conference/events/${param.conferenceId}`);
                }}
              />
            </div>
          )}
      </div>
    </>
  );

  const subtitle = () => {
    let status = [];
    for (const element of conference.orders) {
      if (status.find((x) => x.id === element.status.id) === undefined) {
        const statusElement = {
          id: element.status.id,
          description: element.status.description,
          count: 1,
          color: element.status.color,
        };
        status.push(statusElement);
      } else {
        const found = status.find((x) => x.id === element.status.id);
        let quantity = element.orders.length;
        if (quantity === 1) {
          quantity++;
        }
        found.count += element.orders.length;
        status = [...status.filter((x) => x.id !== element.status.id), found];
      }
    }

    const totalElementCount = (
      <span style={{ color: "#fff" }}>{totalSubscribed}</span>
    );
    const totalBillSentCount = (
      <span style={{ color: "#fff" }}>{totalBillSent}</span>
    );
    const totalBillNoSentCount = (
      <span style={{ color: "#fff" }}>{totalBillNoSent}</span>
    );
    return (
      <>
        <div className="formgrid grid">
          {status.map((element, i) => {
            const elementCount = (
              <span key={i} style={{ color: "#fff" }}>
                {element.count}
              </span>
            );

            return (
              <div className="field col-12 md:col-3" id={element.id}>
                <Button
                  style={{
                    color: element.color,
                    fontSize: "xx-large",
                    marginLeft: "0px",
                  }}
                  onClick={() => {
                    setStatusFilter(element.description);
                  }}
                  text
                >
                  {element.description}({elementCount})
                </Button>
              </div>
            );
          })}
          <div className="field col-12 md:col-3">
            <Button
              style={{
                color: "#E41211",
                fontSize: "xx-large",
                textAlign: "right",
              }}
              onClick={() => {
                setStatusFilter("False");
              }}
              text
            >
              Facturas no enviadas({totalBillNoSentCount})
            </Button>
          </div>
          <div className="field col-12 md:col-3">
            <Button
              style={{
                color: "#9CC346",
                fontSize: "xx-large",
                textAlign: "right",
              }}
              onClick={() => {
                setStatusFilter("True");
              }}
              text
            >
              Facturas enviadas({totalBillSentCount})
            </Button>
          </div>

          <div className="field col-12 md:col-3">
            <Button
              style={{
                color: "#42ecf5",
                fontSize: "xx-large",
                textAlign: "right",
              }}
              onClick={() => {
                setStatusFilter(undefined);
              }}
              text
            >
              Total Inscritos({totalElementCount})
            </Button>
          </div>
        </div>
      </>
    );
  };

  /************************  Informacion de las columnas*/
  const OrdeIdTemplate = (orden) => {
    const button = (
      <Button
        link
        label={orden.id}
        onClick={() => {
          setVisibleOrdenDetail(true);
          setOrden(orden);
        }}
      />
    );
    return button;
  };
  const StatusOrdenTemplate = (orden) => {
    if (
      orden.status.id === 10 &&
      auth.roles.includes("conference.transferRecipt")
    ) {
      return (
        <Button
          icon="pi pi-download"
          rounded
          outlined
          label="Comprobante"
          severity="primary"
          tooltip="Descargar Comprobante"
          style={{
            width: "100%",
            height: "35px",
            marginTop: "2px",
            marginLeft: "2px",
          }}
          onClick={() => {
            dispatch(DownloadPaymentTransfer(orden.id, auth.UserInfo.email));
          }}
        />
      );
    }
    return (
      <span style={{ color: orden.status.color }}>
        {orden.status.description}
      </span>
    );
  };

  const TotalUserOrdenTemplate = (orden) => {
    let quantity = orden.orders.length;

    if (orden.status.id === 9) {
      quantity = orden.quantityOfUsers;
    }

    return <span>{quantity}</span>;
  };

  const amountWithTemplate = (orden) => {
    const discount = orden.discount;
    let quantity = orden.orders.length;
    if (
      orden.status.id === 9 &&
      orden.quantityOfUsers === orden.orders.length
    ) {
      quantity = orden.quantityOfUsers;
    } else if (
      orden.status.id === 9 &&
      orden.quantityOfUsers > orden.orders.length
    ) {
      quantity = orden.quantityOfUsers;
    }

    let discountValue = 0;
    if (discount !== undefined && discount.id !== 0) {
      if (discount.isValidDiscountCode) {
        if (discount.nominal) {
          discountValue = discount.discountNominal * quantity;
        }
        if (discount.percentage) {
          discountValue =
            conference.price * (discount.discountPercentage / 100) * quantity;
        }
      }
    }
    return USDollar.format(conference.price * quantity - discountValue);
  };
  const amountPerPeopleWithTemplate = (orden) => {
    const discount = orden.discount;

    let discountValue = 0;

    if (
      discount !== undefined &&
      discount.id !== 0 &&
      discount.isValidDiscountCode
    ) {
      if (discount.nominal) {
        discountValue = discount.discountNominal;
      }
      if (discount.percentage) {
        discountValue = conference.price * (discount.discountPercentage / 100);
      }
    }
    return USDollar.format(conference.price - discountValue);
  };
  const amountWithOutTemplate = () => {
    return USDollar.format(conference.price);
  };
  const usersTemplate = (order) => {
    if (
      order.participantsNames !== undefined &&
      order.participantsNames.length > 0
    ) {
      return (
        <>
          {order.participantsNames.split(",").map((fullName) => {
            return (
              <span>
                {fullName}
                <br />
              </span>
            );
          })}
        </>
      );
    }
    return <span></span>;
  };
  const paises = arregloPaises.map((pais) => ({
    code: pais.nombre,
    name: pais.nombre,
    flag: pais.abreviatura,
  }));
  const columnsOrders = [
    {
      field: "id",
      header: "Id",
      filter: true,
      sortable: false,
      body: (orden) => {
        return OrdeIdTemplate(orden);
      },
    },
    {
      field: "status.description",
      header: "Estado",
      filter: false,
      sortable: false,
      body: (orden) => {
        return StatusOrdenTemplate(orden);
      },
    },

    {
      header: "Cantidad",
      filter: false,
      sortable: false,
      body: (orden) => {
        return TotalUserOrdenTemplate(orden);
      },
    },
    {
      field: "conferences.price",
      header: "Precio",
      filter: false,
      sortable: false,
      body: (order) => {
        return amountWithOutTemplate(order);
      },
    },
    {
      header: "Precio con descuento",
      filter: false,
      sortable: false,
      body: (order) => {
        return amountPerPeopleWithTemplate(order);
      },
    },
    {
      field: "participantsNames",
      header: "Participantes",
      filter: true,
      sortable: false,
      body: (order) => {
        return usersTemplate(order);
      },
    },
    {
      field: "discount.discountCode",
      header: "Código",
      filter: false,
      sortable: false,
    },
    {
      header: "Total",
      filter: false,
      sortable: false,
      body: (orden) => {
        return amountWithTemplate(orden);
      },
    },

    {
      field: "billSend",
      header: "Factura Enviada",
      filter: false,
      sortable: false,
      body: (orden) => {
        return (
          <span>
            {orden.billSend !== undefined && orden.billSend === true
              ? "Si"
              : "No"}
          </span>
        );
      },
    },
  ];
  const columExpandable = [
    {
      field: "user.name",
      header: "Nombre",
      filterMatchMode: "contains",
    },
    {
      field: "user.lastName",
      header: "Apellido",
      filterMatchMode: "contains",
    },
    {
      field: "user.email",
      header: "Correo",
      filterMatchMode: "contains",
    },
    {
      field: "user.country",
      header: "País",
      filterMatchMode: "contains",
      body: (orden) => {
        const pais = paises.find((pais) => pais.name === orden.user.country);
        if (pais === undefined) {
          return <div></div>;
        }
        return (
          <div className="flex align-items-center" style={{ width: "100px" }}>
            <img
              alt={pais.name}
              src={`https://flagcdn.com/48x36/${pais.flag.toLowerCase()}.png`}
              style={{ width: "18px" }}
            />
            <div style={{ marginLeft: "5%" }}>{pais.name}</div>
          </div>
        );
      },
    },
  ];
  let actions = [];
  const buttons = (rowData) => {
    let buttons = [];
    const addUser = (
      <Button
        icon="pi pi-user-plus"
        rounded
        outlined
        label="Agregar"
        severity="primary"
        tooltip="Agregar Participante"
        style={{
          width: "115px",
          height: "35px",
          marginTop: "2px",
          marginLeft: "2px",
        }}
        onClick={() => {
          setOrdenId(rowData.id);
          setVisibleUser(true);
        }}
      />
    );

    const ChangeStatus = (
      <Button
        rounded
        outlined
        label="Estado"
        icon="pi pi-qrcode"
        severity="help"
        tooltip="Cambiar estado"
        style={{
          width: "115px",
          height: "35px",
          marginTop: "2px",
          marginLeft: "2px",
        }}
        onClick={() => {
          setVisibleStatus(true);
          setUpdateOrden({
            id: rowData.id,
            status: rowData.status,
            paymentMethod: rowData.paymentMethod,
          });
        }}
      />
    );
    const ChangePaymentMethod = (
      <Button
        rounded
        outlined
        label="Método"
        icon="pi pi-credit-card"
        severity="danger"
        style={{
          width: "115px",
          height: "35px",
          marginTop: "2px",
          marginLeft: "2px",
        }}
        tooltip="Cambiar Método de Pago"
        onClick={() => {
          setVisiblePaymentMethod(true);
          setUpdateOrden({
            id: rowData.id,
            paymentMethod: rowData.paymentMethod,
            status: rowData.status,
          });
        }}
      />
    );
    const ChangeDiscountMethod = (
      <Button
        rounded
        outlined
        label="Código"
        icon="pi pi-qrcode"
        severity="success"
        style={{
          width: "115px",
          height: "35px",
          marginTop: "2px",
          marginLeft: "2px",
        }}
        tooltip="Cambiar Codígo de Descuento"
        onClick={() => {
          setVisiblDiscountCode(true);
          setUpdateOrden({
            id: rowData.id,
            paymentMethod: rowData.paymentMethod,
            status: rowData.status,
          });
        }}
      />
    );
    const ChangeTaxBill = (
      <Button
        rounded
        outlined
        label="RNC"
        icon="pi pi-credit-card"
        severity="warning"
        style={{
          width: "115px",
          height: "35px",
          marginTop: "2px",
          marginLeft: "2px",
        }}
        tooltip="Cambiar RNC"
        onClick={() => {
          setVisibleTaxBill(true);
          setUpdateOrden({
            id: rowData.id,
            paymentMethod: rowData.paymentMethod,
            status: rowData.status,
            taxBill: rowData.taxBill,
            rnc: rowData.rnc,
          });
        }}
      />
    );

    const SendBiill = (
      <Button
        rounded
        outlined
        label="Factura"
        icon="pi pi-file-pdf"
        severity="secondary"
        style={{
          width: "115px",
          height: "35px",
          marginTop: "2px",
          marginLeft: "2px",
        }}
        tooltip="Enviar factura"
        onClick={() => {
          setVisibleSendBill(true);
          setOrden(rowData);
        }}
      />
    );

    if (
      rowData.status.id !== 2 &&
      rowData.status.id !== 7 &&
      rowData.status.id !== 9 &&
      auth.roles !== undefined &&
      auth.roles.includes("conferences.edit")
    ) {
      buttons.push(addUser);
    }

    if (
      rowData.status.id !== 2 &&
      rowData.status.id !== 7 &&
      auth.roles !== undefined &&
      auth.roles.includes("conferences.edit")
    ) {
      buttons.push(ChangeStatus);
    }
    if (
      rowData.status.id !== 2 &&
      rowData.status.id !== 7 &&
      rowData.status.id !== 9 &&
      auth.roles !== undefined &&
      auth.roles.includes("conferences.edit")
    ) {
      buttons.push(ChangePaymentMethod);
      buttons.push(ChangeDiscountMethod);
    }
    if (
      rowData.status.id !== 2 &&
      rowData.status.id !== 7 &&
      rowData.status.id !== 9 &&
      auth.roles !== undefined &&
      auth.roles.includes("conferences.TaxBill")
    ) {
      buttons.push(ChangeTaxBill);
    }

    if (
      (rowData.status.id === 2 ||
        rowData.status.id === 18 ||
        rowData.status.id === 5 ||
        rowData.status.id === 10) &&
      auth.roles !== undefined &&
      auth.roles.includes("conference.sendBill")
    ) {
      buttons.push(SendBiill);
    }

    return buttons;
  };
  actions.push(buttons);

  let actionsExpandable = [];
  const buttonsExpandable = (rowData) => {
    let buttons = [];
    const RemoveUserButton = (
      <>
        {refresh === true && (
          <>
            <Loading visible={refresh} />{" "}
          </>
        )}
        {refresh === false && (
          <>
            <Button
              label="Remover"
              icon="pi pi-times"
              className="p-button-rounded p-button-danger p-button-text"
              onClick={() => {
                dispatch(
                  RemoveUser(
                    rowData.id,
                    rowData.user.id,
                    conference.price,
                    auth.UserInfo.email
                  )
                );
                setRefresh(true);
                setTimeout(() => {
                  window.location.reload();
                }, 2500);
              }}
            />
          </>
        )}
      </>
    );
    const SendCertifcate = (
      <>
        {refresh === true && (
          <>
            <Loading visible={refresh} />
          </>
        )}
        {refresh === false && (
          <Button
            label="Enviar Certificado"
            icon="pi pi-envelope"
            className="p-button-rounded p-button-primary p-button-text"
            onClick={() => {
              dispatch(
                SendAgain(rowData.user.id, rowData.id, auth.UserInfo.email)
              );
              setRefresh(true);
              setTimeout(() => {
                window.location.reload();
              }, 2500);
            }}
          />
        )}
        {refresh === false && (
          <>
            <Button
              label="Descargar Certificado"
              icon="pi pi-download"
              className="p-button-rounded p-button-help p-button-text"
              onClick={() => {
                dispatch(
                  DownloadCertificate(
                    rowData.user.id,
                    rowData.id,
                    auth.UserInfo.email
                  )
                );
              }}
            />
          </>
        )}
      </>
    );
    const ViewUser = (
      <Button
        label="Ver usuario"
        icon="pi pi-info-circle"
        className="p-button-rounded p-button-help p-button-text"
        onClick={() => {
          setVisibleUserView(true);
          setUserId(rowData.user.id);
        }}
      />
    );
    buttons.push(ViewUser);

    const count = conference.orders.find((x) => x.id === rowData.id).orders
      .length;
    if (rowData.status.id !== 2 && rowData.status.id !== 7 && count > 1) {
      buttons.push(RemoveUserButton);
    }
    if (rowData.user.certificateSent === true) {
      buttons.push(SendCertifcate);
    }
    return buttons;
  };
  actionsExpandable.push(buttonsExpandable);
  /************************ Fin Informacion de las columnas*/

  /************************ Methods*/

  return (
    <>
      {conference && (conference.id === undefined || conference.id === 0) && (
        <>
          <Loading visible={true} />
        </>
      )}
      {conference && conference.id > 0 && !refresh && (
        <>
          <div className="card" style={{ width: "97.1%" }}>
            <Accordion activeIndex={1}>
              <AccordionTab header="Información General">
                <Card
                  style={{
                    backgroundColor: "#0d3c61",
                    marginLeft: "0%",
                    marginTop: "8px",
                    paddingBottom: "10px",
                    boxShadow: "none",
                    width: "98%",
                    border: "solid #326fd1",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#fff",
                      paddingBottom: "80px",
                      paddingLeft: "10px",
                      boxShadow: "none",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <ConferenceDetailComponent
                      conference={conference}
                      statusConference={statusConference}
                      buttons={buttonsDetails}
                      isDetail={true}
                    />
                  </div>
                </Card>
              </AccordionTab>
              <AccordionTab header="Ordenes">
                <ABADatatableExpandable
                  filter={statusFilter}
                  title=""
                  subtitle={subtitle}
                  values={conference.orders}
                  rows={100}
                  rowsPerPageOptions={[10, 30, 50]}
                  Columns={columnsOrders}
                  columExpandable={columExpandable}
                  width="8%"
                  actions={actions}
                  actionsTable={actionsTable}
                  actionsExpandable={actionsExpandable}
                />
                <OrdenDetailComponent
                  orden={orden}
                  visibleOrdenDetail={visibleOrdenDetail}
                  setVisibleOrdenDetail={setVisibleOrdenDetail}
                />
                <NewQuoteComponent
                  ordenTypeId={2}
                  conference={conference}
                  setVisibleNewQuote={setVisibleNewQuote}
                  visibleNewQuote={visibleNewQuote}
                />
                <CertificatesComponent
                  conference={conference}
                  setRefresh={setRefresh}
                  visibleCertificate={visibleCertificate}
                  setVisibleCertificate={setVisibleCertificate}
                />

                <AttendedComponent
                  users={users}
                  visibleAttended={visibleAttended}
                  setVisibleAttended={setVisibleAttended}
                />
                <SendBiillComponent
                  orden={orden}
                  visibleSendBill={visibleSendBill}
                  setVisibleSendBill={setVisibleSendBill}
                />
                <NewOrderComponent
                  visibleNewOrden={visibleNewOrden}
                  setVisibleAttended={setVisibleAttended}
                />
                <StatusComponent
                  conference={conference}
                  updateOrden={updateOrden}
                  visibleStatus={visibleStatus}
                  setVisibleStatus={setVisibleStatus}
                />
                <PaymentMethodComponent
                  conference={conference}
                  updateOrden={updateOrden}
                  visiblePaymentMethod={visiblePaymentMethod}
                  setVisiblePaymentMethod={setVisiblePaymentMethod}
                />
                <DiscountComponent
                  orden={orden}
                  updateOrden={updateOrden}
                  visibleDiscountCode={visibleDiscountCode}
                  setVisiblDiscountCode={setVisiblDiscountCode}
                />
                <TaxBillComponent
                  updateOrden={updateOrden}
                  setUpdateOrden={setUpdateOrden}
                  visibleTaxBill={visibleTaxBill}
                  setVisibleTaxBill={setVisibleTaxBill}
                />
                <AddUserComponent
                  orden={orden}
                  ordenId={ordenId}
                  visibleUser={visibleUser}
                  setVisibleUser={setVisibleUser}
                />
                <UserViewComponent
                  visibleUserView={visibleUserView}
                  setVisibleUserView={setVisibleUserView}
                  userId={userId}
                  
                />
              </AccordionTab>
            </Accordion>
          </div>
        </>
      )}
    </>
  );
};
export default ConferenceDetail;
