import "./Footer.css";

const Footer = (props) => {
  const year = new Date().getFullYear();
  return (
    <div
      className="footerSize"
      style={{
        color: "#f3f8ff",
        textAlign: "center",
        marginBottom: "0px",
        paddingBottom: "0px",
        borderTop: "solid 1px white",
        marginTop: "1.5px",
      }}
    >
      © {year}, creado por la Asociación Dominicana de Bancos Múltiples Inc.
    </div>
  );
};
export default Footer;
