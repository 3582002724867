import { Card } from "primereact/card";
const UserDetailComponent = (props) => {
  return (
    <>
      <Card
        style={{
          backgroundColor: "#fff",
          marginLeft: "0%",
          marginTop: "8px",
          boxShadow: "none",
          width: "98%",
        }}
      >
        <div
          className="user-info text-center"
          style={{ borderBottom: "solid #000", paddingBottom: "4px" }}
        >
          <h2
            id="header"
            className="mb-2"
            style={{ color: "#000", textDecoration: "underline" }}
          >
            {props.user.name.toUpperCase() +
              " " +
              props.user.lastName.toUpperCase()}
          </h2>
          <span
            className="badge bg-label-secondary"
            style={{
              fontSize: "large",

              fontWeight: "bold",
            }}
          >
            Nombre completo
          </span>
        </div>
        <h2 style={{ color: "#000" }}>Información General</h2>
        <div className="formgrid grid">
          <div className="field col-12 md:col-4 ">
            <label style={{ color: "#000", fontSize: "large" }}>
              Documento:
            </label>
            <label
              style={{
                marginLeft: "1%",
                fontSize: "large",
              }}
            >
              {props.user.identification}
            </label>
          </div>
          <div className="field col-12 md:col-4 ">
            <label style={{ color: "#000", fontSize: "large" }}>
              Correo Electrónico:
            </label>
            <label
              style={{
                marginLeft: "1%",
                fontSize: "large",
              }}
            >
              {props.user.email}
            </label>
          </div>
          <div className="field col-12 md:col-4 ">
            <label style={{ color: "#000", fontSize: "large" }}>Celular:</label>
            <label
              style={{
                marginLeft: "1%",
                fontSize: "large",
              }}
            >
              {props.user.cellPhone}
            </label>
          </div>
        </div>
        <hr className="my-4 mx-n4" />
        <h2 style={{ color: "#000" }}>Dirección</h2>
        <div className="formgrid grid">
          <div className="field col-12 md:col-4 ">
            <label style={{ color: "#000", fontSize: "large" }}>País:</label>
            <label
              style={{
                marginLeft: "1%",
                fontSize: "large",
              }}
            >
              {props.user.country}
            </label>
          </div>
          <div className="field col-12 md:col-4 ">
            <label style={{ color: "#000", fontSize: "large" }}>Ciudad:</label>
            <label
              style={{
                marginLeft: "1%",
                fontSize: "large",
              }}
            >
              {props.user.city}
            </label>
          </div>
          <div className="field col-12 md:col-4 ">
            <label style={{ color: "#000", fontSize: "large" }}>
              Domicilio:
            </label>
            <label
              style={{
                marginLeft: "1%",
                fontSize: "large",
              }}
            >
              {props.user.address}
            </label>
          </div>
        </div>
        <hr className="my-4 mx-n4" />
        <h2 style={{ color: "#000" }}>Información Laboral</h2>
        <div className="formgrid grid">
          <div className="field col-12 md:col-4 ">
            <label style={{ color: "#000", fontSize: "large" }}>Empresa:</label>
            <label
              style={{
                marginLeft: "1%",
                fontSize: "large",
              }}
            >
              {props.user.company.name}
            </label>
          </div>
          <div className="field col-12 md:col-4 ">
            <label style={{ color: "#000", fontSize: "large" }}>Cargo:</label>
            <label
              style={{
                marginLeft: "1%",
                fontSize: "large",
              }}
            >
              {props.user.charge.name}
            </label>
          </div>
          <div className="field col-12 md:col-4 ">
            <label style={{ color: "#000", fontSize: "large" }}>
              Departamento:
            </label>
            <label
              style={{
                marginLeft: "1%",
                fontSize: "large",
              }}
            >
              {props.user.department.name}
            </label>
          </div>
        </div>
        <div className="formgrid grid">
          <div className="field col-12 md:col-12 ">{props.buttons}</div>
        </div>
      </Card>
    </>
  );
};
export default UserDetailComponent;
