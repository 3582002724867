import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import {
  AddSpeaker,
  GetConferenceEventById,
  RemoveSpeaker,
} from "../../../store/conferencesEvent-store/conferenceEvent-actions";
import { Card } from "primereact/card";
import { Rating } from "primereact/rating";
import ABADatatable from "../../../components/ABADatatable";
import { Button } from "primereact/button";
import DialogModal from "../../../components/DialogDialogModal";
import { getTokenInfo } from "../../../utils/Guard";
import Loading from "../../../components/UI/Loading";
import { conferenceEventActions } from "../../../store/conferencesEvent-store/conferenceEvent-slice";

const ConferenceEventDetail = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [visibleUser, setVisibleUser] = useState(false);

  const conferenceEvent = useSelector(
    (state) => state.conferenceEvent.conferenceEvent
  );
  const auth = useSelector((state) => state.auth);
  const user = useSelector((state) => state.user.user);
  const userifno = getTokenInfo();

  useEffect(() => {
    if (
      conferenceEvent &&
      (conferenceEvent.id === undefined || conferenceEvent.id === 0)
    ) {
      dispatch(
        GetConferenceEventById(params.conferenceEventId, auth.UserInfo.email)
      );
    }
  }, [params.conferenceEventId, conferenceEvent, dispatch, auth, userifno]);

  const getstarts = (value) => {
    const stars = value.rating ? value.rating : value.rating;
    return (
      <Rating
        value={stars}
        onIcon={
          <i className="pi pi-star-fill" style={{ color: "#F9C02D" }}></i>
        }
        readOnly
        cancel={false}
      />
    );
  };
  const getQuestionsTemplate = (rowData) => {
    return <label>¿{rowData.question}?</label>;
  };
  const addUserHandler = () => {
    if (user !== undefined && user.email !== "") {
      const confEvent = {
        id: params.conferenceEventId,
        conferenceId: conferenceEvent.conferenceId,
        conferenceEventId: conferenceEvent.Id,
        userId: user.id,
      };
      dispatch(AddSpeaker(confEvent, auth.UserInfo.email));
      visibleUser(false);
    } else {
      alert("Debe seleccionar un usuario");
    }
  };
  const removeUserHandler = (rowData) => {
    dispatch(RemoveSpeaker(rowData.id, auth.UserInfo.email));
  };
  const getSpeakerName = (rowData) => {
    return <label>{rowData.fullName}</label>;
  };
  const getAskingName = (rowData) => {
    return <label>{rowData.fullName}</label>;
  };

  const columnsSpeakers = [
    {
      field: "speaker.fullName",
      header: "Conferencista",
      body: getSpeakerName,
    },
    {
      header: "Valoración",
      body: getstarts,
    },
  ];
  const columnsQuestions = [
    {
      field: "user.fullName",
      header: "Participante",
      body: getAskingName,
    },
    {
      field: "question",
      header: "Preguntas",
      body: getQuestionsTemplate,
    },
    {
      field: "rating",
      header: "Valoración",
      body: getstarts,
    },
  ];
  var options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  let actions = [];
  const buttons = (rowData) => {
    let buttons = [
      <Button
        label="Remover"
        icon="pi pi-minus-circle"
        className="p-button-rounded p-button-danger p-button-text"
        onClick={() => {
          removeUserHandler(rowData);
        }}
      />,
    ];
    return buttons;
  };
  actions.push(buttons);
  return (
    <>
      {conferenceEvent &&
        (conferenceEvent.id === 0 || conferenceEvent.id === undefined) && (
          <>
            <Loading visible={true} />
          </>
        )}
      {conferenceEvent && conferenceEvent.id > 0 && (
        <>
          <Card
            style={{
              backgroundColor: "#0d3c61",
              marginLeft: "0px",
              marginTop: "8px",
              paddingBottom: "10px",
              boxShadow: "none",
              width: "97%",

              border: "solid #326fd1",
            }}
          >
            <div
              style={{
                backgroundColor: "#fff",
                paddingBottom: "80px",
                paddingLeft: "10px",
                boxShadow: "none",
                width: "100%",
                height: "100%",
              }}
            >
              <div className="formgrid grid" style={{ marginBottom: "-50px" }}>
                <div className="field col-12 md:col-12 mt-3">
                  <label
                    style={{
                      color: "#000",
                      fontSize: "large",
                      fontWeight: "bolder",
                    }}
                  >
                    Nombre de la conferencia:
                  </label>
                  <label
                    style={{
                      marginLeft: "1%",
                      fontSize: "large",
                    }}
                  >
                    {conferenceEvent.name}
                  </label>
                </div>
                <div className="field col-12 md:col-3">
                  <label
                    style={{
                      color: "#000",
                      fontSize: "large",
                      fontWeight: "bolder",
                    }}
                  >
                    Fecha:
                  </label>
                  <label
                    style={{
                      marginLeft: "1%",
                      fontSize: "large",
                    }}
                  >
                    {conferenceEvent && conferenceEvent.eventDate
                      ? new Date(conferenceEvent.eventDate).toLocaleDateString(
                          "es-ES",
                          options
                        )
                      : ""}
                  </label>
                </div>
                <div className="field col-12 md:col-3">
                  <label
                    style={{
                      color: "#000",
                      fontSize: "large",
                      fontWeight: "bolder",
                    }}
                  >
                    Hora de inicio:
                  </label>
                  <label
                    style={{
                      marginLeft: "1%",
                      fontSize: "large",
                    }}
                  >
                    {conferenceEvent.startTime}
                  </label>
                </div>
                <div className="field col-12 md:col-3">
                  <label
                    style={{
                      color: "#000",
                      fontSize: "large",
                      fontWeight: "bolder",
                    }}
                  >
                    Hora de fin:
                  </label>
                  <label
                    style={{
                      marginLeft: "1%",
                      fontSize: "large",
                    }}
                  >
                    {conferenceEvent.endTime}
                  </label>
                </div>
                <div className="field col-12 md:col-3">
                  <label
                    style={{
                      color: "#000",
                      fontSize: "large",
                      fontWeight: "bolder",
                    }}
                  >
                    Lugar:
                  </label>
                  <label
                    style={{
                      marginLeft: "1%",
                      fontSize: "large",
                    }}
                  >
                    {conferenceEvent.location}
                  </label>
                </div>
                <div className="field col-12 md:col-3">
                  <label
                    style={{
                      color: "#000",
                      fontSize: "large",
                      fontWeight: "bolder",
                    }}
                  >
                    Valoracion:
                  </label>
                  <label
                    style={{
                      marginLeft: "1%",
                      fontSize: "large",
                    }}
                  >
                    {getstarts(conferenceEvent)}
                  </label>
                </div>
                <div className="field col-12 md:col-12">
                  <Button
                    rounded
                    text
                    raised
                    style={{ backgroundColor: "#fff" }}
                    label="Regresar"
                    icon="pi pi-arrow-left"
                    className="p-button-danger"
                    onClick={() => {
                      dispatch(
                        conferenceEventActions.setConferenceEvent({
                          conferenceEvent: { id: 0 },
                        })
                      );
                      navigate(
                        `/conference/events/${conferenceEvent.conferenceId}`
                      );
                    }}
                  />
                  {auth.roles !== undefined &&
                    auth.roles.includes("conferenceEvent.edit") && (
                      <Button
                        rounded
                        text
                        raised
                        style={{ backgroundColor: "#fff", marginLeft: "1%" }}
                        label="Editar"
                        icon="pi pi-file-edit"
                        className="p-button-primary"
                        onClick={() => {
                          navigate(
                            `/conference/event/edit/${conferenceEvent.conferenceId}/${conferenceEvent.id}`
                          );
                        }}
                      />
                    )}
                </div>
              </div>
            </div>
          </Card>
          <DialogModal
            email={auth.UserInfo.email}
            header="Conferencistas"
            visible={visibleUser}
            setVisible={setVisibleUser}
            usuario={user}
            usuarios={conferenceEvent.Speakers}
            addUserHandler={addUserHandler}
            refresh={false}
          />
          <div>
            <ABADatatable
              title="Conferencistas"
              dontshowMenu="true"
              values={conferenceEvent.speakers}
              rows={5}
              rowsPerPageOptions={[5, 10, 20]}
              Columns={columnsSpeakers}
              actions={actions}
              width="20%"
              height="65%"
              createButton={{
                label: "Agregar Conferencista",
                onClick: () => {
                  setVisibleUser(true);
                },
                permission: "conferenceEvent.create",
              }}
            />
          </div>
          <ABADatatable
            title="Preguntas"
            dontshowMenu="true"
            values={conferenceEvent.questions}
            rows={5}
            rowsPerPageOptions={[5, 10, 20]}
            Columns={columnsQuestions}
            actions={[]}
            width="20%"
            createButton={{}}
          />
        </>
      )}
    </>
  );
};
export default ConferenceEventDetail;
