import { useSelector } from "react-redux";
import { CreateConference } from "../../../store/conferences-store/conference-actions";
import ConferenceForms from "../../../components/Forms/ConferenceForm";

const ConferencesCreate = () => {
  const conference = useSelector((state) => state.conference.conference);

  return (
    <>
      <ConferenceForms   
        actionConference={CreateConference}
        conference={conference}
      />
    </>
  );
};
export default ConferencesCreate;
