import { uiActions } from "../ui-slice";
import { courseCategoryActions } from "./coursecategory-slice";

export const GetCourseCategories = (email, showNotification, getAll) => {
  return async (dispatch) => {
    if (showNotification) {
      dispatch(
        uiActions.showNotification({
          status: "pending",
          title: "Solicitando categorías",
          message: "Solicitando categorías al servidor.",
        })
      );
    }
    const GetCoursecategoriesInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/course/category/getall`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo obtener las categorías");
      }
      const data = await response.json();
      return data;
    };

    try {
      const response = await GetCoursecategoriesInfo();

      if (response.result.errorCode === "OK") {
        if (getAll) {
          dispatch(
            courseCategoryActions.setcourseCategories({
              courseCategories: response.data.map((courseCategory) => {
                return {
                  ...courseCategory,
                  status:
                    courseCategory.status === true ? "Activo" : "Inactivo",
                };
              }),
            })
          );
        } else {
          dispatch(
            courseCategoryActions.setcourseCategories({
              courseCategories: response.data
                .filter((courseCategory) => courseCategory.status === true)
                .map((courseCategory) => {
                  return {
                    ...courseCategory,
                  };
                }),
            })
          );
        }
        if (showNotification) {
          dispatch(
            uiActions.showNotification({
              status: "success",
              title: "Datos Cargados",
              message: response.result.message,
            })
          );
        }
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          courseCategoryActions.setcourseCategories({
            courseCategories: [],
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo obtener las categorías",
        })
      );
    }
  };
};

export const GetCourseCategory = (id, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Solicitando Categoría",
        message: "Solicitando categoría al servidor.",
      })
    );
    const getOrdenTypeInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/course/category/getById`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            Id: id,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo obtener el categoría");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await getOrdenTypeInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          courseCategoryActions.setcourseCategory({
            courseCategory: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Datos Cargados",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          courseCategoryActions.setcourseCategory({
            courseCategory: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo obtener el categoría",
        })
      );
    }
  };
};

export const CreateCourseCategory = (courseCategory, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Creando Categoría",
        message: "Solicitando creación de la categoría al servidor.",
      })
    );
    const createOrdenTypeInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/course/category/create`,
        {
          method: "POST",
          body: JSON.stringify({
            ...courseCategory,
            UserName: email,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo crear el categoría");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await createOrdenTypeInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          courseCategoryActions.setcourseCategory({
            courseCategory: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Categoría Creado",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          courseCategoryActions.setcourseCategory({
            courseCategory: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo crear el categoría",
        })
      );
    }
  };
};

export const UpadateCourseCategory = (courseCategory, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Actualizando el categoría",
        message: "Solicitando actualización de la categoría al servidor.",
      })
    );
    const updateOrdenTypeInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/course/category/update`,
        {
          method: "POST",
          body: JSON.stringify({
            ...courseCategory,
            UserName: email,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo actualizar el categoría");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await updateOrdenTypeInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          courseCategoryActions.setcourseCategory({
            courseCategory: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Categoría actualizado",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          courseCategoryActions.setcourseCategory({
            courseCategory: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo actualizar el categoría",
        })
      );
    }
  };
};

export const ActiveCourseCategory = (id, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Activando categoría",
        message: "Solicitando activación de la categoría al servidor.",
      })
    );
    const activeOrdenTypeInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/course/category/active`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            Id: id,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo activar el categoría");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await activeOrdenTypeInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          courseCategoryActions.setcourseCategory({
            courseCategory: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Categoría Activado",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          courseCategoryActions.setcourseCategory({
            courseCategory: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo activar el categoría",
        })
      );
    }
  };
};

export const InactiveCourseCategory = (id, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Inactivando categoría",
        message: "Solicitando inactivación de la categoría al servidor.",
      })
    );
    const inactiveCouretypeInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/course/category/inactive`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            Id: id,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo inactivar el categoría");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await inactiveCouretypeInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          courseCategoryActions.setcourseCategory({
            courseCategory: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Categoría Inactivado",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          courseCategoryActions.setcourseCategory({
            courseCategory: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo inactivar el categoría",
        })
      );
    }
  };
};
