import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import ABADatatable from "../../../components/ABADatatable";
import { GetCourseCategories } from "../../../store/coursecategory-store/coursecategory-actions";
import { courseCategoryActions } from "../../../store/coursecategory-store/coursecategory-slice";


const CourseCategoryHomePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const courseCategories = useSelector((state) => state.courseCategory.courseCategories);
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    if (auth.roles !== undefined && auth.roles.includes("coursecategory.showAll")) {
      dispatch(GetCourseCategories(auth.UserInfo.email, true, true));
    }
  }, [dispatch, auth]);

  const viewDetailHanlder = (val) => {
    navigate(`/course/category/detail/${val.id}`);
  };
  const editHanlder = (val) => {
    navigate(`/course/category/edit/${val.id}`);
  };
  const createHanlder = () => {
    dispatch(
      courseCategoryActions.setcourseCategory({
        courseCategory: {
          category: "",
        },
      })
    );
    navigate(`/course/category/create`);
  };
  const backHanlder = () => {
    navigate(`/`);
  };
  let actions = [];
  const buttons = (rowData) => {
    let buttons = [];
    const buttonEdit = (
      <Button
        label="Editar"
        icon="pi pi-file-edit"
        className="p-button-rounded p-button-success p-button-text"
        onClick={() => {
          editHanlder(rowData);
        }}
      />
    );
    const buttonView = (
      <Button
        label="Ver"
        icon="pi pi-info-circle"
        className="p-button-rounded p-button-text"
        onClick={() => {
          viewDetailHanlder(rowData);
        }}
      />
    );
    if (auth.roles !== undefined && auth.roles.includes("coursecategory.edit")) {
      buttons.push(buttonEdit);
    }
    if (auth.roles !== undefined && auth.roles.includes("coursecategory.view")) {
      buttons.push(buttonView);
    }
    return buttons;
  };
  actions.push(buttons);

  const columns = [
    {
      field: "category",
      header: "Tipo de course",
      filterMatchMode: "contains",
    },
    {
      field: "status",
      header: "Estado",
      filterMatchMode: "contains",
    },
  ];
  return (
    <>
      <ABADatatable
        title="Categoria de Cursos"
        values={courseCategories}
        rows={5}
        rowsPerPageOptions={[5, 10, 20]}
        Columns={columns}
        actions={actions}
        createButton={{
          label: "Crear Categoria",
          onClick: createHanlder,
          permission: "coursecategory.create",
        }}
        backButton={{
          label: "Regresar",
          onClick: backHanlder,
          permission: "goBack",
        }}
      />
    </>
  );
};
export default CourseCategoryHomePage;
