import React, { useEffect, useRef, useState } from "react";
import Loading from "../UI/Loading";
import YesNoModal from "../YesNoModal";
import { useDispatch, useSelector } from "react-redux";
import { SendCertificate } from "../../store/user-store/user-actions";
import generatePDF, { Margin, Resolution } from "react-to-pdf";
import CerticateCOPLAFT2024 from "../../Templates/CerticateCOPLAFT2024";

let pdfs = [];

const CertificatesComponent = (props) => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [showLoading, setShowLoading] = useState(false);
  const [certificates] = useState([]);
  const [time, setTime] = useState(120); // 120 segundos (2 minutos)
  const [certificatesSent, setCertificatesSent] = useState(false);

  let certificateTemplateRefs = useRef([]);
  // let [pdfs, setPdfs] = useState([]);

  useEffect(() => {
    if (pdfs.length > 0 && showLoading === true) {
      dispatch(SendCertificate(pdfs, auth.UserInfo.email));
    }
  }, [auth.UserInfo.email, dispatch, showLoading]);

  useEffect(() => {
    if (time > 0 && certificatesSent === true && showLoading === true) {
      const timerId = setInterval(() => {
        setTime(time - 1);
      }, 1000); // Actualiza cada segundo

      // Limpia el intervalo cuando el componente se desmonte o cuando cambie el valor de 'time'
      return () => clearInterval(timerId);
    }
  }, [certificatesSent, showLoading, time]);

  useEffect(() => {
    if (time === 0 && certificatesSent === true && showLoading === true) {
      window.location.reload();
    }
  }, [certificatesSent, showLoading, time]);

  useEffect(() => {
    if (
      props.conference !== undefined &&
      props.conference !== null &&
      props.conference.orders !== undefined &&
      props.conference.orders !== null &&
      props.conference.orders.length > 0 &&
      certificateTemplateRefs.current.length === 0 &&
      pdfs.length === 0 &&
      certificates.length === 0
    ) {
      let pdfsList = [];
      // eslint-disable-next-line array-callback-return
      props.conference.orders.map((e) => {
        if (
          e.ordenStatusId === 2 ||
          e.ordenStatusId === 10 ||
          e.ordenStatusId === 17 ||
          e.ordenStatusId === 18 ||
          e.ordenStatusId === 19 ||
          e.ordenStatusId === 20
        ) {
          console.log(e.ordenStatusId);
          // eslint-disable-next-line array-callback-return
          e.orders.map((order) => {
            if (
              order.user.assitance === true &&
              order.user.certificateSent === false
            ) {
              pdfsList.push({
                user: { ...order.user },
                ordenId: order.id,
                pdf: null,
              });
              certificateTemplateRefs.current.push(React.createRef());
              certificates.push({
                user: order.user,
                conference: props.conference,
                ordenId: order.id,
              });
            }
          });
          // setPdfs(pdfsList);
          pdfs = [...pdfsList];
        }
      });
    }
  }, [certificates, props.conference]);

  const handleGenerateCertifitcates = async () => {
    let pdfsList = [];
    let certificateRefs = [...certificateTemplateRefs.current];

    for (let i = 0; i < certificateRefs.length; i++) {
      const fileName = `${pdfs[i].ordenId}-${pdfs[i].user.id}-${pdfs[
        i
      ].user.name.replace(" ", "_")}_${pdfs[i].user.lastName.replace(
        " ",
        "_"
      )}.pdf`;
      const options = {
        filename: fileName,
        method: "build",
        resolution: Resolution.MEDIUM,
        page: {
          margin: Margin.SMALL,
          format: "a4",
          orientation: "landscape",
        },
        canvas: {
          mimeType: "image/png",
          qualityRatio: 1,
        },
        overrides: {
          pdf: {
            compress: true,
          },
          canvas: {
            useCORS: true,
          },
        },
      };
      const pdf = await generatePDF(certificateRefs[i], options);
      const pdfBlob = pdf.output("blob");
      const pdfFile = new File([pdfBlob], fileName, {
        type: "application/pdf",
      });

      pdfsList.push({
        ...pdfs[i].user,
        ordenId: pdfs[i].ordenId,
        pdf: pdfFile,
      });
    }
    setTime(15 * pdfsList.length);
    // setPdfs(pdfsList);
    pdfs = [...pdfsList];
    setShowLoading(true);
    setCertificatesSent(true);
  };
  // Formatear el tiempo a minutos y segundos
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };
  return (
    <>
      <YesNoModal
        content={
          <>
            {showLoading && (
              <>
                <Loading visible={showLoading}>
                  <div style={{ textAlign: "center" }}>
                    <h1>Tiempo de espera</h1>
                    <h2>{formatTime(time)}</h2>
                    {time === 0 && <h3>¡El tiempo ha terminado!</h3>}
                  </div>
                </Loading>
              </>
            )}
            {!showLoading &&
              certificates.map((certificate, index) => {
                return (
                  <div
                    key={index++}
                    ref={certificateTemplateRefs.current[index]}
                  >
                    {certificate.conference.conferecenCodeName ===
                      "COPLAFT2024" && (
                      <CerticateCOPLAFT2024
                        key={index}
                        user={certificate.user}
                        conference={certificate.conference}
                        ordenId={certificate.ordenId}
                      />
                    )}
                  </div>
                );
              })}
          </>
        }
        ExternalAction={!certificatesSent}
        actionButtonLabel="Enviar Certificados"
        actionIcon="pi pi-envelope"
        actionSeverity="primary"
        actionHandler={handleGenerateCertifitcates}
        visible={props.visibleCertificate}
        setVisible={props.setVisibleCertificate}
        showButton={false}
      />
    </>
  );
};
export default CertificatesComponent;
