import { paymentMethodActions } from "./paymentmethod-slice";
import { uiActions } from "../ui-slice";

export const GetPaymentMethods = (email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Solicitando métodos de pago",
        message: "Solicitando métodos de pago al servidor.",
      })
    );
    const GetPaymentMethodInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/orden/paymentmethod/getall`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo obtener los métodos de pago");
      }
      const data = await response.json();
      return data;
    };

    try {
      const response = await GetPaymentMethodInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          paymentMethodActions.setPaymentMethods({
            paymentMethods: response.data.map((paymentMethod) => {
              return {
                ...paymentMethod,
                status: paymentMethod.status === true ? "Activo" : "Inactivo",
              };
            }),
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Datos Cargados",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          paymentMethodActions.setPaymentMethods({
            paymentMethods: [],
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo obtener los métodos de pago",
        })
      );
    }
  };
};

export const GetPaymentMethod = (id, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Solicitando Método de pago",
        message: "Solicitando método de pago al servidor.",
      })
    );
    const getPaymentMethodInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/orden/paymentmethod/getById`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            Id: id,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo obtener el método de pago");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await getPaymentMethodInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          paymentMethodActions.setPaymentMethod({
            paymentMethod: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Datos Cargados",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          paymentMethodActions.setPaymentMethod({
            paymentMethod: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo obtener el método de pago",
        })
      );
    }
  };
};

export const CreatePaymentMethod = (paymentMethod, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Creando Método de pago",
        message: "Solicitando Creación del método de pago al servidor.",
      })
    );
    const createPaymentMethodInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/orden/paymentmethod/create`,
        {
          method: "POST",
          body: JSON.stringify({
            ...paymentMethod,
            UserName: email,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo crear el método de pago");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await createPaymentMethodInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          paymentMethodActions.setPaymentMethod({
            paymentMethod: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Método de pago Creado",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          paymentMethodActions.setPaymentMethod({
            paymentMethod: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo crear el método de pago",
        })
      );
    }
  };
};

export const UpadatePaymentMethod = (paymentMethod, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Actualizando el método de pago",
        message: "Solicitando Actualización del método de pago al servidor.",
      })
    );
    const updatePaymentMethodInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/orden/paymentmethod/update`,
        {
          method: "POST",
          body: JSON.stringify({
            ...paymentMethod,
            UserName: email,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo actualizar el método de pago");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await updatePaymentMethodInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          paymentMethodActions.setPaymentMethod({
            paymentMethod: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Método de pago Actualizado",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          paymentMethodActions.setPaymentMethod({
            paymentMethod: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo actualizar el método de pago",
        })
      );
    }
  };
};

export const ActivePaymentMethod = (id, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Activando método de pago",
        message: "Solicitando activación del método de pago al servidor.",
      })
    );
    const activePaymentMethodInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/orden/paymentmethod/active`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            Id: id,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo activar el método de pago");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await activePaymentMethodInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          paymentMethodActions.setPaymentMethod({
            paymentMethod: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Método de pago Activado",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          paymentMethodActions.setPaymentMethod({
            paymentMethod: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo activar el método de pago",
        })
      );
    }
  };
};

export const InactivePaymentMethod = (id, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Inactivando método de pago",
        message: "Solicitando inactivación del método de pago al servidor.",
      })
    );
    const InactivePaymentMethodInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/orden/paymentmethod/inactive`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            Id: id,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo inactivar el método de pago");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await InactivePaymentMethodInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          paymentMethodActions.setPaymentMethod({
            paymentMethod: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Método de pago Inactivado",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          paymentMethodActions.setPaymentMethod({
            paymentMethod: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo inactivar el método de pago",
        })
      );
    }
  };
};
