import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Checkbox } from "primereact/checkbox";
import { useDispatch, useSelector } from "react-redux";
import { Message } from "primereact/message";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Card } from "primereact/card";
import "primereact/resources/themes/saga-blue/theme.css";

import { discountActions } from "../../store/discount-store/discount-slice";
import { uiActions } from "../../store/ui-slice";
import Loading from "../UI/Loading";

const DiscountForm = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const discount = props.discount;
  const isValid = useSelector((state) => state.discount.discountValidation);
  const notification = useSelector((state) => state.ui.notification);
  const auth = useSelector((state) => state.auth);
  const [type, setType] = useState("CO");
  const conferenceSelectOptions = props.conferences.map((conference) => {
    return { code: conference.id, name: conference.name };
  });

  let startDate = new Date();
  let endDate = new Date();

  if (discount && discount.startDate && discount.endDate) {
    startDate = new Date(discount.startDate);
    endDate = new Date(discount.endDate);
  }

  const types = [];
  if (auth.roles.includes("conferences.showAll")) {
    types.push({
      code: "CO",
      name: "Congreso",
    });
  }

  const getFormErrorMessage = (id) => {
    if (id !== undefined && isValid[id] !== undefined) {
      return !isValid[id].isValid ? (
        <Message
          severity="warn"
          style={{ width: "100%" }}
          text={isValid[id].errorMessage}
        />
      ) : (
        <small className="p-warn">&nbsp;</small>
      );
    }
  };

  const getName = (code) => {
    if (code === "CA") {
      return "Capacitación";
    } else if (code === "CO") {
      return "Congreso";
    }
  };
  const getNameConference = (code) => {
    if (code !== undefined) {
      const value = props.conferences.find((c) => c.id === code);
      return value !== undefined ? value.name : "Seleccione un congreso";
    }
  };

  const onChangeType = (id, value) => {
    if (id === "CA") {
      dispatch(
        discountActions.modifyPropertyValue({ id: "idConference", value: 0 })
      );
    } else {
      dispatch(
        discountActions.modifyPropertyValue({ id: "IdCourse", value: "" })
      );
    }
    setType(value);
  };
  const onChecked = (value, id) => {
    dispatch(discountActions.modifyPropertyValue({ id, value }));
    if (id === "percentage") {
      dispatch(
        discountActions.modifyPropertyValue({ id: "nominal", value: !value })
      );
      dispatch(
        discountActions.modifyPropertyValue({ id: "discountNominal", value: 0 })
      );
    }
    if (id === "nominal") {
      dispatch(
        discountActions.modifyPropertyValue({ id: "percentage", value: !value })
      );
      dispatch(
        discountActions.modifyPropertyValue({
          id: "discountPercentage",
          value: 0,
        })
      );
    }
  };
  const onChangeHandler = (id, value) => {
    dispatch(discountActions.modifyPropertyValue({ id, value }));
    if (value) {
      dispatch(discountActions.setFieldValid({ id, value: true }));
    } else {
      dispatch(discountActions.setFieldValid({ id, value: false }));
    }
  };
  const onChangeHandlerNovalidation = (id, value) => {
    dispatch(discountActions.modifyPropertyValue({ id, value }));
  };
  const onChangeCourseCongreso = (id, value) => {
    if (id === "idConference") {
      dispatch(
        discountActions.modifyPropertyValue({
          id: "conference",
          value: { id: value },
        })
      );
      dispatch(
        discountActions.modifyPropertyValue({ id: "idCourse", value: "" })
      );
    } else {
      dispatch(discountActions.modifyPropertyValue({ id, value }));
      dispatch(
        discountActions.modifyPropertyValue({
          id: "conference",
          value: undefined,
        })
      );
    }
  };
  const onSubmitHandler = (event) => {
    event.preventDefault();
    dispatch(discountActions.setDiscountValidation({ ...discount }));

    if (!isValid.isValid) {
      dispatch(
        uiActions.showNotification({
          status: "warning",
          title: "Advertencia",
          message: "Existen valores invalidos, favor de revisar.",
        })
      );
    } else {
      dispatch(props.actionDiscount(discount, auth.UserInfo.email));
      setTimeout(function () {
        if (notification && notification.status === "success") {
          window.location.href = "/discounts";
        }
      }, 1000);
    }
  };
  return (
    <>
      {!props.creating && discount && discount.id === 0 && (
        <>
          <Loading visible={true} />
        </>
      )}
      {(props.creating || (discount && discount.id && discount.id > 0)) && (
        <Card
          style={{
            backgroundColor: "#142f47",
            marginLeft: "1%",
            marginTop: "-16px",
            boxShadow: "none",
            height: "100%",
            width: "99%",
          }}
        >
          <div className="mb-4 bg-blue-900 p-3">
            <h2 style={{ color: "#fff" }}>
              Descuento:
              <span style={{ color: "#35c4dc", marginLeft: "1%" }}>
                {discount && discount.name && discount.name.toUpperCase()}
              </span>
            </h2>
            <form onSubmit={onSubmitHandler}>
              <div className="formgrid grid">
                <div className="field col-12">
                  <label
                    htmlFor="type"
                    style={{ fontSize: "large", color: "#35c4dc" }}
                  >
                    Descuento para
                  </label>
                  <Dropdown
                    className="w-full text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round outline-none focus:border-primary"
                    value={{
                      code: type,
                      name: getName(type),
                    }}
                    onChange={(e) => onChangeType("type", e.value.code)}
                    options={types}
                    optionLabel="name"
                    placeholder="Descuento para"
                    filter
                    style={{ width: "100%" }}
                  />
                </div>

                {type && (
                  <>
                    {type === "CO" && (
                      <>
                        <div className="field col-12">
                          <label
                            htmlFor="IdConference"
                            style={{ fontSize: "large", color: "#35c4dc" }}
                          >
                            Congreso
                          </label>
                          <Dropdown
                            className="w-full text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round outline-none focus:border-primary"
                            value={{
                              code:
                                discount.conference !== undefined
                                  ? discount.conference.id
                                  : "",
                              name: getNameConference(
                                discount.conference !== undefined
                                  ? discount.conference.id
                                  : "",
                                true
                              ),
                            }}
                            onChange={(e) =>
                              onChangeCourseCongreso(
                                "idConference",
                                e.value.code
                              )
                            }
                            options={conferenceSelectOptions}
                            optionLabel="name"
                            placeholder="Seleccione Congreso"
                            filter
                            style={{ width: "100%" }}
                          />
                          <div>{getFormErrorMessage("IdConference")}</div>
                        </div>
                      </>
                    )}
                    {(discount.idCourse !==
                      "00000000-0000-0000-0000-000000000000" ||
                      discount.conference.id > 0) && (
                      <>
                        <div className="field col-12 md:col-6">
                          <label
                            htmlFor="Name"
                            style={{ fontSize: "large", color: "#35c4dc" }}
                          >
                            Nombre del descuento
                          </label>
                          <InputText
                            id={discount.name}
                            value={discount.name}
                            onChange={(e) =>
                              onChangeHandler("name", e.target.value)
                            }
                            placeholder="Nombre del descuento"
                            style={{ width: "100%" }}
                          />
                          <div>{getFormErrorMessage("name")}</div>
                        </div>
                        <div className="field col-12 md:col-6">
                          <label
                            htmlFor="Description"
                            style={{ fontSize: "large", color: "#35c4dc" }}
                          >
                            Description del descuento
                          </label>
                          <InputText
                            id={discount.description}
                            value={discount.description}
                            onChange={(e) =>
                              onChangeHandler("description", e.target.value)
                            }
                            placeholder="Description del descuento"
                            style={{ width: "100%" }}
                          />
                          <div>{getFormErrorMessage("Description")}</div>
                        </div>
                        <div className="field col-12 md:col-4">
                          <label
                            htmlFor="NumberOfUses"
                            style={{
                              fontSize: "large",
                              color: "#35c4dc",
                              width: "60%",
                            }}
                          >
                            Cantidad de usos
                          </label>
                          <InputNumber
                            id={discount.numberOfUses}
                            value={
                              discount.numberOfUses !== undefined
                                ? discount.numberOfUses
                                : ""
                            }
                            min="1"
                            max="9999"
                            onChange={(e) =>
                              onChangeHandler("numberOfUses", e.value)
                            }
                            placeholder="Cantidad de usos"
                            style={{ width: "100%" }}
                          />
                          <div>{getFormErrorMessage("NumberOfUses")}</div>
                        </div>
                        <div
                          className="field col-12 md:col-2"
                          style={{ marginTop: "2%" }}
                        >
                          <label
                            htmlFor="Percentage"
                            style={{ fontSize: "large", color: "#35c4dc" }}
                          >
                            Descuento Porcentual (%)
                            <Checkbox
                              inputId="Percentage"
                              checked={discount.percentage}
                              onChange={(e) =>
                                onChecked(e.checked, "percentage")
                              }
                              style={{ marginLeft: "5px" }}
                            />
                          </label>
                          &nbsp;
                        </div>
                        <div
                          className="field col-12 md:col-2"
                          style={{ marginTop: "23px" }}
                        >
                          <label
                            htmlFor="DiscountPercentage"
                            style={{
                              fontSize: "large",
                              color: "#35c4dc",
                              marginRight: "10px",
                            }}
                          >
                            % Descuento
                          </label>
                          <br />
                          <InputNumber
                            id={discount.discountPercentage}
                            value={
                              discount.discountPercentage !== undefined
                                ? discount.discountPercentage
                                : ""
                            }
                            min={1}
                            max={100}
                            onChange={(e) =>
                              onChangeHandlerNovalidation(
                                "discountPercentage",
                                e.value
                              )
                            }
                            placeholder="10%"
                            disabled={!discount.percentage}
                            style={{ width: "10%" }}
                          />
                        </div>
                        <div
                          className="field col-12 md:col-2"
                          style={{ marginTop: "2%" }}
                        >
                          <label
                            htmlFor="Nominal"
                            style={{ fontSize: "large", color: "#35c4dc" }}
                          >
                            Descuento Nominal (US$)
                            <Checkbox
                              inputId="nominal"
                              checked={discount.nominal}
                              onChange={(e) => onChecked(e.checked, "nominal")}
                              style={{ marginLeft: "5px" }}
                            />
                          </label>
                        </div>
                        <div className="field col-12 md:col-2">
                          <label
                            htmlFor="DiscountNominal"
                            style={{
                              fontSize: "large",
                              color: "#35c4dc",
                              marginRight: "10px",
                            }}
                          >
                            US$ Descuento
                          </label>
                          <br />
                          <InputNumber
                            id={discount.discountNominal}
                            value={
                              discount.discountNominal !== undefined
                                ? discount.discountNominal
                                : ""
                            }
                            min={0}
                            max={9999}
                            minFractionDigits={2}
                            locale="en-US"
                            onChange={(e) =>
                              onChangeHandlerNovalidation(
                                "discountNominal",
                                e.value
                              )
                            }
                            placeholder="US$ 100.00"
                            disabled={!discount.nominal}
                            style={{ width: "10%" }}
                          />
                        </div>

                        <div className="field col-12 md:col-3">
                          <label
                            htmlFor="StartDate"
                            style={{
                              fontSize: "large",
                              color: "#35c4dc",
                              marginRight: "10px",
                            }}
                          >
                            Fecha de inicio
                          </label>
                          {props.creating && (
                            <Calendar
                              id="StartDate"
                              value={startDate}
                              showTime={false}
                              onChange={(e) => {
                                const date = e.value.toDateString();
                                onChangeHandler("startDate", date);
                              }}
                            />
                          )}
                          {props.creating === undefined && (
                            <Calendar
                              id="StartDate"
                              value={startDate}
                              showTime={false}
                              onChange={(e) => {
                                const date = new Date(e.value).toDateString();
                                onChangeHandler("startDate", date);
                              }}
                            />
                          )}
                          <div>{getFormErrorMessage("startDate")}</div>
                        </div>
                        <div className="field col-12 md:col-3">
                          <label
                            htmlFor="EndDate"
                            style={{
                              fontSize: "large",
                              color: "#35c4dc",
                              marginRight: "10px",
                            }}
                          >
                            Fecha de fin
                          </label>
                          {props.creating === undefined && (
                            <Calendar
                              id="EndDate"
                              value={endDate}
                              showTime={false}
                              onChange={(e) => {
                                const date = new Date(e.value).toDateString();
                                onChangeHandler("endDate", date);
                              }}
                            />
                          )}
                          {props.creating && (
                            <Calendar
                              id="EndDate"
                              value={endDate}
                              showTime={false}
                              onChange={(e) => {
                                const date = e.value.toDateString();
                                onChangeHandler("endDate", date);
                              }}
                            />
                          )}

                          <div>{getFormErrorMessage("endDate")}</div>
                        </div>
                        <div className="field col-12 md:col-3">
                          <label
                            htmlFor="EndDate"
                            style={{
                              fontSize: "large",
                              color: "#35c4dc",
                              marginRight: "10px",
                            }}
                          >
                            Código descuento
                          </label>
                          <InputText
                            id={discount.discountCode}
                            value={discount.discountCode}
                            onChange={(e) =>
                              onChangeHandler("discountCode", e.target.value)
                            }
                            placeholder="Código descuento"
                            style={{ width: "100%" }}
                          />
                          &nbsp;
                        </div>
                        <div
                          className="field col-12 md:col-3"
                          style={{ marginTop: "2%" }}
                        >
                          <label
                            htmlFor="Grupal"
                            style={{ fontSize: "large", color: "#35c4dc" }}
                          >
                            Descuento para grupos
                            <Checkbox
                              inputId="Grupal"
                              checked={discount.grupal}
                              onChange={(e) => onChecked(e.checked, "grupal")}
                              style={{ marginLeft: "5px" }}
                            />
                          </label>
                          &nbsp;
                        </div>
                        <div
                          className="field col-12 md:col-3"
                          style={{ marginTop: "2%" }}
                        >
                          <label
                            htmlFor="automatic"
                            style={{ fontSize: "large", color: "#35c4dc" }}
                          >
                            Descuento Automático
                            <Checkbox
                              inputId="automatic"
                              checked={discount.automatic}
                              onChange={(e) => onChecked(e.checked, "automatic")}
                              style={{ marginLeft: "5px" }}
                            />
                          </label>
                          &nbsp;
                        </div>
                      </>
                    )}
                  </>
                )}
                <div className="field col-12 md:col-12">
                  <Button
                    rounded
                    text
                    raised
                    style={{ backgroundColor: "#fff" }}
                    label="Regresar"
                    icon="pi pi-arrow-left"
                    className="p-button-danger"
                    onClick={() => navigate("/discounts")}
                  />
                  <Button
                    rounded
                    text
                    raised
                    style={{ backgroundColor: "#fff", marginLeft: "1%" }}
                    label="Guardar"
                    className="p-button-success"
                    icon={
                      notification && notification.status === "pending"
                        ? "pi pi-spin pi-spinner"
                        : "pi pi-save"
                    }
                    disabled={notification && notification.status === "pending"}
                  />
                </div>
              </div>
            </form>
          </div>
        </Card>
      )}
    </>
  );
};
export default DiscountForm;
