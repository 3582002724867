import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Message } from "primereact/message";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { ordenStatusActions } from "../../store/ordenstatus-store/ordenstatus-slice";
import { uiActions } from "../../store/ui-slice";
import { useEffect } from "react";

let restartPage = false;

const OrdenStatusForms = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isValid = useSelector((state) => state.ordenStatus.statusValidation);
  const auth = useSelector((state) => state.auth);
  const status = props.status;
  const notification = useSelector((state) => state.ui.notification);

  useEffect(() => {
    if (
      notification &&
      notification.status === "success" &&
      status.id > 0 &&
      restartPage
    ) {
      dispatch(ordenStatusActions.restartState());
      window.location.href = "/orden/statues";
    }
  }, [dispatch, navigate, notification, status.id]);

  const onChangeHandler = (id, value) => {
    dispatch(ordenStatusActions.modifyPropertyValue({ id, value }));
    if (value !== "") {
      dispatch(ordenStatusActions.setFieldValid({ id, value: true }));
    } else {
      dispatch(ordenStatusActions.setFieldValid({ id, value: false }));
    }
  };
  const onSubmitHandler = (event) => {
    event.preventDefault();

    if (status.color === "" || status.color === null) {
      dispatch(ordenStatusActions.setFieldValid({ id: "color", value: false }));
      dispatch(
        uiActions.showNotification({
          status: "warning",
          title: "Advertencia",
          message: "Existen valores invalidos, favor de revisar.",
        })
      );
    } else if (status.description === "" || status.description === null) {
      dispatch(
        ordenStatusActions.setFieldValid({ id: "description", value: false })
      );
      dispatch(
        uiActions.showNotification({
          status: "warning",
          title: "Advertencia",
          message: "Existen valores invalidos, favor de revisar.",
        })
      );
    } else {
      dispatch(props.actionStatus(status, auth.UserInfo.email));
      restartPage = true;
    }
  };
  const getFormErrorMessage = (id) => {
    return !isValid[id].isValid ? (
      <Message severity="warn" text={isValid[id].errorMessage} />
    ) : (
      <small className="p-warn">&nbsp;</small>
    );
  };
  return (
    <>
      <Card
        style={{
          backgroundColor: "#142f47",
          marginLeft: "0%",
          marginTop: "-16px",
          boxShadow: "none",
          height: "100%",
          width: "98%",
        }}
      >
        <form onSubmit={onSubmitHandler}>
          <div className="formgrid grid">
            <div className="field col-12 md:col-4">
              <label
                htmlFor="description"
                style={{ fontSize: "large", color: "#35c4dc" }}
              >
                Nombre Estatus:
              </label>
              <InputText
                id="description"
                value={status.description}
                onChange={(e) => onChangeHandler("description", e.target.value)}
                placeholder=""
                style={{ width: "100%" }}
              />
              <div>{getFormErrorMessage("description")}</div>
            </div>
            <div className="field col-12 md:col-4">
              <label
                htmlFor="color"
                style={{ fontSize: "large", color: "#35c4dc" }}
              >
                Color:
                {status.color !== "" && (
                  <span
                    style={{
                      width: "100%",

                      marginLeft: "5%",
                      backgroundColor: status.color,
                      color: status.color ? status.color : "#142F47",
                    }}
                  >
                    .....................
                  </span>
                )}
              </label>
              <br />
              <InputText
                id="color"
                value={status.color}
                onChange={(e) => onChangeHandler("color", e.target.value)}
                placeholder=""
                style={{ width: "30%" }}
              />

              <div>{getFormErrorMessage("color")}</div>
            </div>
          </div>
          <div className="formgrid grid">
            <Button
              rounded
              text
              raised
              style={{ backgroundColor: "#fff" }}
              label="Regresar"
              icon="pi pi-arrow-left"
              className="p-button-danger"
              onClick={() => {
                navigate("/orden/statues");
              }}
            />
            <Button
              rounded
              text
              raised
              style={{ backgroundColor: "#fff", marginLeft: "1%" }}
              label="Guardar"
              className="p-button-success"
              icon={
                notification && notification.status === "pending"
                  ? "pi pi-spin pi-spinner"
                  : "pi pi-save"
              }
              disabled={notification && notification.status === "pending"}
            />
          </div>
        </form>
      </Card>
    </>
  );
};
export default OrdenStatusForms;
