import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  GetCharge,
  UpdateCharge,
} from "../../../store/charge-store/charge-actions";
import ChargeForms from "../../../components/Forms/ChargeForms";

const ChargeEdit = () => {
  const param = useParams();
  const dispatch = useDispatch();
  const charge = useSelector((state) => state.charge.charge);
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    if (charge.id===undefined || charge.id === 0) {
      dispatch(GetCharge(param.chargeId, auth.UserInfo.email));
    }
  }, [param.chargeId, auth.UserInfo.email, dispatch, charge]);

  return (
    <>
      <ChargeForms actionCharge={UpdateCharge} charge={charge} />
    </>
  );
};
export default ChargeEdit;
