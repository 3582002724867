import { ordenTypeActions } from "./ordentype-slice";
import { uiActions } from "../ui-slice";

export const GetOrdenTypes = (email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Solicitando tipos de Ordenes",
        message: "Solicitando tipos de ordenes al servidor.",
      })
    );
    const GetOrdentypesInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/orden/type/getall`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo obtener los tipos de ordenes");
      }
      const data = await response.json();
      return data;
    };

    try {
      const response = await GetOrdentypesInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          ordenTypeActions.setOrdenTypes({
            ordenTypes: response.data.map((ordenType) => {
              return {
                ...ordenType,
                status: ordenType.status === true ? "Activo" : "Inactivo",
              };
            }),
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Datos Cargados",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          ordenTypeActions.setOrdenTypes({
            ordenTypes: [],
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo obtener los tipos de ordenes",
        })
      );
    }
  };
};

export const GetOrdenType = (id, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Solicitando Tipo de orden",
        message: "Solicitando tipo de orden al servidor.",
      })
    );
    const getOrdenTypeInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/orden/type/getById`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            Id: id,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo obtener el tipo de orden");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await getOrdenTypeInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          ordenTypeActions.setOrdenType({
            ordenType: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Datos Cargados",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          ordenTypeActions.setOrdenType({
            ordenType: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo obtener el estado",
        })
      );
    }
  };
};

export const CreateOrdenType = (ordenType, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Creando Tipo de Orden",
        message: "Solicitando Creación del tipo de orden al servidor.",
      })
    );
    const createOrdenTypeInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/orden/type/create`,
        {
          method: "POST",
          body: JSON.stringify({
            ...ordenType,
            UserName: email,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo crear el tipo de orden");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await createOrdenTypeInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          ordenTypeActions.setOrdenType({
            ordenType: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Tipo de orden Creado",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          ordenTypeActions.setOrdenType({
            ordenType: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo crear el tipo de orden",
        })
      );
    }
  };
};

export const UpadateOrdenType = (ordenType, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Actualizando el tipo de orden",
        message: "Solicitando Actualización del tipo de orden al servidor.",
      })
    );
    const updateOrdenTypeInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/orden/type/update`,
        {
          method: "POST",
          body: JSON.stringify({
            ...ordenType,
            UserName: email,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo actualizar el tipo de orden");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await updateOrdenTypeInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          ordenTypeActions.setOrdenType({
            ordenType: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Tipo de orden Actualizado",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          ordenTypeActions.setOrdenType({
            ordenType: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo actualizar el tipo de orden",
        })
      );
    }
  };
};

export const ActiveOrdenType = (id, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Activando tipo de orden",
        message: "Solicitando activación del tipo de orden al servidor.",
      })
    );
    const activeOrdenTypeInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/orden/type/active`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            Id: id,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo activar el tipo de orden");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await activeOrdenTypeInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          ordenTypeActions.setOrdenType({
            ordenType: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Tipo de orden Activado",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          ordenTypeActions.setOrdenType({
            ordenType: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo activar el tipo de orden",
        })
      );
    }
  };
};

export const InactiveOrdentype = (id, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Inactivando tipo de orden",
        message: "Solicitando inactivación del tipo de orden al servidor.",
      })
    );
    const inactiveOrdentypeInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/orden/type/inactive`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            Id: id,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo inactivar el tipo de orden");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await inactiveOrdentypeInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          ordenTypeActions.setOrdenType({
            ordenType: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Tipo de orden Inactivado",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          ordenTypeActions.setOrdenType({
            ordenType: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo inactivar el estado",
        })
      );
    }
  };
};
