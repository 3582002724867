import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../store/auth-slice";
import { authProvider } from "../store/AuthProvider";
import NavBar from "./NavBar";

const NavBarMenu = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const logoutHandler = () => {
    authProvider.logout();
    dispatch(authActions.logout());
  };
  return (
    <>
      <NavBar
        userName={auth.UserInfo.name}
        logoutHandler={logoutHandler}
        home="/"
      />
    </>
  );
};
export default NavBarMenu;
