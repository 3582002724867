import { createSlice } from "@reduxjs/toolkit";

const initialAuthState = {
  showMenu: false,
  notification: null,
};

const UISlice = createSlice({
  name: "UI",
  initialState: initialAuthState,
  reducers: {
    showHideMenu(state) {
      state.showMenu = !state.showMenu;
    },
    showNotification(state, action) {
      state.notification = {
        status: action.payload.status,
        title: action.payload.title,
        message: action.payload.message,
        timer: 5000,
      };
    },
  },
});
export const uiActions = UISlice.actions;
export default UISlice;
