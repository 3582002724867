import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  courseCategories: [],
  courseCategory: {
    id: 0,
    category: "",
    Status: false,
    creationDate: new Date().toDateString(),
    creationUser: new Date().toDateString(),
  },
  courseCategoryValidation: {
    isValid: true,
    category: {
      isValid: true,
      errorMessage: "El campo categoría no debe estar vacio",
    },
  },
};

const courseCategorySlice = createSlice({
  name: "courseCategory",
  initialState,
  reducers: {
    restartState(state) {
      state.courseCategory = {};
      state.courseCategories = [];
    },
    setcourseCategories(state, actions) {
      state.courseCategories = actions.payload.courseCategories;
      state.courseCategory = {};
    },
    setcourseCategory(state, actions) {
      state.courseCategory = actions.payload.courseCategory;
      state.courseCategories = [];
    },
    modifyPropertyValue(state, actions) {
      state.courseCategory[actions.payload.id] = actions.payload.value;
    },
    setcourseCategoryValidation(state, actions) {
      state.courseCategoryValidation = {
        category: {
          ...state.courseCategoryValidation.category,
          isValid: actions.payload.category !== "",
        },
      };
      state.courseCategoryValidation.isValid =
        state.courseCategoryValidation.description.isValid;
    },
    setFieldValid(state, actions) {
      state.courseCategoryValidation.name.isValid = actions.payload.value;
    },
  },
});

export const courseCategoryActions = courseCategorySlice.actions;
export default courseCategorySlice;
