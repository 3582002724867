import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import CourseTypeForm from "../../../components/Forms/CourseTypeForm";
import {
  GetCourseType,
  UpadateCourseType,
} from "../../../store/coursetype-store/coursetype-actions";

const CourseTypeEdit = () => {
  const param = useParams();
  const dispatch = useDispatch();
  const courseType = useSelector((state) => state.courseType.courseType);
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(GetCourseType(param.courseTypeId, auth.UserInfo.email));
  }, [param.ordenTypeId, auth.UserInfo.email, dispatch, param.courseTypeId]);

  return (
    <>
      <CourseTypeForm
        actionCourseType={UpadateCourseType}
        courseType={courseType}
      />
    </>
  );
};
export default CourseTypeEdit;
