import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  ordenTypes: [],
  ordenType: {
    Id: 0,
    description: "",
    name: "",
    Status: false,
    creationDate: new Date().toDateString(),
    creationUser: new Date().toDateString(),
  },
  ordenTypeValidation: {
    isValid: true,
    name: {
      isValid: true,
      errorMessage: "El campo tipo de orden no debe estar vacio",
    },
  },
};

const ordenTypeSlice = createSlice({
  name: "ordentype",
  initialState,
  reducers: {
    restartState(state) {
      state.ordenType = {};
      state.ordenTypes = [];
    },
    setOrdenTypes(state, actions) {
      state.ordenTypes = actions.payload.ordenTypes;
      state.ordenType = {};
    },
    setOrdenType(state, actions) {
      state.ordenType = actions.payload.ordenType;
      state.ordenTypes = [];
    },
    modifyPropertyValue(state, actions) {
      state.ordenType[actions.payload.id] = actions.payload.value;
    },
    setOrdenTypeValidation(state, actions) {
      state.ordenTypeValidation = {
        name: {
          ...state.ordenTypeValidation.name,
          isValid: actions.payload.name !== "",
        },
      };
      state.ordenTypeValidation.isValid =
        state.ordenTypeValidation.description.isValid;
    },
    setFieldValid(state, actions) {
      state.ordenTypeValidation.name.isValid =
        actions.payload.value;
    },
  },
});

export const ordenTypeActions = ordenTypeSlice.actions;
export default ordenTypeSlice;
