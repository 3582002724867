import { useSelector } from "react-redux";
import { CreateUser } from "../../../store/user-store/user-actions";
import UserForm from "../../../components/Forms/UserForm"

const UserCreate = () => {
  const user = useSelector((state) => state.user.user);

  return (
    <>
      <UserForm actionUser={CreateUser} user={user} isCreation={true} />
    </>
  );
};
export default UserCreate;
