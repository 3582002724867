import { useSelector } from "react-redux";
import { CreateStatus } from "../../../store/ordenstatus-store/ordenstatus-actions"
import OrdenStatusForms from "../../../components/Forms/OrdenStatusForms";

const OrdenStatusCreate = () => {
  const status = useSelector((state) => state.ordenStatus.status);

  return (
    <>
      <OrdenStatusForms actionStatus={CreateStatus} status={status} />
    </>
  );
};
export default OrdenStatusCreate;
