import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dolarDiario: {
    Id: 0,
    tcDolar: 0.0,
  },
  dolarDiarioValidation: {
    isValid: true,
    tcDolar: {
      isValid: true,
      errorMessage: "El campo Valor del dolar no debe estar vacio",
    },
  },
};

const dolarDiarioSlice = createSlice({
  name: "dolardiario",
  initialState,
  reducers: {
    restartState(state) {
      state.dolarDiario = {};
    },
    setDolarDiario(state, actions) {
      state.dolarDiario = actions.payload.dolarDiario;
    },
    modifyPropertyValue(state, actions) {
      state.dolarDiario.tcDolar = actions.payload.value;
    },
    setDolarDiarioValidation(state, actions) {
      state.dolarDiarioValidation = {
        ...state.dolarDiarioValidation.tcDolar,
        isValid: actions.payload.tcDolar > 0.0,
      };
      state.dolarDiarioValidation.isValid =
        state.dolarDiarioValidation.tcDolar.isValid;
    },
    setFieldValid(state, actions) {
      state.dolarDiarioValidation.tcDolar.isValid = actions.payload.value;
    },
  },
});

export const dolarDiarioActions = dolarDiarioSlice.actions;
export default dolarDiarioSlice;
