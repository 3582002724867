import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  users: [],
  teachers: [],
  user: {
    email: "",
    name: "",
    lastName: "",
    identification: "",
    cellPhone: "",
    country: "",
    city: "",
    address: "",
    company: {},
    charge: {},
    department: {},
    status: false,
    token: "",
    fullName: "",
    ordens: [],
    photo: null,
    sign: null,
  },
  userValidation: {
    isValid: true,
    isUserInfoValid: false,
    isUserGeneralInfoValid: false,
    email: {
      isValid: true,
      errorMessage: "El formato del correo es incorrecto.",
    },
    name: {
      isValid: true,
      errorMessage: "El campo name no debe estar vacio",
    },
    lastName: {
      isValid: true,
      errorMessage: "El campo lastName no debe estar vacio",
    },
    identification: {
      isValid: true,
      errorMessage: "El campo Documento de Identidad no debe estar vacio",
    },
    cellPhone: {
      isValid: true,
      errorMessage: "El campo celular no debe estar vacio",
    },
    country: {
      isValid: true,
      errorMessage: "Debe seleccionar un país",
    },
    password: {
      isValid: true,
      errorMessage: "Contraseña incorrecta",
    },
    repassword: {
      isValid: true,
      errorMessage: "Contraseña incorrecta",
    },
  },
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    restartState(state) {
      state.user = {};
      state.users = [];
      state.teachers = [];
    },
    setusers(state, actions) {
      state.users = actions.payload.users;
      state.user =
        actions.payload.user !== undefined ? actions.payload.user : {};
    },
    setTeachers(state, actions) {
      state.teachers = actions.payload.teachers;
      state.user = {};
      state.users = [];
    },
    setuser(state, actions) {
      state.user = actions.payload.user;
      state.users =
        actions.payload.users !== undefined ? actions.payload.users : [];
    },
    setIsConference(state, actions) {
      state.isConference = actions.payload;
    },
    setIsRegister(state, actions) {
      state.isRegister = actions.payload;
    },
    setPhoto(state, actions) {
      state.user.photo = actions.payload;
    },
    setSignature(state, actions) {
      state.user.sign = actions.payload;
    },
    modifyPropertyValue(state, actions) {
      state.user[actions.payload.id] = actions.payload.value;
    },
    
    setIsValid(state, actions) {
      state.userValidation = {
        email: {
          ...state.userValidation.email,
          isValid:
            actions.payload.email !== "" && actions.payload.email.includes("@"),
        },
        name: {
          ...state.userValidation.name,
          isValid: actions.payload.name !== "",
        },
        lastName: {
          ...state.userValidation.lastName,
          isValid: actions.payload.lastName !== "",
        },
        cellPhone: {
          ...state.userValidation.cellPhone,
          isValid:
            actions.payload.cellPhone !== "" &&
            actions.payload.cellPhone.length >= 10,
        },
        country: {
          ...state.userValidation.country,
          isValid: actions.payload.country !== "",
        },
        password: {
          ...state.userValidation.password,
          isValid:
            actions.payload.password !== "" ||
            actions.payload.password !== actions.payload.repassword,
        },
        repassword: {
          ...state.userValidation.repassword,
          isValid:
            actions.payload.repassword !== "" ||
            actions.payload.repassword !== actions.payload.password,
        },
      };
      state.userValidation.isValid =
        state.userValidation.email.isValid &&
        state.userValidation.name.isValid &&
        state.userValidation.lastName.isValid &&
        state.userValidation.cellPhone.isValid &&
        state.userValidation.country.isValid &&
        state.userValidation.identification.isValid;

      state.userValidation.isUserInfoValid =
        state.userValidation.email.isValid &&
        state.userValidation.password.isValid &&
        state.userValidation.repassword.isValid;
    },
    setUserGeneralInfoValid(state, actions) {
      state.userValidation = {
        email: {
          ...state.userValidation.email,
          isValid: true,
        },
        name: {
          ...state.userValidation.name,
          isValid: actions.payload.name !== "",
        },
        lastName: {
          ...state.userValidation.lastName,
          isValid: actions.payload.lastName !== "",
        },
        cellPhone: {
          ...state.userValidation.cellPhone,
          isValid:
            actions.payload.cellPhone !== "" &&
            actions.payload.cellPhone.length >= 10,
        },
        identification: {
          ...state.userValidation.identification,
          isValid: actions.payload.identification !== "",
        },
        country: {
          ...state.userValidation.country,
          isValid: true,
        },

        password: {
          ...state.userValidation.password,
          isValid: true,
        },
        repassword: {
          ...state.userValidation.repassword,
          isValid: true,
        },
      };

      state.userValidation.isUserGeneralInfoValid =
        state.userValidation.name.isValid &&
        state.userValidation.lastName.isValid &&
        state.userValidation.identification.isValid &&
        state.userValidation.cellPhone.isValid;
    },
    setUserInfoValid(state, actions) {
      state.userValidation = {
        email: {
          ...state.userValidation.email,
          isValid: true,
        },
        name: {
          ...state.userValidation.name,
          isValid: true,
        },
        lastName: {
          ...state.userValidation.lastName,
          isValid: true,
        },
        cellPhone: {
          ...state.userValidation.cellPhone,
          isValid: true,
        },
        country: {
          ...state.userValidation.country,
          isValid: true,
        },
        identification: {
          ...state.userValidation.identification,
          isValid: true,
        },
        password: {
          ...state.userValidation.password,
          isValid:
            actions.payload.password !== "" ||
            actions.payload.password !== actions.payload.repassword,
        },
        repassword: {
          ...state.userValidation.repassword,
          isValid:
            actions.payload.repassword !== "" ||
            actions.payload.repassword !== actions.payload.password,
        },
      };

      state.userValidation.isUserInfoValid =
        state.userValidation.email.isValid &&
        state.userValidation.password.isValid &&
        state.userValidation.repassword.isValid;
    },

    setFieldValid(state, actions) {
      state.userValidation[actions.payload.id].isValid = actions.payload.value;
    },
  },
});

export const userActions = userSlice.actions;
export default userSlice;
