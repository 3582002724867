import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sponsorShips: [],
  sponsorShip: {
    id: 0,
    name: "",
    desciption: "",
    contractTemplateURl: "",
    status: true,
    sLASing: 0,
    sLAPayment: 0,
    prince: 0,
    maxAide: 0,
    maxParticipant: 0,
    file: null,
  },
  sponsorShipValidation: {
    isValid: true,
    Name: {
      isValid: true,
      errorMessage: "El campo nombre no debe estar vacio",
    },
    Desciption: {
      isValid: true,
      errorMessage: "El campo descripción no debe estar vacio",
    },
    SLASing: {
      isValid: true,
      errorMessage: "El SLA de firma debe ser mayor que 0",
    },
    SLAPayment: {
      isValid: true,
      errorMessage: "El SLA de pago debe ser mayor que 0",
    },
    Prince: {
      isValid: true,
      errorMessage: "El precio debe ser mayor que 0",
    },
    MaxAide: {
      isValid: true,
      errorMessage: "El máximo de ayudantes debe ser mayor que 0",
    },
    MaxParticipant: {
      isValid: true,
      errorMessage: "El máximo de participantes debe ser mayor que 0",
    },
  },
};

const sponsorShipSlice = createSlice({
  name: "sponsorShip",
  initialState,
  reducers: {
    restartState(state) {
      state.sponsorShip = {};
      state.sponsorShips = [];
    },
    setsponsorships(state, actions) {
      state.sponsorShips = actions.payload;
      state.sponsorShip = {};
    },
    setsponsorship(state, actions) {
      state.sponsorShip = actions.payload.sponsorship;
    },
    modifyPropertyValue(state, actions) {
      state.sponsorShip[actions.payload.id] = actions.payload.value;
    },
    setsponsorshipValidation(state, actions) {
      state.sponsorShipValidation = {
        Name: {
          ...state.sponsorShipValidation.Name,
          isValid: actions.payload.CompanyName !== "",
        },
        Desciption: {
          ...state.sponsorShipValidation.Desciption,
          isValid: actions.payload.Desciption !== "",
        },
        SLASing: {
          ...state.sponsorShipValidation.SLASing,
          isValid: actions.payload.SLASing > 0,
        },
        SLAPayment: {
          ...state.sponsorShipValidation.SLAPayment,
          isValid: actions.payload.SLAPayment > 0,
        },
        Prince: {
          ...state.sponsorShipValidation.Prince,
          isValid: actions.payload.Prince > 0,
        },
        MaxAide: {
          ...state.sponsorShipValidation.MaxAide,
          isValid: actions.payload.MaxAide > 0,
        },
        MaxParticipant: {
          ...state.sponsorShipValidation.MaxParticipant,
          isValid: actions.payload.MaxParticipant > 0,
        },
      };

      state.sponsorShipValidation.isValid =
        state.sponsorShipValidation.Name.isValid &&
        state.sponsorShipValidation.Desciption.isValid &&
        state.sponsorShipValidation.SLASing.isValid &&
        state.sponsorShipValidation.SLAPayment.isValid &&
        state.sponsorShipValidation.Prince.isValid &&
        state.sponsorShipValidation.MaxAide.isValid &&
        state.sponsorShipValidation.MaxParticipant.isValid;
        
    },
    setsponsorshipValid(state, actions) {
      state.sponsorShipValidation.sponsorshipCode.isValid =
        actions.payload.isValid;
      state.sponsorShipValidation.sponsorshipCode.errorMessage =
        actions.payload.errorMessage;
    },
    setFieldValid(state, actions) {
      state.sponsorShipValidation[actions.payload.id].isValid =
        actions.payload.value;
    },
  },
});

export const sponsorshipActions = sponsorShipSlice.actions;
export default sponsorShipSlice;
