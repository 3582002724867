import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { GetSponsorshipById } from "../../../store/sponsor-store/sponsor-actions";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { InputNumber } from "primereact/inputnumber";
import ConferenceDetailComponent from "../../../components/conferences/ConferenceDetailComponent";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { CreateOrder } from "../../../store/orden-store/orden-actions";

const SponsorPaymentLink = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const auth = useSelector((state) => state.auth);
  const sponsorship = useSelector((state) => state.sponsorship.sponsorship);
  const [conference, setConference] = useState(undefined);
  const [amount, setAmount] = useState(0);
  const [comprobanteSelected, setComprobanteSelected] = useState({
    comprobante: false,
    rnc: "",
  });
  const [billInfo, setBillInfo] = useState({ BillName: "", BillAddress: "" });

  useEffect(() => {
    if (sponsorship && (sponsorship.Id <= 0 || sponsorship.Id === undefined)) {
      dispatch(GetSponsorshipById(params.sponsorshipId, auth.UserInfo.email));
    }
  }, [auth.UserInfo.email, dispatch, params.sponsorshipId, sponsorship]);

  useEffect(() => {
    if (sponsorship && sponsorship.Conference !== undefined) {
      setConference({
        Id: sponsorship.Conference.id,
        Name: sponsorship.Conference.name,
        Description: sponsorship.Conference.description,
        Location: sponsorship.Conference.location,
        Price: sponsorship.Conference.price,
        SoldOut: sponsorship.Conference.soldOut,
        Status: sponsorship.Conference.status,
        StartDate: sponsorship.Conference.startDate,
        EndDate: sponsorship.Conference.endDate,
        ImageUrl: sponsorship.Conference.imageUrl,
        LoginUrl: sponsorship.Conference.loginUrl,
        creationDate: sponsorship.Conference.creationDate,
        creationUser: sponsorship.Conference.creationUser,
        updateDate: sponsorship.Conference.updateDate,
        updateUser: sponsorship.Conference.updateUser,
      });
    }
  }, [auth.UserInfo.email, dispatch, params.conferenceId, sponsorship]);

  const onSubmitHandler = (event) => {
    event.preventDefault();
    let payOrder;
    payOrder = {
      IdCongreso: conference.Id,
      IdPaymethod: 1,
      Monto: amount,
      DiscountCode: "",
      Category: "PaymentLinkSponsorship",
      Type: "individual",
      Comprobante: comprobanteSelected.comprobante,
      RNC: comprobanteSelected.rnc,
      ExtraActivity: false,
      Agree: false,
      BillName: billInfo.BillName,
      BillAddress: billInfo.BillAddress,
    };
    if (auth.UserInfo) {
      dispatch(CreateOrder(payOrder, sponsorship.Email, undefined, true));
      setTimeout(function () {
        window.location.href = `/admin/sponsorships`;
      }, 1500);
    }
  };

  return (
    <>
      <div
        className="card flex justify-content-center "
        style={{ marginTop: "15px", backgroundColor: "#142f47" }}
      >
        <Card
          style={{
            backgroundColor: "#fff",
            marginTop: "8px",
            boxShadow: "none",
            width: "90%",
            borderRadius: "10px",
          }}
        >
          <form onSubmit={onSubmitHandler}>
            <div className="formgrid grid pr-2 pl-2">
              <div className="field col-12 md:col-6">
                <label
                  htmlFor="DiscountCode"
                  style={{
                    color: "#000",
                    fontSize: "large",
                    fontWeight: "bolder",
                  }}
                >
                  Patrocinador: &nbsp; &nbsp;
                </label>
                <label
                  style={{
                    color: "#000",
                    fontSize: "large",
                    textDecoration: "underline",
                  }}
                >
                  {sponsorship && sponsorship.CompanyName}
                </label>
              </div>
              {conference && conference.Id !== undefined && (
                <ConferenceDetailComponent
                  conference={conference}
                  showInfo={false}
                />
              )}

              <div className="field col-12 md:col-4 mt-3">
                <label
                  style={{
                    color: "#000",
                    fontSize: "large",
                    fontWeight: "bolder",
                  }}
                >
                  Monto a pagar:
                </label>
                <InputNumber
                  style={{
                    width: "70%",
                    borderRadius: "6px",
                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                  }}
                  value={amount}
                  onValueChange={(e) => setAmount(e.value)}
                  minFractionDigits={2}
                  maxFractionDigits={5}
                />
              </div>
              <div className="field col-12 md:col-4">
                <label
                  htmlFor="Comprobante"
                  style={{ color: "#000", fontSize: "large" }}
                >
                  Comprobante: &nbsp; &nbsp;
                  <Checkbox
                    inputId="comprobanteSelected"
                    checked={comprobanteSelected.comprobante}
                    onChange={(e) => {
                      const value = e.checked;
                      const newComprobanteSelected = {
                        ...comprobanteSelected,
                        comprobante: value,
                      };
                      setComprobanteSelected(newComprobanteSelected);
                    }}
                  />
                </label>
              </div>
              {comprobanteSelected.comprobante && (
                <div className="field col-12 md:col-4">
                  <label
                    htmlFor="Name"
                    style={{ color: "#000", fontSize: "large" }}
                  >
                    RNC:
                  </label>
                  &nbsp; &nbsp;
                  <InputText
                    id="RNC"
                    value={comprobanteSelected.rnc}
                    onChange={(e) =>
                      setComprobanteSelected({
                        ...comprobanteSelected,
                        rnc: e.target.value,
                      })
                    }
                    placeholder=""
                    style={{
                      width: "70%",
                      borderRadius: "6px",
                      boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                    }}
                  />
                </div>
              )}
              <div className="field col-12 md:col-6">
                <label
                  htmlFor="DiscountCode"
                  style={{ color: "#000", fontSize: "large" }}
                >
                  Nombre de facturación: &nbsp; &nbsp;
                  <InputText
                    id="billName"
                    value={billInfo.BillName}
                    onChange={(e) => {
                      const info = { ...billInfo, BillName: e.target.value };
                      setBillInfo(info);
                    }}
                    placeholder="Nombre de facturación"
                    style={{
                      width: "100%",
                      borderRadius: "6px",
                      boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                    }}
                  />
                </label>
              </div>
              <div className="field col-12 md:col-6">
                <label
                  htmlFor="billAddress"
                  style={{ color: "#000", fontSize: "large" }}
                >
                  Dirección de facturación: &nbsp; &nbsp;
                  <InputText
                    id="billAddress"
                    value={billInfo.BillAddress}
                    onChange={(e) => {
                      const info = { ...billInfo, BillAddress: e.target.value };
                      setBillInfo(info);
                    }}
                    placeholder="Dirección de facturación"
                    style={{
                      width: "100%",
                      borderRadius: "6px",
                      boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                    }}
                  />
                </label>
              </div>
              <div className="field col-12 md:col-6">
                <Button
                  label="Regresar"
                  className="p-button-danger"
                  icon="pi pi-arrow-left"
                  onClick={() => {
                    navigate("/admin/sponsorships");
                  }}
                  style={{
                    width: "40%",
                    border: "solid 0.5px #2196F3",
                    borderRadius: "7px",
                  }}
                />
              </div>
              <div className="field col-12 md:col-6">
                <Button
                  label="Enviar"
                  className="p-button-success"
                  icon="pi pi-send"
                  style={{
                    width: "40%",
                    border: "solid 0.5px #2196F3",
                    borderRadius: "7px",
                  }}
                />
              </div>
            </div>
          </form>
        </Card>
      </div>
    </>
  );
};
export default SponsorPaymentLink;
