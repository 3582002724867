import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import Loading from "../../../components/UI/Loading";
import {
  ActiveCourseCategory,
  GetCourseCategory,
  InactiveCourseCategory,
} from "../../../store/coursecategory-store/coursecategory-actions";

const CourseCategoryDetail = () => {
  const param = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const courseCategory = useSelector(
    (state) => state.courseCategory.courseCategory
  );
  const auth = useSelector((state) => state.auth);
  const notification = useSelector((state) => state.ui.notification);

  useEffect(() => {
    dispatch(GetCourseCategory(param.courseCategoryId, auth.UserInfo.email));
  }, [param.courseCategoryId, auth, dispatch]);

  const activeHandler = () => {
    dispatch(ActiveCourseCategory(param.courseCategoryId, auth.UserInfo.email));
  };
  const inactiveHandler = () => {
    dispatch(
      InactiveCourseCategory(param.courseCategoryId, auth.UserInfo.email)
    );
  };
  let activeSuspend;
  if (
    auth.roles !== undefined &&
    auth.roles.includes("coursecategory.active")
  ) {
    activeSuspend = (
      <Button
        rounded
        text
        raised
        style={{ backgroundColor: "#fff", marginLeft: "1%" }}
        label="Activar"
        className="p-button-success"
        onClick={activeHandler}
        icon={
          notification && notification.status === "pending"
            ? "pi pi-spin pi-spinner"
            : "pi pi-check"
        }
        disabled={notification && notification.status === "pending"}
      />
    );
  }
  if (courseCategory.status) {
    if (
      auth.roles !== undefined &&
      auth.roles.includes("coursecategory.inactive")
    ) {
      activeSuspend = (
        <Button
          rounded
          text
          raised
          style={{ backgroundColor: "#fff", marginLeft: "1%" }}
          label="Suspender"
          className="p-button-warning"
          onClick={inactiveHandler}
          icon={
            notification && notification.status === "pending"
              ? "pi pi-spin pi-spinner"
              : "pi pi-times"
          }
          disabled={notification && notification.status === "pending"}
        />
      );
    }
  }

  return (
    <>
      {(courseCategory.id === undefined || courseCategory.id === 0) && (
       <Loading visible={true} />
      )}
      {courseCategory.id !== undefined && courseCategory.id > 0 && (
        <Card
          style={{
            backgroundColor: "#183462",
            marginLeft: "1%",
            marginTop: "8px",
            boxShadow: "none",
            width: "99%",
            border: "solid #326fd1",
          }}
        >
          <div
            className="user-info text-center"
            style={{ borderBottom: "solid #fff", paddingBottom: "4px" }}
          >
            <h2
              id="header"
              className="mb-2"
              style={{ color: "#fff", textDecoration: "underline" }}
            >
              {courseCategory.category.toUpperCase()}
            </h2>
            <span
              className="badge bg-label-secondary"
              style={{
                fontSize: "large",
                color: "lightskyblue",
                fontWeight: "bold",
              }}
            >
              Categoría
            </span>
          </div>
          <div className="formgrid grid">
            <div className="field col-12 md:col-12 mt-3">
              <Button
                rounded
                text
                raised
                style={{ backgroundColor: "#fff" }}
                label="Regresar"
                icon="pi pi-arrow-left"
                className="p-button-danger"
                onClick={() => {
                  navigate("/course/types");
                }}
              />
              {auth.roles !== undefined &&
                auth.roles.includes("coursecategory.edit") && (
                  <Button
                    rounded
                    text
                    raised
                    style={{ backgroundColor: "#fff", marginLeft: "1%" }}
                    label="Editar"
                    className="p-button-primary"
                    icon="pi pi-file-edit"
                    onClick={() => {
                      navigate(`/course/category/edit/${param.courseCategoryId}`);
                    }}
                  />
                )}
              {activeSuspend}
            </div>
          </div>
        </Card>
      )}
    </>
  );
};
export default CourseCategoryDetail;
