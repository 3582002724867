import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { GetOrdensById } from "../../../store/orden-store/orden-actions";
import { useEffect } from "react";
import PaymentDetail from "../../../components/Payments/PaymentDetail";
import UserAccordion from "../../../components/Capacitaciones/UserAccordion";
import { userActions } from "../../../store/user-store/user-slice";
import { Button } from "primereact/button";

const OrdenDetail = () => {
  const orden = useSelector((state) => state.orden.orden);
  // const param = useParams();
  // const dispatch = useDispatch();
  const navigate = useNavigate();

  // const auth = useSelector((state) => state.auth);
  // const usuario = useSelector((state) => state.usuario.usuario);
  // const isConference = useSelector((state) => state.usuario.isConference);

  // useEffect(() => {
  //   dispatch(GetOrdensById(param.ordenId, auth.UserInfo.email, true, usuario));
  // }, [param.ordenId, auth, dispatch, usuario, orden.ordenId]);
  // useEffect(() => {
  //   if (param.isConference === "1") {
  //     dispatch(userActions.setIsConference(true));
  //   } else {
  //     dispatch(userActions.setIsConference(false));
  //   }
  // }, [dispatch, param.isConference]);

  // const buttons = (
  //   <>
  //     {orden &&
  //       orden.ordenId > 0 &&
  //       orden.conferences &&
  //       orden.conferences.id > 0 &&
  //       (orden.estado.estadoId === 2 ||
  //         orden.estado.estadoId === 17 ||
  //         orden.estado.estadoId === 18) && (
  //         <>
  //           <Button
  //             label="Ver Gafetes"
  //             className="p-button-dander"
  //             style={{
  //               width: "25%",
  //               border: "solid 0.5px #2196F3",
  //               borderRadius: "7px",
  //             }}
  //             onClick={() => {
  //               navigate(`/admin/userBadges/${orden.ordenId}`);
  //             }}
  //           />

  //         </>
  //       )}
  //   </>
  // );
  return (
    <>
      <PaymentDetail
        orden={orden}
        comprobanteSelected={orden.comprobante}
        RNC={orden.rnc}
        extraActivitySelected={orden.extraActivity}
        status={orden.status}
        discountCode={orden.discount.discountCode}
        // userinfo={auth.UserInfo}
        // buttons={buttons}
      />

      {/* <UserAccordion usuarios={orden.} />
      <div className="formgrid grid">
        <div className="field col-12 md:col-1"></div>
        <div className="field col-12 md:col-4">
          <Button
            label="Atrás"
            className="p-button-secondary"
            style={{
              width: "100%",
              border: "solid 0.5px #2196F3",
              borderRadius: "7px",
            }}
            onClick={() => {
              navigate("/admin/conferences");
            }}
          />
        </div>
      </div> */}
    </>
  );
};
export default OrdenDetail;
