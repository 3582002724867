import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import ABADatatable from "../../../components/ABADatatable";
import { GetCourseTypes } from "../../../store/coursetype-store/coursetype-actions";
import { courseTypeActions } from "../../../store/coursetype-store/coursetype-slice";

const CourseTypesHomePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const courseType = useSelector((state) => state.courseType);
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    if (auth.roles !== undefined && auth.roles.includes("coursetype.showAll")) {
      dispatch(GetCourseTypes(auth.UserInfo.email, true, true));
    }
  }, [dispatch, auth]);

  const viewDetailHanlder = (val) => {
    navigate(`/course/type/detail/${val.id}`);
  };
  const editHanlder = (val) => {
    navigate(`/course/type/edit/${val.id}`);
  };
  const createHanlder = () => {
    dispatch(
      courseTypeActions.setcourseType({
        courseType: {
          Descripcion: "",
        },
      })
    );
    navigate(`/course/type/create`);
  };
  const backHanlder = () => {
    navigate(`/`);
  };
  let actions = [];
  const buttons = (rowData) => {
    let buttons = [];
    const buttonEdit = (
      <Button
        label="Editar"
        icon="pi pi-file-edit"
        className="p-button-rounded p-button-success p-button-text"
        onClick={() => {
          editHanlder(rowData);
        }}
      />
    );
    const buttonView = (
      <Button
        label="Ver"
        icon="pi pi-info-circle"
        className="p-button-rounded p-button-text"
        onClick={() => {
          viewDetailHanlder(rowData);
        }}
      />
    );
    if (auth.roles !== undefined && auth.roles.includes("coursetype.edit")) {
      buttons.push(buttonEdit);
    }
    if (auth.roles !== undefined && auth.roles.includes("coursetype.view")) {
      buttons.push(buttonView);
    }
    return buttons;
  };
  actions.push(buttons);

  const columns = [
    {
      field: "name",
      header: "Tipo de course",
      filterMatchMode: "contains",
    },
    {
      field: "status",
      header: "Estado",
      filterMatchMode: "contains",
    },
  ];
  return (
    <>
      <ABADatatable
        title="Tipo de cursos"
        values={courseType.courseTypes}
        rows={5}
        rowsPerPageOptions={[5, 10, 20]}
        Columns={columns}
        actions={actions}
        createButton={{
          label: "Crear tipo de curso",
          onClick: createHanlder,
          permission: "coursetype.create",
        }}
        backButton={{
          label: "Regresar",
          onClick: backHanlder,
          permission: "goBack",
        }}
      />
    </>
  );
};
export default CourseTypesHomePage;
