import { useState } from "react";
import DialogModal from "../DialogDialogModal";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../store/user-store/user-slice";
import { AddUser } from "../../store/orden-store/orden-actions";

const AddUserComponent = (props) => {
  const auth = useSelector((state) => state.auth);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [refresh, setRefresh] = useState(false);

  const addUserHandler = () => {
    let users = [...props.orden.users];
    if (users.find((x) => x.id === user.user.id) === undefined) {
      if (
        user.user !== undefined &&
        (user.user.name === undefined || user.user.name === "")
      ) {
        dispatch(userActions.setFieldValid({ id: "name", value: false }));
        return;
      }
      if (
        user.user !== undefined &&
        (user.user.lastName === undefined || user.user.lastName === "")
      ) {
        dispatch(userActions.setFieldValid({ id: "lastName", value: false }));
        return;
      }
      if (
        user.user !== undefined &&
        (user.user.email === undefined || user.user.email === "")
      ) {
        dispatch(userActions.setFieldValid({ id: "email", value: false }));
        return;
      }
      if (
        user.user !== undefined &&
        (user.user.cellPhone === undefined || user.user.cellPhone === "")
      ) {
        dispatch(userActions.setFieldValid({ id: "cellPhone", value: false }));
        return;
      }
      if (
        user.user !== undefined &&
        (user.user.country === undefined || user.user.country === "")
      ) {
        dispatch(userActions.setFieldValid({ id: "country", value: false }));
        return;
      }

      dispatch(AddUser(props.ordenId, user.user, auth.UserInfo.email));
      dispatch(userActions.restartState());
      setTimeout(() => {
        window.location.reload();
      }, 2500);
      setRefresh(true);
    }
  };
  return (
    <>
      <DialogModal
        email={auth.UserInfo.email}
        header="Agregar participante"
        visible={props.visibleUser}
        setVisible={props.setVisibleUser}
        addUserHandler={addUserHandler}
        refresh={refresh}
      />
    </>
  );
};
export default AddUserComponent;
