import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  statues: [],
  status: {
    id: 0,
    color: "",
    description: "",
    status: false,
  },
  statusValidation: {
    isValid: true,
    color: {
      isValid: true,
      errorMessage: "Debe Seleccionar un color",
    },
    description: {
      isValid: true,
      errorMessage: "El campo descripción no debe estar vacio",
    },
  },
};

const ordenStatusSlice = createSlice({
  name: "ordenStatus",
  initialState,
  reducers: {
    restartState(state) {
      state.status = {};
      state.statues = [];
    },
    setStatues(state, actions) {
      state.statues = actions.payload.statues;
      state.status = {};
    },
    setStatus(state, actions) {
      state.status = actions.payload.status;
      state.statues = [];
    },
    modifyPropertyValue(state, actions) {
      state.status[actions.payload.id] = actions.payload.value;
    },
    setStatusValidation(state, actions) {
      state.chargeValidation = {
        color: {
          ...state.chargeValidation.color,
          isValid: actions.payload.color !== "",
        },
        description: {
          ...state.chargeValidation.description,
          isValid: actions.payload.description !== "",
        },
      };
      state.statusValidation.isValid =
        state.statusValidation.description.isValid;
    },
    setFieldValid(state, actions) {
      state.statusValidation[actions.payload.id].isValid = actions.payload.value;
    },
  },
});

export const ordenStatusActions = ordenStatusSlice.actions;
export default ordenStatusSlice;
