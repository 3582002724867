import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import ABADatatable from "../../../components/ABADatatable";
import { GetCompanies } from "../../../store/company-store/company-actions";
import { companyActions } from "../../../store/company-store/company-slice";

const CompanyHomePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const companies = useSelector((state) => state.company.companies);
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    if (auth.roles !== undefined && auth.roles.includes("company.showAll")) {
      dispatch(GetCompanies(auth.UserInfo.email, true, true));
    }
  }, [dispatch, auth]);

  const viewDetailHanlder = (val) => {
    navigate(`/company/detail/${val.id}`);
  };
  const editHanlder = (val) => {
    navigate(`/company/edit/${val.id}`);
  };
  const createHanlder = () => {
    dispatch(
      companyActions.setCompany({
        company: {
          id: 0,
          name: "",
          rnc: "",
          status: false,
        },
      })
    );
    navigate(`/company/create`);
  };
  const backHanlder = () => {
    navigate(`/`);
  };
  let actions = [];
  const buttons = (rowData) => {
    let buttons = [];
    const buttonEdit = (
      <Button
        label="Editar"
        icon="pi pi-file-edit"
        className="p-button-rounded p-button-success p-button-text"
        onClick={() => {
          editHanlder(rowData);
        }}
      />
    );
    const buttonView = (
      <Button
        label="Ver"
        icon="pi pi-info-circle"
        className="p-button-rounded p-button-text"
        onClick={() => {
          viewDetailHanlder(rowData);
        }}
      />
    );
    if (auth.roles !== undefined && auth.roles.includes("company.edit")) {
      buttons.push(buttonEdit);
    }
    if (auth.roles !== undefined && auth.roles.includes("company.view")) {
      buttons.push(buttonView);
    }
    return buttons;
  };
  actions.push(buttons);

  const columns = [
    {
      field: "name",
      header: "Nombre",
      filterMatchMode: "contains",
    },

    {
      field: "rnc",
      header: "RNC",
      filterMatchMode: "contains",
    },
    {
      field: "status",
      header: "Estado",
      filterMatchMode: "contains",
    },
  ];
  return (
    <>
      <ABADatatable
        width="20%"
        title="Empresas"
        values={companies}
        rows={5}
        rowsPerPageOptions={[5, 10, 20]}
        Columns={columns}
        actions={actions}
        createButton={{
          label: "Crear empresa",
          onClick: createHanlder,
          permission: "company.create",
        }}
        backButton={{
          label: "Regresar",
          onClick: backHanlder,
          permission: "goBack",
        }}
      />
    </>
  );
};
export default CompanyHomePage;
