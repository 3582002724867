import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import YesNoModal from "../YesNoModal";
import { InputText } from "primereact/inputtext";
import { InputSwitch } from "primereact/inputswitch";
import Loading from "../UI/Loading";
import { UpdateTaxbill } from "../../store/orden-store/orden-actions";

const TaxBillComponent = (props) => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [refresh, setRefresh] = useState(false);

  const ChangeTaxBillHandler = () => {
    dispatch(
      UpdateTaxbill(
        props.updateOrden.id,
        props.updateOrden.taxBill,
        props.updateOrden.rnc,
        auth.UserInfo.email
      )
    );
    setTimeout(function () {
      window.location.reload();
    }, 1500);
    setRefresh(true);
  };

  const headerTaxBill = (
    <>
      <span style={{ fontSize: "x-large" }}>
        Comprobante orden:{" "}
        <span style={{ color: "#eec137" }}>{props.updateOrden.id}</span>
      </span>
    </>
  );
  const contentTaxBill = (
    <>
      {refresh === true && (
        <>
          <Loading visible={refresh} />
        </>
      )}
      {refresh === false && (
        <div className="formgrid grid">
          <div className="field col-12 md:col-6">
            <label
              htmlFor="taxBill"
              style={{ fontSize: "large", color: "#35c4dc" }}
            >
              Requiere RNC: <br />
              <InputSwitch
                checked={props.updateOrden.taxBill}
                style={{ width: "45%", height: "20px", marginTop: "5%" }}
                onChange={(e) =>
                  props.setUpdateOrden({
                    ...props.updateOrden,
                    taxBill: e.value,
                  })
                }
              />
            </label>
          </div>
          <div className="field col-12 md:col-6">
            <label
              htmlFor="rnc"
              style={{ fontSize: "large", color: "#35c4dc" }}
            >
              RNC:
            </label>

            <InputText
              id="rnc"
              value={
                props.updateOrden.rnc !== undefined &&
                props.updateOrden.rnc !== ""
                  ? props.updateOrden.rnc
                  : ""
              }
              onChange={(e) =>
                props.setUpdateOrden({
                  ...props.updateOrden,
                  rnc: e.target.value,
                })
              }
              style={{
                width: "100%",
                borderRadius: "6px",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                height: "50px",
              }}
            />
          </div>
        </div>
      )}
    </>
  );
  return (
    <>
      <YesNoModal
        header={headerTaxBill}
        content={contentTaxBill}
        visible={props.visibleTaxBill}
        setVisible={props.setVisibleTaxBill}
        yesHandler={ChangeTaxBillHandler}
        showButton={
          props.updateOrden.taxBill !== undefined &&
          props.updateOrden.rnc !== undefined &&
          props.updateOrden.rnc !== ""
        }
        yesButtonLabel="Guardar"
        icon="pi pi-save"
      />
    </>
  );
};
export default TaxBillComponent;
