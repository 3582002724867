import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  GetDolarDiario,
  UpadateDolarDiario,
} from "../../../store/dolar-store/dolardiario-actions";
import DoldarDiarioForm from "../../../components/Forms/DoldarDiarioForm";

const DolarDiarioEdit = () => {
  const param = useParams();
  const dispatch = useDispatch();
  const dolarDiario = useSelector((state) => state.dolarDiario.dolarDiario);
  const auth = useSelector((state) => state.auth);
  useEffect(() => {
    dispatch(GetDolarDiario(param.dolarDiarioId, auth.UserInfo.email));
  }, [param.dolarDiarioId, auth.UserInfo.email, dispatch]);

  return (
    <>
      <DoldarDiarioForm
        actionDolarDiario={UpadateDolarDiario}
        dolarDiario={dolarDiario}
      />
    </>
  );
};
export default DolarDiarioEdit;
