import { useEffect, } from "react";
import { GetUsers } from "../../../store/user-store/user-actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ABADatatable from "../../../components/ABADatatable";
import { userActions } from "../../../store/user-store/user-slice";
import { Button } from "primereact/button";
import { arregloPaises } from "../../../components/List";

const UserHomePage = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();
  

  useEffect(() => {
    if (auth.roles !== undefined && auth.roles.includes("user.showAll")) {
      dispatch(GetUsers(auth.UserInfo.email, true));
    }
 
  }, [dispatch, auth]);

  const paises = arregloPaises.map((pais) => ({
    code: pais.nombre,
    name: pais.nombre,
    flag: pais.abreviatura,
  }));

  const viewDetailHanlder = (val) => {
    navigate(`/user/detail/${val.id}`);
  };
  const editHanlder = (val) => {
    navigate(`/user/edit/${val.id}`);
  };
  const backHanlder = () => {
    navigate(`/`);
  };
  const createHanlder = () => {
    dispatch(
      userActions.setuser({
        user: {
          email: "",
          name: "",
          lastName: "",
          identification: "",
          cellphone: "",
          country: "",
          city: "",
          address: "",
          company: "",
          charge: {},
          department: {},
          status: false,
          token: "",
          fullName: "",
        },
      })
    );
    navigate(`/user/create`);
  };
  let actions = [];
  const buttons = (rowData) => {
    let buttons = [];
    const buttonEdit = (
      <Button
        label="Editar"
        icon="pi pi-file-edit"
        className="p-button-rounded p-button-success p-button-text"
        onClick={() => {
          editHanlder(rowData);
        }}
      />
    );
    const buttonView = (
      <Button
        label="Ver"
        icon="pi pi-info-circle"
        className="p-button-rounded p-button-text"
        onClick={() => {
          viewDetailHanlder(rowData);
        }}
      />
    );
    if (auth.roles !== undefined && auth.roles.includes("user.edit")) {
      buttons.push(buttonEdit);
    }
    if (auth.roles !== undefined && auth.roles.includes("user.view")) {
      buttons.push(buttonView);
    }
    return buttons;
  };
  actions.push(buttons);

  const columns = [
    {
      field: "identification",
      header: "Documento",
      filterMatchMode: "contains",
    },

    {
      field: "name",
      header: "Nombres",
      filterMatchMode: "contains",
    },
    {
      field: "lastName",
      header: "Apellidos",
      filterMatchMode: "contains",
    },
    {
      field: "email",
      header: "Correo",
      filterMatchMode: "contains",
    },
    {
      field: "country",
      header: "País",
      filterMatchMode: "contains",
      body: (rowData) => {
        const pais = paises.find((pais) => pais.name === rowData.country);
        if (pais === undefined) {
          return <div></div>;
        }
        return (
          <div className="flex align-items-center" style={{ width: "100px" }}>
            <img
              alt={pais.name}
              src={`https://flagcdn.com/48x36/${pais.flag.toLowerCase()}.png`}
              style={{ width: "18px" }}
            />
            <div style={{ marginLeft: "5%" }}>{pais.name}</div>
          </div>
        );
      },
    },
  ];
  return (
    <>
      <ABADatatable
        width="20%"
        title="Usuarios"
        values={user.users}
        rows={5}
        rowsPerPageOptions={[5, 10, 20]}
        Columns={columns}
        actions={actions}
        createButton={{
          label: "Crear Usuario",
          onClick: createHanlder,
          permission: "user.create",
        }}
        backButton={{
          label: "Regresar",
          onClick: backHanlder,
          permission: "goBack",
        }}
      />
    </>
  );
};
export default UserHomePage;
