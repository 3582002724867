import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sponsors: [],
  sponsor: {
    Id: 0,
    CompanyName: "",
    WebUrl: "",
    Country: "",
    City: "",
    Phone: "",
    Email: "",
    ConferenceId: 0,
    Description: "",
    Multinational: false,
    MaxUsers: 1,
    Usuario: {
      id: 0,
      email: "",
      nombres: "",
      apellidos: "",
      telefonoCelular: "",
      pais: "",
      ciudad: "",
      direccion: "",
      entidadLabora: "",
      cargo: "",
      departamento: "",
      identificacion: "",
      password: "",
    },
  },
  sponsorValidation: {
    isValid: true,
    CompanyName: {
      isValid: true,
      errorMessage: "El campo nombre de la empresa no debe estar vacio",
    },
    ConferenceId: {
      isValid: true,
      errorMessage: "Debe seleccionar un congreso",
    },
    Email: {
      isValid: true,
      errorMessage: "El correo de contacto no debe estar vacio",
    },
  },
};

const sponsorSlice = createSlice({
  name: "sponsor",
  initialState,
  reducers: {
    restartState(state) {
      state.sponsor = {};
      state.sponsors = [];
    },
    setsponsorships(state, actions) {
      state.sponsors = actions.payload;
      state.sponsor = {};
    },
    setsponsorship(state, actions) {
      state.sponsor = actions.payload.sponsorship;
    },
    modifyPropertyValue(state, actions) {
      state.sponsor[actions.payload.id] = actions.payload.value;
    },
    setsponsorshipValidation(state, actions) {
      state.sponsorValidation = {
        CompanyName: {
          ...state.sponsorValidation.CompanyName,
          isValid: actions.payload.CompanyName !== "",
        },
        ConferenceId: {
          ...state.sponsorValidation.ConferenceId,
          isValid: actions.payload.ConferenceId > 0,
        },
        Email: {
          ...state.sponsorValidation.Email,
          isValid: actions.payload.Email !== "",
        },
      };

      state.sponsorValidation.isValid =
        state.sponsorValidation.CompanyName.isValid &&
        state.sponsorValidation.ConferenceId.isValid &&
        state.sponsorValidation.Email.isValid;
    },
    setsponsorshipValid(state, actions) {
      state.sponsorValidation.sponsorshipCode.isValid =
        actions.payload.isValid;
      state.sponsorValidation.sponsorshipCode.errorMessage =
        actions.payload.errorMessage;
    },
    setFieldValid(state, actions) {
      state.sponsorValidation[actions.payload.id].isValid =
        actions.payload.value;
    },
  },
});

export const sponsorActions = sponsorSlice.actions;
export default sponsorSlice;
