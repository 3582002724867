import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { GetUserById } from "../../../store/user-store/user-actions";
import UserDetailInfo from "./UserDetailInfo";

const UserDetail = () => {
  const param = useParams();

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    if (user && user.id === undefined) {
      dispatch(GetUserById(param.userId, auth.UserInfo.email));
    }
  }, [param.userId, auth, dispatch, user]);

  return <UserDetailInfo user={user} showPasswordChange={true} />;
};
export default UserDetail;
