import { useEffect, useState } from "react";
import YesNoModal from "../YesNoModal";
import Loading from "../UI/Loading";
import { Dropdown } from "primereact/dropdown";
import { useDispatch, useSelector } from "react-redux";
import { GetPaymentMethods } from "../../store/paymentmethod-store/paymentmethod-actions";
import { GetStatues } from "../../store/ordenstatus-store/ordenstatus-actions";
import { InputText } from "primereact/inputtext";
import { InputSwitch } from "primereact/inputswitch";
import { Button } from "primereact/button";
import DialogModal from "../DialogDialogModal";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { userActions } from "../../store/user-store/user-slice";
import { useParams } from "react-router-dom";
import { uiActions } from "../../store/ui-slice";
import { CreateOrder } from "../../store/orden-store/orden-actions";

const NewOrderComponent = (props) => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const params = useParams();
  const paymentMethodList = useSelector(
    (state) => state.paymentMethod.paymentMethods
  );
  const statusList = useSelector((state) => state.ordenStatus.statues);
  const discountValidation = useSelector(
    (state) => state.discount.discountValidation.discountCode.isValid
  );
  const user = useSelector((state) => state.user);
  const conference = useSelector((state) => state.conference.conference);

  const [orden, setOrden] = useState({
    conference: { id: parseInt(params.conferenceId) },
    ordenType: { id: 2 },
    paymentMethod: { id: 1 },
    status: { id: 1 },
    amount: 0,
    discountCode: "",
    Category: "",
    taxBill: false,
    RNC: "",
    users: [],
  });
  const [refresh, setRefresh] = useState(false);
  const [visibleUser, setVisibleUser] = useState(false);
  const [selectedPaymentMethod, setPaymentMethod] = useState({
    code: 0,
    name: "",
  });
  const [selectedTipo, setSelecedtipo] = useState({ code: "", name: "" });
  const [discountCode, setDiscountCode] = useState("");
  const [selectedStatusMethod, setSelectedStatus] = useState({
    code: 0,
    name: "",
    color: "",
  });
  const [updateOrden] = useState({
    id: 0,
    paymentMethod: { id: 0, name: "" },
    status: {
      id: 0,
      description: "",
      color: "",
    },
  });
  const tipo = [
    { code: "individual", name: "Individual" },
    { code: "grupal", name: "Grupal" },
  ];

  useEffect(() => {
    if (conference.id > 0 && paymentMethodList.length === 0) {
      dispatch(GetPaymentMethods(auth.UserInfo.email));
    }
  }, [auth.UserInfo.email, conference.id, dispatch, paymentMethodList]);
  useEffect(() => {
    if (conference.id > 0 && statusList.length === 0) {
      dispatch(GetStatues(auth.UserInfo.email));
    }
  }, [auth.UserInfo.email, conference.id, dispatch, statusList]);

  let statues = [];
  if (statusList.length > 0) {
    if (updateOrden.status.id === 9) {
      statues = statusList.filter(
        (status) => status.status === "Activo" && status.id === 1
      );
      statues = statues.map((status) => {
        return {
          code: status.id,
          name: status.description,
          color: status.color,
        };
      });
    } else {
      statues = statusList.filter((status) => status.status === "Activo");
      statues = statues.map((status) => {
        return {
          code: status.id,
          name: status.description,
          color: status.color,
        };
      });
    }
  }

  let paymentMethods = [];
  if (paymentMethodList.length > 0) {
    paymentMethods = paymentMethodList.filter(
      (paymentMethod) => paymentMethod.status === "Activo"
    );
    paymentMethods = paymentMethods.map((paymentMethod) => {
      return {
        code: paymentMethod.id,
        name: paymentMethod.name,
      };
    });
  }

  const headerNewOrder = (
    <>
      <span style={{ fontSize: "x-large" }}>
        Crear Nueva Orden
        <span style={{ color: "#eec137" }}>{}</span>
      </span>
    </>
  );
  const contentNewOrder = (
    <>
      {refresh === true && (
        <>
          <Loading visible={refresh} />
        </>
      )}
      {refresh === false && (
        <div className="formgrid grid">
          <div className="field col-12 md:col-4">
            <label
              htmlFor="paymentMethod"
              style={{ fontSize: "large", color: "#35c4dc" }}
            >
              Método de pago:
            </label>
            <Dropdown
              id="paymentMethod"
              value={selectedPaymentMethod}
              onChange={(e) => onChangeOrdenHandler("paymentMethod", e.value)}
              options={paymentMethods}
              optionLabel="name"
              filter
              style={{
                width: "100%",
                borderRadius: "6px",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                height: "50px",
              }}
            />
          </div>
          <div className="field col-12 md:col-4">
            <label
              htmlFor="status"
              style={{ fontSize: "large", color: "#35c4dc" }}
            >
              Estado:
            </label>
            <Dropdown
              id="status"
              value={selectedStatusMethod}
              onChange={(e) => onChangeOrdenHandler("status", e.value)}
              options={statues}
              optionLabel="name"
              filter
              style={{
                width: "100%",
                borderRadius: "6px",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                height: "50px",
              }}
            />
          </div>
          <div className="field col-12 md:col-4">
            <label
              htmlFor="password"
              style={{ fontSize: "large", color: "#35c4dc" }}
            >
              Categoría:
            </label>
            <Dropdown
              id="type"
              value={selectedTipo}
              onChange={(e) => onChangeOrdenHandler("type", e.value)}
              options={tipo}
              optionLabel="name"
              filter
              style={{
                width: "100%",
                borderRadius: "6px",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                height: "50px",
              }}
            />
          </div>

          {orden.users.length >= 1 && (
            <>
              <div className="field col-12 md:col-4">
                <label
                  htmlFor="discountCode"
                  style={{ fontSize: "large", color: "#35c4dc" }}
                >
                  Código de descuento:{" "}
                  {orden.discountCode !== "" && discountValidation && (
                    <i style={{ color: "green" }} className="pi pi-check"></i>
                  )}
                  {orden.discountCode !== "" && !discountValidation && (
                    <i style={{ color: "red" }} className="pi pi-times"></i>
                  )}
                  {orden.discountCode === "" && (
                    <i
                      style={{ color: "#35c4dc" }}
                      className="pi pi-question-circle"
                    ></i>
                  )}
                </label>

                <InputText
                  id="discountCode"
                  value={
                    orden.discountCode !== undefined &&
                    orden.discountCode !== ""
                      ? orden.discountCode
                      : discountCode
                  }
                  onChange={(e) =>
                    onChangeOrdenHandler("discountCode", e.target.value)
                  }
                  style={{
                    width: "100%",
                    borderRadius: "6px",
                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                    height: "50px",
                    borderColor:
                      orden.discountCode !== "" &&
                      discountValidation !== undefined &&
                      discountValidation === true
                        ? "green"
                        : orden.discountCode !== "" &&
                          discountValidation !== undefined &&
                          discountValidation === false
                        ? "red"
                        : "",
                  }}
                />
              </div>
              <div className="field col-12 md:col-2">
                <label
                  htmlFor="taxBill"
                  style={{ fontSize: "large", color: "#35c4dc" }}
                >
                  Requiere RNC: <br />
                  <InputSwitch
                    checked={orden.taxBill}
                    style={{ width: "45%", height: "20px", marginTop: "5%" }}
                    onChange={(e) => onChangeOrdenHandler("taxBill", e.value)}
                  />
                </label>
              </div>

              {orden.taxBill && (
                <div className="field col-12 md:col-4">
                  <label
                    htmlFor="rnc"
                    style={{ fontSize: "large", color: "#35c4dc" }}
                  >
                    RNC:
                  </label>

                  <InputText
                    id="rnc"
                    value={
                      orden.RNC !== undefined && orden.RNC !== ""
                        ? orden.RNC
                        : ""
                    }
                    onChange={(e) =>
                      onChangeOrdenHandler("rnc", e.target.value)
                    }
                    style={{
                      width: "100%",
                      borderRadius: "6px",
                      boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                      height: "50px",
                    }}
                  />
                </div>
              )}
            </>
          )}
          {orden.Category !== "" && (
            <>
              <div className="field col-12 md:col-12">
                <Button
                  rounded
                  text
                  raised
                  label={`Agregar Usuario`}
                  className="p-button-primary"
                  icon="pi pi-user-plus"
                  onClick={() => {
                    setVisibleUser(true);
                  }}
                  disabled={
                    orden.Category === "individual" && orden.users.length === 1
                      ? true
                      : false
                  }
                />
              </div>
              <div className="field col-12 md:6">
                <DataTable
                  value={orden.users}
                  tableStyle={{ minWidth: "50rem" }}
                >
                  <Column field="name" header="Nombre"></Column>
                  <Column field="lastName" header="Apellido"></Column>
                  <Column field="email" header="Email"></Column>
                  <Column
                    body={(rowData) => {
                      return (
                        <Button
                          label="Eliminar"
                          icon="pi pi-times"
                          rounded
                          outlined
                          severity="danger"
                          onClick={() => {
                            removeUserHandler(rowData);
                          }}
                        />
                      );
                    }}
                    style={{ textAlign: "center", width: "6rem" }}
                  />
                </DataTable>
              </div>
            </>
          )}
          <div className="field col-12 md:col-12">
            <Button
              rounded
              text
              raised
              label="Guardar"
              className="p-button-success"
              icon="pi pi-save"
              onClick={() => {
                saveHandler();
              }}
            />
          </div>
        </div>
      )}
    </>
  );

  const onChangeOrdenHandler = (id, value) => {
    let newOrden = { ...orden };
    if (id === "paymentMethod") {
      newOrden.paymentMethod.id = value.code;
      setPaymentMethod(value);
    }
    if (id === "status") {
      newOrden.status.id = value.code;
      setSelectedStatus(value);
    }
    if (id === "type") {
      newOrden.Category = value.code;
      setSelecedtipo(value);
    }
    if (id === "discountCode") {
      newOrden.discountCode = value;
      setDiscountCode(value);
    }
    if (id === "users") {
      newOrden.users.push(value);
    }
    if (id === "taxBill") {
      newOrden.taxBill = value;
    }
    if (id === "rnc") {
      newOrden.RNC = value;
    }
    newOrden.amount = conference.price;
    newOrden.conference = conference;
    setOrden(newOrden);
  };
  const addUserHandler = () => {
    if (
      user.user !== undefined &&
      (user.user.name === undefined || user.user.name === "")
    ) {
      dispatch(userActions.setFieldValid({ id: "name", value: false }));
      return;
    }
    if (
      user.user !== undefined &&
      (user.user.lastName === undefined || user.user.lastName === "")
    ) {
      dispatch(userActions.setFieldValid({ id: "lastName", value: false }));
      return;
    }
    if (
      user.user !== undefined &&
      (user.user.email === undefined || user.user.email === "")
    ) {
      dispatch(userActions.setFieldValid({ id: "email", value: false }));
      return;
    }
    if (
      user.user !== undefined &&
      (user.user.cellPhone === undefined || user.user.cellPhone === "")
    ) {
      dispatch(userActions.setFieldValid({ id: "cellPhone", value: false }));
      return;
    }
    if (
      user.user !== undefined &&
      (user.user.country === undefined || user.user.country === "")
    ) {
      dispatch(userActions.setFieldValid({ id: "country", value: false }));
      return;
    }
    onChangeOrdenHandler("users", user.user);
    dispatch(userActions.restartState());
    setVisibleUser(false);
  };
  const removeUserHandler = (user) => {
    let users = [...orden.users];
    const index = users.findIndex((x) => x.id === user.id);
    users.splice(index, 1);
    setOrden({ ...orden, users: users });
  };
  const saveHandler = () => {
    if (orden.users.length === 0) {
      dispatch(
        uiActions.showNotification({
          status: "warning",
          title: "Error",
          message: "Debe agregar al menos un usuario a la orden",
        })
      );
      return;
    }
    if (orden.paymentMethod.id === 0) {
      dispatch(
        uiActions.showNotification({
          status: "warning",
          title: "Error",
          message: "Debe seleccionar un método de pago",
        })
      );
      return;
    }
    if (orden.status.id === 0) {
      dispatch(
        uiActions.showNotification({
          status: "warning",
          title: "Error",
          message: "Debe seleccionar un estado",
        })
      );
      return;
    }
    if (orden.Category === "") {
      dispatch(
        uiActions.showNotification({
          status: "warning",
          title: "Error",
          message: "Debe seleccionar un tipo de orden",
        })
      );
      return;
    }

    dispatch(CreateOrder(orden, auth.UserInfo.email, orden.users));
    setTimeout(function () {
      window.location.reload();
    }, 1500);
    setRefresh(true);
  };
  return (
    <>
      <YesNoModal
        className="p-"
        header={headerNewOrder}
        content={contentNewOrder}
        visible={props.visibleNewOrden}
        setVisible={props.setVisibleNewOrden}
        showButton={false}
      />
      <DialogModal
        email={auth.UserInfo.email}
        header="Usuario Solicitante"
        visible={visibleUser}
        setVisible={setVisibleUser}
        addUserHandler={addUserHandler}
        refresh={refresh}
      />
    </>
  );
};
export default NewOrderComponent;
