import { useDispatch, useSelector } from "react-redux";
import DialogModal from "../DialogDialogModal";
import { useState } from "react";
import { Button } from "primereact/button";
import { userActions } from "../../store/user-store/user-slice";
import ABADatatable from "../ABADatatable";
import { Image } from "primereact/image";
import { courseActions } from "../../store/courses-store/course-slice";

const CourseStep3 = (props) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const user = useSelector((state) => state.user);

  const [visibleUser, setVisibleUser] = useState(false);

  const addUserHandler = () => {
    let teachers = [...props.course.courseTeachers];
    const isInList = teachers.find((t) => t.email === user.user.email);

    if (isInList) {
      const index = teachers.findIndex((t) => t.email === user.email);
      teachers.splice(index, 1);
    }
    teachers.push(user.user);
    dispatch(courseActions.setCourseTeacher({ courseTeachers: teachers }));
    if (teachers.length > 0) {
      dispatch(
        courseActions.setFieldValid({ id: "courseTeachers", value: true })
      );
    } else {
      dispatch(
        courseActions.setFieldValid({ id: "courseTeachers", value: false })
      );
    }
    setVisibleUser(false);
  };

  const removeUserHandler = (val) => {
    let teachers = [...props.course.courseTeachers];
    const index = teachers.findIndex((t) => t.email === val.email);

    if (index !== -1) {
      teachers.splice(index, 1);
      dispatch(courseActions.setCourseTeacher({ courseTeachers: teachers }));
    }
    if (teachers.length > 0) {
      dispatch(
        courseActions.setFieldValid({ id: "courseTeachers", value: true })
      );
    } else {
      dispatch(
        courseActions.setFieldValid({ id: "courseTeachers", value: false })
      );
    }
  };
  const editHanlder = (val) => {
    dispatch(
      userActions.setuser({
        user: val,
      })
    );
    setVisibleUser(true);
  };

  const columns = [
    {
      field: "name",
      header: "Nombres",
      filterMatchMode: "contains",
    },
    {
      field: "lastName",
      header: "Apellidos",
      filterMatchMode: "contains",
    },
    {
      field: "email",
      header: "Correo",
      filterMatchMode: "contains",
    },
    {
      header: "Empresa",
      body: (rowData) => {
        return rowData.company.name;
      },
    },

    {
      field: "photo",
      header: "Foto",
      body: (rowData) => {
        
        return (
          <>
            {rowData.photo !== undefined &&
              rowData.photo !== null &&
              rowData.photo.objectURL !== undefined &&
              rowData.photo.objectURL !== null && (
                <Image
                  src={rowData.photo.objectURL}
                  indicatorIcon={<i className="pi pi-search"></i>}
                  alt="Image"
                  preview
                  width="80"
                  height="60"
                />
              )}
            {(rowData.photo === undefined ||
              rowData.photo === null ||
              rowData.photo.objectURL === undefined ||
              rowData.photo.objectURL === null) &&
              rowData.photoURL !== undefined &&
              rowData.photoURL !== "" && (
                <Image
                  src={rowData.photoURL}
                  indicatorIcon={<i className="pi pi-search"></i>}
                  alt="Image"
                  preview
                  width="80"
                  height="60"
                />
              )}
          </>
        );
      },
    },
    {
      field: "signature",
      header: "firma",
      body: (rowData) => {
        return (
          <>
            {rowData.signature !== undefined &&
              rowData.signature !== null &&
              rowData.signature.objectURL !== undefined &&
              rowData.signature.objectURL !== null && (
                <Image
                  src={rowData.signature.objectURL}
                  indicatorIcon={<i className="pi pi-search"></i>}
                  alt="Image"
                  preview
                  width="80"
                  height="60"
                />
              )}
            {(rowData.sign === undefined ||
              rowData.sign === null ||
              rowData.sign.objectURL === undefined ||
              rowData.sign.objectURL === null) &&
              rowData.signatureURL !== undefined &&
              rowData.signatureURL !== "" && (
                <Image
                  src={rowData.signatureURL}
                  indicatorIcon={<i className="pi pi-search"></i>}
                  alt="Image"
                  preview
                  width="80"
                  height="60"
                />
              )}
          </>
        );
      },
    },
  ];

  let actions = [];
  const buttons = (rowData) => {
    let buttons = [];
    const buttonEdit = (
      <Button
        label="Quitar"
        icon="pi pi-trash"
        text
        rounded
        severity="danger"
        onClick={() => {
          removeUserHandler(rowData);
        }}
      />
    );
    const buttonView = (
      <Button
        label="Editar"
        icon="pi pi-file-edit"
        text
        rounded
        security="success"
        onClick={() => {
          editHanlder(rowData);
        }}
      />
    );
    buttons.push(buttonView);
    buttons.push(buttonEdit);
    return buttons;
  };
  actions.push(buttons);

  return (
    <>
      <div
        className="mb-4 bg-blue-900 p-3 mt-4"
        style={{
          margin: "0px",
          marginBottom: "20px",
          width: "100%",
          height: "670px",
          overflowY: "auto",
        }}
      >
        <h2
          style={{
            color: "#fff",
            textDecoration: "underline",
            textAlign: "center",
            fontSize: "xx-large",
          }}
        >
          Faciliatdores
        </h2>
        <div className="formgrid grid">
          <div className="field col-12 md:col-6">
            <Button
              label="Agregar Facilitador"
              className="p-button-prymary"
              rounded
              outlined
              onClick={() => {
                setVisibleUser(true);
              }}
              icon={"pi pi-plus"}
              style={{
                width: "40%",
                borderRadius: "6px",
                backgroundColor: "#ffffff",
              }}
            />
          </div>
          <div className="field col-12 md:col-12">
            <div>{props.getFormErrorMessage("courseTeachers")}</div>
            <ABADatatable
              title=""
              values={props.course.courseTeachers}
              width="20%"
              rows={5}
              rowsPerPageOptions={[5, 10, 20]}
              Columns={columns}
              actions={actions}
              createButton={{}}
              backButton={{}}
            />
          </div>
        </div>
      </div>

      <DialogModal
        email={auth.UserInfo.email}
        header="Agregar participante"
        visible={visibleUser}
        setVisible={setVisibleUser}
        teacher={true}
        addUserHandler={addUserHandler}
        refresh={false}
      />
    </>
  );
};
export default CourseStep3;
