import { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Tag } from "primereact/tag";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";
import { GetUsersByCourseId } from "../../../store/user-store/user-actions";
import ABADatatable from "../../../components/ABADatatable";
import {
  CreateOrder,
  DownloadPaymentTransfer,
  UpdateOrdenStatus,
} from "../../../store/orden-store/orden-actions";
import {
  GetCourse,
  ActiveCourse,
  InactiveCourse,
} from "../../../store/courses-store/course-actions";
import YesNoModal from "../../../components/YesNoModal";
import DialogModal from "../../../components/DialogDialogModal";
import CourseDetailComponent from "../../../components/Capacitaciones/CourseDetailComponent";

const CourseDetail = () => {
  const param = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const course = useSelector((state) => state.course.course);
  const auth = useSelector((state) => state.auth);
  const usuarios = useSelector((state) => state.usuario.usuarios);
  const usuario = useSelector((state) => state.usuario.usuario);
  const notification = useSelector((state) => state.ui.notification);
  const [visible, setVisible] = useState(false);
  const [visibleUser, setVisibleUser] = useState(false);
  const [ordenId, setOrdenId] = useState(0);
  const [amountSelect, setAmount] = useState(0);
  const [estado, setEstado] = useState("");

  useEffect(() => {
    if (course && course.id === undefined) {
      dispatch(GetCourse(param.courseId, auth.UserInfo.email));
    } else {
      if (usuarios.length === 0) {
        dispatch(
          GetUsersByCourseId(
            param.courseId,
            auth.UserInfo.email,
            true,
            usuario
          )
        );
      }
    }
  }, [param.courseId, auth, dispatch, usuario, course, usuarios.length]);
  var options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  let startDate = new Date().toLocaleDateString("en-US", options);
  if (course.startDate !== "" || course.startDate !== undefined) {
    startDate = new Date(course.startDate+"T01:00:00").toLocaleDateString("en-US", options);
  }
  let endDate = new Date().toLocaleDateString("en-US", options);
  if (course.endDate !== "" || course.endDate !== undefined) {
    endDate = new Date(course.endDate+"T01:00:00").toLocaleDateString("en-US", options);
  }

  const activeHandler = () => {
    dispatch(ActiveCourse(param.courseId, auth.UserInfo.email));
  };
  const inactiveHandler = () => {
    dispatch(InactiveCourse(param.courseId, auth.UserInfo.email));
  };

  let statusCourse = (
    <>
      <span className="fw-bold me-2" style={{ color: "#fff" }}>
        Estatus:&nbsp;
      </span>
      <Tag
        value="Inactivo"
        severity={"warning"}
        style={{ fontSize: "medium" }}
      ></Tag>
    </>
  );
  let activeSuspend;
  if (auth.roles !== undefined && auth.roles.includes("course.active")) {
    activeSuspend = (
      <Button
        label="Activar"
        className="p-button-success"
        style={{ marginLeft: "1%" }}
        onClick={activeHandler}
        icon={
          notification && notification.conference === "pending"
            ? "pi pi-spin pi-spinner"
            : "pi pi-check"
        }
        disabled={notification && notification.conference === "pending"}
      />
    );
  }

  if (course.status) {
    statusCourse = (
      <>
        <span className="fw-bold me-2" style={{ color: "#fff" }}>
          Estatus:
        </span>
        <Tag
          value="Activo"
          severity={"success"}
          style={{ fontSize: "medium" }}
        ></Tag>
      </>
    );
    if (auth.roles !== undefined && auth.roles.includes("course.inactive")) {
      activeSuspend = (
        <Button
          label="Suspender"
          className="p-button-warning"
          style={{ marginLeft: "1%" }}
          onClick={inactiveHandler}
          icon={
            notification && notification.conference === "pending"
              ? "pi pi-spin pi-spinner"
              : "pi pi-ban"
          }
          disabled={notification && notification.conference === "pending"}
        />
      );
    }
  }
  const fullNameTemplate = (usuario) => {
    return (
      <span>
        {usuario.nombres} {usuario.apellidos}
      </span>
    );
  };
  const discountTypeTemplate = (usuario) => {
    return (
      <>
        {usuario.discount.percentage &&
          usuario.discount.discountPercentage + "%"}
        {usuario.discount.nominal && "US$" + usuario.discount.discountNominal}
      </>
    );
  };
  const discountTemplate = (usuario) => {
    let discountAmount = 0;
    if (usuario.discount.percentage) {
      discountAmount =
        (usuario.discount.discountPercentage / 100) * course.price;
    } else if (usuario.discount.nominal) {
      discountAmount = usuario.discount.discountNominal;
    }

    return <>US$ {discountAmount}.00</>;
  };
  const amountWithTemplate = (usuario) => {
    let discountAmount = 0;
    if (usuario.discount.percentage) {
      discountAmount =
        (usuario.discount.discountPercentage / 100) * course.price;
    } else if (usuario.discount.nominal) {
      discountAmount = usuario.discount.discountNominal;
    }
    const amount = course.price - discountAmount;
    return <>US$ {amount}.00</>;
  };
  const amountWithOutTemplate = () => {
    return <>US$ {course.price}.00</>;
  };
  const statusOrden = (usuario) => {
    let severity = "warning";
    if (usuario.estado.estadoId === 2) {
      severity = "success";
    }
    return <Tag value={usuario.estado.descripcion} severity={severity}></Tag>;
  };

  const downloadFile = (rowData) => {
    dispatch(DownloadPaymentTransfer(rowData.ordenId, auth.UserInfo.email));
  };
  const yesHandler = (update) => {
    if (update) {
      const response = {
        OrderNumber: `${ordenId}`,
        Amount: `US$ ${amountSelect}.00`,
        AuthorizationCode: `OK${ordenId}`,
        ResponseCode: `OK${ordenId}`,
        IsoCode: "",
        ResponseMessage: "APROBADA",
        ErrorDescription: "",
        RRN: "",
        CustomOrderId: `${ordenId}`,
        CardNumber: "",
        AzulOrderId: `${ordenId}`,
      };
      dispatch(UpdateOrdenStatus(response, auth.UserInfo.email));
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
    setVisible(false);
  };

  const addUserHandler = () => {
    if (usuario !== undefined && usuario.email !== "") {
      const payOrder = {
        IdCongreso: 0,
        IdCourse: course.id,
        IdPaymethod: 1,
        Monto: course.price,
        DiscountCode: "",
        Category: "capacitaciones",
        Type: "individual",
        Comprobante: false,
        RNC: "",
      };
      const users = [usuario];
      dispatch(CreateOrder(payOrder, usuario.email, users));
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };

  let actions = [];
  const buttons = (rowData) => {
    let buttons = [];
    const buttonDownload = (
      <Button
        label="Comprobante"
        icon="pi pi-cloud-download"
        className="p-button-rounded p-button-success p-button-text"
        onClick={() => {
          downloadFile(rowData);
        }}
      />
    );
    const buttonIsPaid = (
      <Button
        label="Pago"
        icon="pi pi-check-square"
        className="p-button-rounded  p-button-text"
        onClick={() => {
          setVisible(true);
          setOrdenId(rowData.ordenId);
          setEstado(rowData.estado.descripcion);
          let discountAmount = 0;
          if (rowData.discount.percentage) {
            discountAmount =
              (rowData.discount.discountPercentage / 100) * course.price;
          } else if (rowData.discount.nominal) {
            discountAmount = rowData.discount.discountNominal;
          }
          const amount = course.price - discountAmount;
          setAmount(amount);
        }}
      />
    );

    if (
      auth.roles !== undefined &&
      auth.roles.includes("orden.cansee.voucher") &&
      rowData.estado.estadoId === 10
    ) {
      buttons.push(buttonDownload);
    }
    if (
      auth.roles !== undefined &&
      auth.roles.includes("orden.canPaid") &&
      rowData.estado.estadoId !== 2
    ) {
      buttons.push(buttonIsPaid);
    }

    return buttons;
  };
  actions.push(buttons);
  const OrdeIdTemplate = (usuario) => {
    return (
      <Link to={`/admin/detailorden/${usuario.ordenId}/0`}>
        {usuario.ordenId}
      </Link>
    );
  };

  const columnsUsuarios = [
    {
      header: "Orden Id",
      filter: false,
      sortable: false,
      body: (usuario) => {
        return OrdeIdTemplate(usuario);
      },
    },
    {
      header: "Participante",
      filter: false,
      sortable: false,
      body: (usuario) => {
        return fullNameTemplate(usuario);
      },
    },
    {
      header: "Descuento",
      filter: false,
      sortable: false,
      body: (usuario) => {
        return discountTypeTemplate(usuario);
      },
    },
    {
      header: "Monto descuento",
      filter: false,
      sortable: false,
      body: (usuario) => {
        return discountTemplate(usuario);
      },
    },
    {
      header: "Monto sin descuento",
      filter: false,
      sortable: false,
      body: (usuario) => {
        return amountWithOutTemplate(usuario);
      },
    },
    {
      header: "Monto con descuento",
      filter: false,
      sortable: false,
      body: (usuario) => {
        return amountWithTemplate(usuario);
      },
    },
    {
      header: "Estatus usuario",
      filter: false,
      sortable: false,
      body: (usuario) => {
        return statusOrden(usuario);
      },
    },
  ];

  const headerDialog = (
    <>
      <span style={{ fontSize: "x-large" }}>
        Desea marcar como pagado la orden:{" "}
        <span style={{ color: "#eec137" }}>{ordenId}</span>
      </span>
    </>
  );
  const contentDialog = (
    <>
      <span>
        La orden <span style={{ color: "#f59e0b" }}>{ordenId}</span> se
        encuentra en
        <span style={{ color: "#f59e0b" }}> {estado} </span>
        desea marcarla como <span style={{ color: "#4cd07d" }}>pagado</span>?
      </span>
    </>
  );
  const buttonsDetail = (
    <>
      {auth.roles !== undefined && auth.roles.includes("course.edit") && (
        <Button
          label=" Particiapante"
          icon="pi pi-user-plus"
          className="p-button-secondary"
          style={{ marginLeft: "1%" }}
          onClick={() => {
            setVisibleUser(true);
          }}
        />
      )}
      {auth.roles !== undefined && auth.roles.includes("course.edit") && (
        <Button
          label="Editar"
          icon="pi pi-file-edit"
          className="p-button-primary"
          style={{ marginLeft: "1%" }}
          onClick={() => {
            navigate(`/admin/editcourse/${param.courseId}`);
          }}
        />
      )}
      {activeSuspend}
      <Button
        label="Regresar"
        icon="pi pi-arrow-left"
        className="p-button-danger"
        style={{ marginLeft: "1%" }}
        onClick={() => {
          navigate("/admin/courses");
        }}
      />
    </>
  );

  return (
    <>
      <Card
        style={{
          backgroundColor: "#fff",
          marginLeft: "1%",
          marginTop: "8px",
          boxShadow: "none",
          width: "99%",
          border: "solid #326fd1",
        }}
      >
        <CourseDetailComponent
          course={course}
          buttons={buttonsDetail}
          startDate={startDate}
          endDate={endDate}
          statusCourse={statusCourse}
          showtopic={true}
        />
      </Card>

      <DialogModal
        email={auth.UserInfo.email}
        header="Agregar participante"
        visible={visibleUser}
        setVisible={setVisibleUser}
        usuario={usuario}
        usuarios={usuarios}
        addUserHandler={addUserHandler}
      />
      {usuarios.length > 0 && (
        <>
          <ABADatatable
            title="Participantes"
            values={usuarios}
            rows={5}
            rowsPerPageOptions={[5, 10, 20]}
            Columns={columnsUsuarios}
            width="0px"
            actions={actions}
            createButton={{}}
          />
          <YesNoModal
            className="p-"
            header={headerDialog}
            visible={visible}
            setVisible={setVisible}
            yesHandler={yesHandler}
            content={contentDialog}
          />
        </>
      )}
    </>
  );
};
export default CourseDetail;
