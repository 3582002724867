import { Card } from "primereact/card";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "primereact/button";
import { toPng } from "html-to-image";
import { useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { GetOrdensById } from "../../../store/orden-store/orden-actions";
import Badges from "../../../components/Badges";

const UserBadges = () => {
  const orden = useSelector((state) => state.orden.orden);
  const navigate = useNavigate();
  const param = useParams();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const usuario = useSelector((state) => state.usuario.usuario);

  useEffect(() => {
    if (orden && orden.ordenId <= 0) {
      dispatch(
        GetOrdensById(param.ordenId, auth.UserInfo.email, true, usuario)
      );
    }
  }, [param.ordenId, auth, dispatch, usuario, orden]);

  const onButtonClick = useCallback((usuario) => {
    toPng(document.getElementById(usuario.id))
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = `${usuario.nombres}_${usuario.apellidos}.png`;
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {});
  }, []);


  return (
    <>
      <div className="formgrid grid mt-4">
        <div className="field col-12 md:col-3 ">
          <Button
            type="button"
            label="Regresar"
            icon="pi pi-arrow-left"
            className="p-button-danger mt-3"
            style={{
              width: "50%",
              border: "solid 0.5px #2196F3",
              borderRadius: "7px",
            }}
            onClick={() => {
              navigate(`/admin/detailorden/${orden.ordenId}/1`);
            }}
          />
        </div>
        <div className="field col-12 md:col-3 ">
          <Button
            type="button"
            label="Descargar Todos"
            className="p-button-dander"
            style={{
              width: "50%",
              border: "solid 0.5px #2196F3",
              borderRadius: "7px",
            }}
            onClick={() => {
              onButtonClick(usuario);
            }}
          />
        </div>
        <div className="field col-12 md:col-3 "></div>
        <div className="field col-12 md:col-3 "></div>
        {orden.usuarios.map((usuario) => {
          const badge = (
            <div
              key={usuario.id}
              className="field col-12 md:col-3"
              style={{ textAlign: "center" }}
            >
              <Card
                style={{
                  padding: "0px",
                  width: "96mm",
                  borderRadius: "15px",
                  backgroundColor: "#fff",
                  border: "#fff solid ",
                }}
              >
                <Badges orden={orden} usuario={usuario} />
              </Card>
            </div>
          );
          return badge;
        })}
      </div>
    </>
  );
};
export default UserBadges;
