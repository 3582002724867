import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ABADatatable from "../../../components/ABADatatable";

import { GetStatues } from "../../../store/ordenstatus-store/ordenstatus-actions";
import { ordenStatusActions } from "../../../store/ordenstatus-store/ordenstatus-slice";
import { Button } from "primereact/button";

const OrdenStatusHomePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ordenStatus = useSelector((state) => state.ordenStatus);
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    if (auth.roles !== undefined && auth.roles.includes("status.showAll")) {
      dispatch(GetStatues(auth.UserInfo.email));
    }
  }, [dispatch, auth]);

  const viewDetailHanlder = (val) => {
    navigate(`/orden/status/detail/${val.id}`);
  };
  const editHanlder = (val) => {
    navigate(`/orden/status/edit/${val.id}`);
  };
  const createHanlder = () => {
    dispatch(
      ordenStatusActions.setStatus({
        status: {
          id: 0,
          color: "",
          description: "",
          status: false,
        },
      })
    );
    navigate(`/orden/status/create`);
  };
  const backHanlder = () => {
    navigate(`/`);
  };
  let actions = [];
  const buttons = (rowData) => {
    let buttons = [];
    const buttonEdit = (
      <Button
        label="Editar"
        icon="pi pi-file-edit"
        className="p-button-rounded p-button-success p-button-text"
        onClick={() => {
          editHanlder(rowData);
        }}
      />
    );
    const buttonView = (
      <Button
        label="Ver"
        icon="pi pi-info-circle"
        className="p-button-rounded p-button-text"
        onClick={() => {
          viewDetailHanlder(rowData);
        }}
      />
    );
    if (auth.roles !== undefined && auth.roles.includes("status.edit")) {
      buttons.push(buttonEdit);
    }
    if (auth.roles !== undefined && auth.roles.includes("status.view")) {
      buttons.push(buttonView);
    }
    return buttons;
  };
  actions.push(buttons);

  const getColor = (rowData) => {
    if (
      rowData.color === null ||
      rowData.color === undefined ||
      rowData.color === "" ||
      rowData.color === "1"
    ) {
      return (
        <div style={{ backgroundColor: "#ffffff", color: "#ffffff" }}>
          {rowData.color}
        </div>
      );
    }
    return (
      <div style={{ backgroundColor: rowData.color, color: rowData.color }}>
        {rowData.color}
      </div>
    );
  };

  const columns = [
    {
      field: "description",
      header: "Nombre",
      filterMatchMode: "contains",
    },
    {
      field: "color",
      header: "Color",
      body: (ordenStatus) => {
        return getColor(ordenStatus);
      },
      filterMatchMode: "contains",
    },
    {
      field: "status",
      header: "Estado",
      filterMatchMode: "contains",
    },
  ];
  return (
    <>
      <ABADatatable
        title="Estatus"
        values={ordenStatus.statues}
        rows={5}
        rowsPerPageOptions={[5, 10, 20]}
        Columns={columns}
        actions={actions}
        createButton={{
          label: "Crear Estado",
          onClick: createHanlder,
          permission: "status.create",
        }}
        backButton={{
          label: "Regresar",
          onClick: backHanlder,
          permission: "goBack",
        }}
      />
    </>
  );
};
export default OrdenStatusHomePage;
