import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  courseTypes: [],
  courseType: {
    id: 0,
    description: "",
    name: "",
    Status: false,
    creationDate: new Date().toDateString(),
    creationUser: new Date().toDateString(),
  },
  courseTypeValidation: {
    isValid: true,
    name: {
      isValid: true,
      errorMessage: "El campo tipo de curso no debe estar vacio",
    },
  },
};

const courseTypeSlice = createSlice({
  name: "courseType",
  initialState,
  reducers: {
    restartState(state) {
      state.courseType = {};
      state.courseTypes = [];
    },
    setcourseTypes(state, actions) {
      state.courseTypes = actions.payload.courseTypes;
      state.courseType = {};
    },
    setcourseType(state, actions) {
      state.courseType = actions.payload.courseType;
      state.courseTypes = [];
    },
    modifyPropertyValue(state, actions) {
      state.courseType[actions.payload.id] = actions.payload.value;
    },
    setcourseTypeValidation(state, actions) {
      state.courseTypeValidation = {
        name: {
          ...state.courseTypeValidation.name,
          isValid: actions.payload.name !== "",
        },
      };
      state.courseTypeValidation.isValid =
        state.courseTypeValidation.description.isValid;
    },
    setFieldValid(state, actions) {
      state.courseTypeValidation.name.isValid =
        actions.payload.value;
    },
  },
});

export const courseTypeActions = courseTypeSlice.actions;
export default courseTypeSlice;
