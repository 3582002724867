import { useDispatch } from "react-redux";
import { authProvider } from "../../store/AuthProvider";
import { authActions } from "../../store/auth-slice";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import "primereact/resources/themes/saga-blue/theme.css";
import logoABA from "../../assets/img/LogoABA.png";
import "./Login.css";

const Login = () => {
  const dispatch = useDispatch();
  const loginHandler = async () => {
    await authProvider.login();
    const account = authProvider.getAccount();
    dispatch(
      authActions.login({
        UserInfo: {
          id: account != null ? account.accountIdentifier : "",
          name: account != null ? account.name : "",
          email: account != null ? account.userName : "",
        },
        isAuthenticate: account != null ? true : false,
        roles: account != null ? account.idToken.roles : [],
      })
    );
  };
  const header = (
    <div style={{backgroundColor:"#0D3D61"}}>
      <img alt="Card" className="bg-blue-900 loginImage" src={logoABA} />
    </div>
  );
  const footer = (
    <div className="flex flex-wrap justify-content-end gap-2 footerLogin">
      <Button
        label="Iniciar Sesión"
        onClick={loginHandler}
        className="p-button-outlined p-button-danger loginButton"
      />
    </div>
  );
  return (
    <>
      <div className="card flex justify-content-center ">
        <Card
          title="Registro Pagos Admin"
          subTitle="Favor de iniciar Sesión"
          footer={footer}
          header={header}
          className="loginSize"
        ></Card>
      </div>
    </>
  );
};
export default Login;
