import { ordenStatusActions } from "./ordenstatus-slice";
import { uiActions } from "../ui-slice";

export const GetStatues = (email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Solicitando Estados de Ordenes",
        message: "Solicitando estados al servidor.",
      })
    );
    const GetStatuesInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/orden/status/getall`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo obtener los estados");
      }
      const data = await response.json();
      return data;
    };

    try {
      const response = await GetStatuesInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          ordenStatusActions.setStatues({
            statues: response.data.map((status) => {
              return {
                ...status,
                status: status.status === true ? "Activo" : "Inactivo",
              };
            }),
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Datos Cargados",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          ordenStatusActions.setStatues({
            statues: [],
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo obtener los estados",
        })
      );
    }
  };
};
export const GetStatus = (id, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Solicitando Estado",
        message: "Solicitando estado al servidor.",
      })
    );
    const getStatusInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/orden/status/getById`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            Id: id,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo obtener el estado");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await getStatusInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          ordenStatusActions.setStatus({
            status: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Datos Cargados",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          ordenStatusActions.setStatus({
            status: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo obtener el estado",
        })
      );
    }
  };
};
export const CreateStatus = (status, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Creando Estado",
        message: "Solicitando Creación del Estado al servidor.",
      })
    );
    const createStatusInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/orden/status/create`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            ...status,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo crear el estado");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await createStatusInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          ordenStatusActions.setStatus({
            status: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Estado Creado",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          ordenStatusActions.setStatus({
            status: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo crear el estado",
        })
      );
    }
  };
};
export const UpadateStatus = (status, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Actualizando Estado",
        message: "Solicitando Actualización del Estado al servidor.",
      })
    );

    const updateStatusInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/orden/status/update`,
        {
          method: "POST",
          body: JSON.stringify({
            ...status,
            UserName: email,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo actualizar el estado");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await updateStatusInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          ordenStatusActions.setStatus({
            status: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Estado Actualizado",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          ordenStatusActions.setStatus({
            status: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo actualizar el estado",
        })
      );
    }
  };
};
export const ActiveStatus = (id, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Activando estado",
        message: "Solicitando activación del estado al servidor.",
      })
    );
    const activeStatusInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/orden/status/active`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            Id:id
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo activar el estado");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await activeStatusInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          ordenStatusActions.setStatus({
            status: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Estado Activado",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          ordenStatusActions.setStatus({
            status: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo activar el estado",
        })
      );
    }
  };
};

export const InactiveStatus = (id, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Activando estado",
        message: "Solicitando activación del estado al servidor.",
      })
    );
    const inactiveStatusInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/orden/status/inactive`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            Id:id
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo inactivar el estado");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await inactiveStatusInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          ordenStatusActions.setStatus({
            status: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Estado Inactivado",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          ordenStatusActions.setStatus({
            status: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo inactivar el estado",
        })
      );
    }
  };
};
