import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Message } from "primereact/message";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { companyActions } from "../../store/company-store/company-slice";
import { uiActions } from "../../store/ui-slice";

let restartPage = false;

const CompanyForms = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isValid = useSelector((state) => state.company.companyValidation);
  const auth = useSelector((state) => state.auth);
  const company = props.company;
  const notification = useSelector((state) => state.ui.notification);

  const onChangeHandler = (id, value) => {
    dispatch(companyActions.modifyPropertyValue({ id, value }));
    if (id !== "rnc") {
      if (value !== "") {
        dispatch(companyActions.setFieldValid({ id, value: true }));
      } else {
        dispatch(companyActions.setFieldValid({ id, value: false }));
      }
    }
  };
  
  
  useEffect(() => {
    if (
      notification &&
      notification.status === "success" &&
      company.id > 0 &&
      restartPage
    ) {
      dispatch(companyActions.restartState());
      window.location.href = "/companies";
    }
  }, [company.id, dispatch, navigate, notification]);
  const onSubmitHandler = (event) => {
    event.preventDefault();
    if (company.name === "") {
      dispatch(companyActions.setFieldValid({ id: "name", value: false }));
      dispatch(
        uiActions.showNotification({
          status: "warning",
          title: "Advertencia",
          message: "Existen valores invalidos, favor de revisar.",
        })
      );
    } else {
      dispatch(props.actionCompany(company, auth.UserInfo.email));
      restartPage = true;
    }
  };
  const getFormErrorMessage = (id) => {
    return !isValid[id].isValid ? (
      <Message severity="warn" text={isValid[id].errorMessage} />
    ) : (
      <small className="p-warn">&nbsp;</small>
    );
  };
  return (
    <>
      <Card
        style={{
          backgroundColor: "#142f47",
          marginLeft: "1%",
          marginTop: "-16px",
          boxShadow: "none",
          height: "100%",
          width: "99%",
        }}
      >
        <form onSubmit={onSubmitHandler}>
          <div className="formgrid grid">
            <div className="field col-12 md:col-12">
              <h2 className="card-header" style={{ color: "#fff" }}>
                Empresa:
                <span style={{ color: "lightskyblue", marginLeft: "1%" }}>
                  {company && company.name && company.name.toUpperCase()}
                </span>
              </h2>
            </div>
            <div className="field col-12 md:col-6">
              <label
                htmlFor="name"
                style={{ fontSize: "large", color: "#35c4dc" }}
              >
                Empresa:
              </label>
              <InputText
                id="name"
                value={company.name}
                onChange={(e) => onChangeHandler("name", e.target.value)}
                placeholder=""
                style={{ width: "100%" }}
              />
              <div>{getFormErrorMessage("name")}</div>
            </div>
            <div className="field col-12 md:col-8">
              <label
                htmlFor="rnc"
                style={{ fontSize: "large", color: "#35c4dc" }}
              >
                RNC:
              </label>
              <InputText
                id="rnc"
                value={company.rnc}
                onChange={(e) => onChangeHandler("rnc", e.target.value)}
                style={{ width: "100%" }}
              />
            </div>
          </div>
          <div className="formgrid grid">
            <Button
              rounded
              text
              raised
              style={{ backgroundColor: "#fff" }}
              label="Regresar"
              icon="pi pi-arrow-left"
              className="p-button-danger"
              onClick={() => {
                navigate("/companies");
              }}
            />
            <Button
              rounded
              text
              raised
              style={{ backgroundColor: "#fff", marginLeft: "1%" }}
              label="Guardar"
              className="p-button-success"
              icon={
                notification && notification.status === "pending"
                  ? "pi pi-spin pi-spinner"
                  : "pi pi-save"
              }
              disabled={notification && notification.status === "pending"}
            />
          </div>
        </form>
      </Card>
    </>
  );
};
export default CompanyForms;
