import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "primereact/button";

import ABADatatable from "../../../components/ABADatatable";
import { conferenceEventActions } from "../../../store/conferencesEvent-store/conferenceEvent-slice";
import { Rating } from "primereact/rating";
import { GetConferenceEvents } from "../../../store/conferencesEvent-store/conferenceEvent-actions";

const ConferenceEventsHomePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const conference = useSelector((state) => state.conferenceEvent.conference);
  const auth = useSelector((state) => state.auth);
  useEffect(() => {
    if (
      auth.roles !== undefined &&
      auth.roles.includes("conferenceEvent.showAll") &&
      conference &&
      conference.id === undefined
    ) {
      dispatch(GetConferenceEvents(auth.UserInfo.email, params.conferenceId));
    }
  }, [dispatch, auth, params.conferenceId, conference]);

  const viewDetailHanlder = (val) => {
    navigate(`/conference/event/detail/${val.id}`);
  };
  const editHanlder = (val) => {
    navigate(`/conference/event/edit/${val.conferenceId}/${val.id}`);
  };
  const createHanlder = () => {
    dispatch(
      conferenceEventActions.setConferenceEvent({
        conferenceEvent: {
          id: 0,
          conferenceId: 0,
          name: "",
          startTime: "00:00",
          endTime: "00:00",
          location: "",
          eventDate: new Date().toDateString(),
          rating: 1,
          startQuestions: false,
          startRating: false,
        },
      })
    );
    navigate(`/conference/event/create/${params.conferenceId}`);
  };
  const backHanlder = () => {
    navigate(`/conference/detail/${params.conferenceId}`);
  };
  let actions = [];
  const buttons = (rowData) => {
    let buttons = [];
    const buttonEdit = (
      <Button
        label="Editar"
        icon="pi pi-file-edit"
        className="p-button-rounded p-button-success p-button-text"
        onClick={() => {
          editHanlder(rowData);
        }}
      />
    );
    const buttonView = (
      <Button
        label="Ver"
        icon="pi pi-info-circle"
        className="p-button-rounded p-button-text"
        onClick={() => {
          viewDetailHanlder(rowData);
        }}
      />
    );
    if (
      auth.roles !== undefined &&
      auth.roles.includes("conferenceEvent.edit")
    ) {
      buttons.push(buttonEdit);
    }
    if (
      auth.roles !== undefined &&
      auth.roles.includes("conferenceEvent.view")
    ) {
      buttons.push(buttonView);
    }
    return buttons;
  };
  actions.push(buttons);

  const getstarts = (rowData) => {
    return (
      <Rating
        value={rowData.rating}
        onIcon={
          <i className="pi pi-star-fill" style={{ color: "#F9C02D" }}></i>
        }
        readOnly
        cancel={false}
      />
    );
  };

  const columns = [
    {
      field: "name",
      header: "Conferencia",
      filterMatchMode: "contains",
    },
    {
      field: "location",
      header: "Lugar",
      filterMatchMode: "contains",
    },
    {
      field: "eventDate",
      header: "Fecha",
      filterMatchMode: "contains",
    },
    {
      field: "startTime",
      header: "Hora de inico",
      filterMatchMode: "contains",
    },
    {
      header: "Valoración",
      body: getstarts,
      filterMatchMode: "contains",
    },
  ];
  return (
    <>
      <ABADatatable
        title={`${
          conference !== undefined && conference.name !== undefined
            ? conference.name
            : ""
        } => Conferencias`}
        values={
          conference !== undefined &&
          conference.events !== undefined &&
          conference.events.length > 0
            ? conference.events
            : []
        }
        rows={5}
        rowsPerPageOptions={[5, 10, 20]}
        Columns={columns}
        actions={actions}
        width="20%"
        createButton={{
          label: "Crear Conferencia",
          onClick: createHanlder,
          permission: "conferenceEvent.create",
        }}
        backButton={{
          label: "Regresar",
          onClick: backHanlder,
          permission: "goBack",
        }}
      />
    </>
  );
};
export default ConferenceEventsHomePage;
