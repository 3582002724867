import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sponsoragendas: [],
};

const sponsorAgendaSlice = createSlice({
  name: "sponsoragenda",
  initialState,
  reducers: {
    restartState(state) {
      state.sponsoragendas = [];
    },
    setsponsorshipAgendas(state, actions) {
      state.sponsoragendas = actions.payload;
    },
  },
});

export const sponsorAgendaActions = sponsorAgendaSlice.actions;
export default sponsorAgendaSlice;
