import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  charges: [],
  charge: {
    id: 0,
    name: "",
    description: "",
    status: false,
  },
  chargeValidation: {
    isValid: true,
    name: {
      isValid: true,
      errorMessage: "El campo nombre no debe estar vacio",
    },
    description: {
      isValid: true,
      errorMessage: "El campo descripción no debe estar vacio",
    },
  },
};

const chargeSlice = createSlice({
  name: "charge",
  initialState,
  reducers: {
    restartState(state) {
      state.charge = {};
      state.charges = [];
    },
    setCharges(state, actions) {
      state.charges = actions.payload.charges;
      state.charge = {};
    },
    setCharge(state, actions) {
      state.charge = actions.payload.charge;
      state.charges = [];
    },
    modifyPropertyValue(state, actions) {
      state.charge[actions.payload.id] = actions.payload.value;
    },
    setChargeValidation(state, actions) {
      state.chargeValidation = {
        name: {
          ...state.chargeValidation.name,
          isValid: actions.payload.name !== "",
        },
        description: {
          ...state.chargeValidation.description,
          isValid: actions.payload.description !== "",
        },
      };
      state.chargeValidation.isValid =
        state.chargeValidation.description.isValid;
    },
    setFieldValid(state, actions) {
      state.chargeValidation[actions.payload.id].isValid = actions.payload.value;
    },
  },
});

export const chargeActions = chargeSlice.actions;
export default chargeSlice;
