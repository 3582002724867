import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  companies: [],
  company: {
    id: 0,
    name: "",
    rnc: "",
    status: false,
  },
  companyValidation: {
    isValid: true,
    name: {
      isValid: true,
      errorMessage: "El campo nombre no debe estar vacio",
    },
  },
};

const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    restartState(state) {
      state.company = {};
      state.companies = [];
    },
    setCompanies(state, actions) {
      state.companies = actions.payload.companies;
      state.company = {};
    },
    setCompany(state, actions) {
      state.company = actions.payload.company;
      state.companies = [];
    },
    modifyPropertyValue(state, actions) {
      state.company[actions.payload.id] = actions.payload.value;
    },
    setCompanyValidation(state, actions) {
      state.companyValidation = {
        name: {
          ...state.companyValidation.name,
          isValid: actions.payload.name !== "",
        },
      };
      state.companyValidation.isValid = state.companyValidation.name.isValid;
    },
    setFieldValid(state, actions) {
      state.companyValidation[actions.payload.id].isValid =
        actions.payload.value;
    },
  },
});

export const companyActions = companySlice.actions;
export default companySlice;
