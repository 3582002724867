import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { GetConferencesActive } from "../../store/conferences-store/conference-actions";
import { Card } from "primereact/card";
import congresoImage from "../../assets/img/Sold out.png";

const AdminHomePage = () => {
  const conferences = useSelector((state) => state.conference.conferences);
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    if (conferences && conferences.length <= 0) {
      // dispatch(GetConferencesActive(auth.UserInfo.email));
    }
  }, [auth, conferences, dispatch]);

  

  return (
    <>
      <div className="formgrid grid">
        {conferences.map((conference) => {
          return (
            <div className="field col-12 md:col-3 mt-3" key={conference.id}>
              {conference.status === true && (
                <Card
                  style={{
                    backgroundColor: "#fff",
                    marginLeft: "1%",
                    marginTop: "0px",
                    paddingBottom: "10px",
                    boxShadow: "white 0px 2px 15px 0px",
                    width: "75%",
                    height: "220px",
                  }}
                >
                  <div
                    style={{
                      paddingBottom: "80px",
                      paddingLeft: "10px",
                      paddingTop: "10px",
                      boxShadow: "none",
                      height: "220px",
                    }}
                  >
                    <div className="formgrid grid">
                      <div className="field col-12 md:col-4 mt-3">
                        <span
                          style={{
                            fontSize: "large",
                            color: "#000",
                            fontWeight: "bold",
                          }}
                        >
                          Evento:
                        </span>
                      </div>
                      <div
                        className="field col-12 md:col-6"
                        style={{ marginTop: "18px" }}
                      >
                        {conference.name}
                      </div>
                      <div className="field col-12 md:col-4 mt-3">
                        <span
                          style={{
                            fontSize: "large",
                            color: "#000",
                            fontWeight: "bold",
                          }}
                        >
                          Inscritos:
                        </span>
                      </div>
                      <div
                        className="field col-12 md:col-6"
                        style={{ marginTop: "18px" }}
                      >
                        {conference.totalRegister}/{conference.soldOut}
                      </div>
                      {conference.totalRegister === conference.soldOut && (
                        <div className="field col-12 md:col-4">
                          <img
                            alt="logo"
                            src={congresoImage}
                            style={{ height: "100px", marginTop: "-30px" }}
                          ></img>
                        </div>
                      )}
                    </div>
                  </div>
                </Card>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};
export default AdminHomePage;
