import { Button } from "primereact/button";
import ABADatatable from "../../../components/ABADatatable";
import { arregloPaises } from "../../../components/List";
import { useDispatch, useSelector } from "react-redux";
import { MarkAssistance } from "../../../store/user-store/user-actions";
const Attended = (props) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  const paises = arregloPaises.map((pais) => ({
    code: pais.nombre,
    name: pais.nombre,
    flag: pais.abreviatura,
  }));
  const viewDetailHanlder = (userId, ordenId) => {
    dispatch(MarkAssistance(userId, ordenId, auth.UserInfo.email));
  };

  let actions = [];
  const buttons = (rowData) => {

    let buttons = [];

    const buttonAttend = (
      <Button
        icon="pi pi-check"
        rounded
        outlined
        aria-label="Filter"
        onClick={() => {
          viewDetailHanlder(rowData.id, rowData.ordenId);
        }}
      />
    );
    if (!rowData.assitance) {
      buttons.push(buttonAttend);
    }
    return buttons;
  };
  actions.push(buttons);
  const columns = [
    {
      field: "ordenId",
      header: "Orden",
      filterMatchMode: "contains",
    },

    {
      field: "name",
      header: "Nombres",
      filterMatchMode: "contains",
    },
    {
      field: "lastName",
      header: "Apellidos",
      filterMatchMode: "contains",
    },
    {
      field: "email",
      header: "Correo",
      filterMatchMode: "contains",
    },
    {
      field: "country",
      header: "País",
      filterMatchMode: "contains",
      body: (rowData) => {
        const pais = paises.find((pais) => pais.name === rowData.country);
        if (pais === undefined) {
          return <div></div>;
        }
        return (
          <div className="flex align-items-center" style={{ width: "100px" }}>
            <img
              alt={pais.name}
              src={`https://flagcdn.com/48x36/${pais.flag.toLowerCase()}.png`}
              style={{ width: "18px" }}
            />
            <div style={{ marginLeft: "5%" }}>{pais.name}</div>
          </div>
        );
      },
    },
  ];
  return (
    <div style={{ width: "110%" }}>
      <ABADatatable
        widthCard="90%"
        width="20%"
        title="Usuarios"
        values={props.users}
        rows={5}
        rowsPerPageOptions={[5, 10, 20]}
        Columns={columns}
        actions={actions}
        createButton={{}}
        backButton={{}}
      />
    </div>
  );
};
export default Attended;
