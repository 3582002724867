import { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { useSelector } from "react-redux";
import { Card } from "primereact/card";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

const ABADatatable = (props) => {
  const [globalFilter, setGlobalFilter] = useState(null);
  const auth = useSelector((state) => state.auth);

  const header = (
    <div className="formgrid grid">
      <div className="field col-12 md:col-12">
        <i className="pi pi-search" style={{ margin: "8px" }}></i>
        <input
          type="search"
          className="p-inputtext p-component p-column-filter"
          onChange={(e) => setGlobalFilter(e.target.value)}
          placeholder="Buscar"
          style={{ width: "98%" }}
        />
      </div>
      <div className="field col-12 md:col-12">
        {props.backButton !== undefined &&
          props.backButton.permission === "goBack" && (
            <Button
              rounded
              text
              raised
              type="button"
              style={{ marginLeft: "10px" }}
              icon="pi pi-arrow-left"
              severity="danger"
              label={props.backButton.label}
              onClick={props.backButton.onClick}
            />
          )}
        {auth.roles !== undefined &&
          auth.roles.includes(props.createButton.permission) && (
            <Button
              rounded
              text
              raised
              type="button"
              style={{ marginLeft: "10px" }}
              icon="pi pi-plus"
              severity="success"
              label={props.createButton.label}
              onClick={props.createButton.onClick}
            />
          )}
      </div>
    </div>
  );

  return (
    <>
      <Card
        className="mt-3  bg-blue-900"
        title={props.title}
        style={{
          color: "#fff",
          height: props.height !== undefined ? props.height : "100%",
          width: props.widthCard !== undefined ? props.widthCard : "97%",
          marginBottom:
            props.marginBottom !== undefined ? props.marginBottom : "0px",
        }}
      >
        <DataTable
          stripedRows
          paginator
          tableStyle={{ minWidth: "50rem" }}
          filterDisplay={props.dontshowMenu === undefined ? "menu" : ""}
          header={header}
          globalFilter={globalFilter}
          value={props.values}
          dataKey={props.title}
          rows={props.rows}
          rowsPerPageOptions={props.rowsPerPageOptions}
          emptyMessage={`No se encontraron registros`}
        >
          {props.Columns.map((col, i) => (
            <Column
              key={i}
              field={col.field !== undefined ? col.field : ""}
              header={col.header}
              filterMatchMode={col.filterMatchMode}
              body={col.body}
              sortable={col.sortable !== undefined ? col.filter : true}
              filter={col.filter !== undefined ? col.filter : true}
              style={{ width: props.width ? props.width : "35%" }}
            />
          ))}

          {props.actions.length > 0 &&
            props.actions.map((b, i) => {
              return (
                <Column
                  key={i}
                  body={(rowData) => {
                    return <div className="flex">{b(rowData)}</div>;
                  }}
                  style={{ textAlign: "center", width: "6rem" }}
                />
              );
            })}
        </DataTable>
      </Card>
    </>
  );
};
export default ABADatatable;
