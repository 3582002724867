import { useSelector } from "react-redux";
import CourseCategoryForm from "../../../components/Forms/CourseCategoryForm";
import { CreateCourseCategory } from "../../../store/coursecategory-store/coursecategory-actions";

const CourseCategoryCreate = () => {
  const courseCategory = useSelector(
    (state) => state.courseCategory.courseCategory
  );

  return (
    <>
      <CourseCategoryForm
        actionCourseCategory={CreateCourseCategory}
        courseCategory={courseCategory}
      />
    </>
  );
};
export default CourseCategoryCreate;
