import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Tag } from "primereact/tag";

const ConferenceDetailComponent = (props) => {
  let USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  var options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const startDateConference = new Date(
    props.conference.startDate
  ).toLocaleDateString("es-ES", options);

  const optionBodyTemplate = (rowData) => {
    
    return (
      <Button
        label={rowData.url}
        link
        onClick={() => window.open(rowData.url, "_blank")}
      />
    );
  };

  return (
    <>
      <div className="formgrid grid" style={{ marginBottom: "-50px" }}>
        <div className="field col-12 md:col-12 mt-3">
          <label
            style={{ color: "#000", fontSize: "large", fontWeight: "bolder" }}
          >
            Nombre del evento:
          </label>
          <label
            style={{
              marginLeft: "1%",
              fontSize: "large",
            }}
          >
            {props.conference.name.toUpperCase()}
          </label>
        </div>

        {props.showInfo === undefined && (
          <div className="field col-12 md:col-3 mt-3">
            <label
              style={{ color: "#000", fontSize: "large", fontWeight: "bolder" }}
            >
              Precio:
            </label>
            <label
              style={{
                marginLeft: "1%",
                fontSize: "large",
              }}
            >
              {USDollar.format(props.conference.price)}
            </label>
          </div>
        )}

        {props.showInfo === undefined && (
          <div className="field col-12 md:col-3 mt-3">
            <label
              style={{ color: "#000", fontSize: "large", fontWeight: "bolder" }}
            >
              Fecha de inicio:
            </label>
            <label
              style={{
                marginLeft: "1%",
                fontSize: "large",
              }}
            >
              {startDateConference}
            </label>
          </div>
        )}

        <div className="field col-12 md:col-3 mt-3">
          {props.statusConference}
        </div>
        <div className="field col-12 md:col-3 mt-3">
          <span className="fw-bold me-2" style={{ color: "#000" }}>
            Solo Tarjeta de Crédito:&nbsp;
          </span>
          {props.conference.creditCardOnly && (
            <Tag
              value="Activo"
              severity={"success"}
              style={{ fontSize: "medium" }}
            ></Tag>
          )}
          {!props.conference.creditCardOnly && (
            <Tag
              value="Inactivo"
              severity={"warning"}
              style={{ fontSize: "medium" }}
            ></Tag>
          )}
        </div>
        {props.showInfo === undefined && (
          <div className="field col-12 md:col-3 mt-3">
            <label
              style={{ color: "#000", fontSize: "large", fontWeight: "bolder" }}
            >
              Lugar:
            </label>
            <label
              style={{
                marginLeft: "1%",
                fontSize: "large",
              }}
            >
              {props.conference.location}
            </label>
          </div>
        )}
        {props.isDetail && (
          <div className="field col-12 md:col-12 mt-3">
            <label
              style={{ color: "#000", fontSize: "large", fontWeight: "bolder" }}
            >
              Url Login:
            </label>
            <Button
              label={props.conference.loginUrl}
              link
              onClick={() => window.open(props.conference.loginUrl, "_blank")}
            />
          </div>
        )}
        {props.isDetail && props.conference.conferenceMenuInfoes.length > 0 && (
          <div className="field col-12 md:col-12 mt-3">
            <div className="card">
              <DataTable
                value={props.conference.conferenceMenuInfoes}
              >
                <Column field="name" header="Nombre Opción" style={{width:"45%"}}></Column>
                <Column header="Url" body={optionBodyTemplate}></Column>
              </DataTable>
            </div>
          </div>
        )}
        <div className="field col-12 md:col-12 mt-3">
          <label
            style={{ color: "#000", fontSize: "large", fontWeight: "bolder" }}
          >
            Descripción:
          </label>
          <label
            style={{
              marginLeft: "1%",
              fontSize: "large",
            }}
          >
            {props.conference.description}
          </label>
        </div>
        <div className="field col-12 md:col-12 mt-3">
          <label
            style={{ color: "#000", fontSize: "large", fontWeight: "bolder" }}
          >
            Términos y condiciones:
          </label>
          <label
            style={{
              marginLeft: "1%",
              fontSize: "large",
            }}
          >
            {props.conference.ternmConditions}
          </label>
        </div>
        <div className="field col-12 md:col-12 mt-3">{props.buttons}</div>
      </div>
    </>
  );
};
export default ConferenceDetailComponent;
