import { dolarDiarioActions } from "./dolardiario-slice";
import { uiActions } from "../ui-slice";

export const GetDolarDiario = (id, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Solicitando Valor del dolar",
        message: "Solicitando valor del dolar al servidor.",
      })
    );
    const getDolarDiarioInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/dollar/getById`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            Id: id,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo obtener el el valor del dolar");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await getDolarDiarioInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          dolarDiarioActions.setDolarDiario({
            dolarDiario: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Valor del dolar obtenido",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          dolarDiarioActions.setDolarDiario({
            dolarDiario: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo obtener el valor del dolar",
        })
      );
    }
  };
};

export const UpadateDolarDiario = (dolarDiario, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Actualizando el valor del dolar",
        message: "Solicitando Actualización del valor del dolar al servidor.",
      })
    );
    const updatedolarDiarioInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/dollar/update`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            Id: dolarDiario.id,
            TCDolar: dolarDiario.tcDolar,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo actualizar el valor del dolar");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await updatedolarDiarioInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          dolarDiarioActions.setDolarDiario({
            dolarDiario: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Valor del dolar Actualizado",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          dolarDiarioActions.setDolarDiario({
            dolarDiario: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo actualizar el valor del dolar",
        })
      );
    }
  };
};
