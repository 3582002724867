import { userActions } from "./user-slice";
import { uiActions } from "../ui-slice";
import { saveAs } from "file-saver";

export const GetUsers = (email, dontShow) => {
  return async (dispatch) => {
    if (dontShow !== undefined) {
      dispatch(
        uiActions.showNotification({
          status: "pending",
          title: "Cargando inforamción",
          message: "Se esta solicitando la información al servidor.",
        })
      );
    }
    const GetUsersInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/user/getall`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );

      if (!response.ok) {
        throw new Error("No se pudo obtener la información solicitada");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await GetUsersInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          userActions.setusers({
            users: response.data,
          })
        );
        if (dontShow !== undefined) {
          dispatch(
            uiActions.showNotification({
              status: "success",
              title: "información cargada",
              message: response.result.message,
            })
          );
        }
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          userActions.setusers({
            users: [],
          })
        );
        if (dontShow !== undefined) {
          dispatch(
            uiActions.showNotification({
              status: "warning",
              title: "Advertencia",
              message: response.result.message,
            })
          );
        }
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo obtener la información solicitada",
        })
      );
    }
  };
};
export const GetUserById = (id, userName, users, dontShow) => {
  return async (dispatch) => {
    if (dontShow === undefined) {
      dispatch(
        uiActions.showNotification({
          status: "pending",
          title: "Solicitando Usuario",
          message: "Solicitando Usuario al servidor.",
        })
      );
    }
    const getUsuarioInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/user/getById`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: userName,
            Id: id,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo obtener el usuario");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await getUsuarioInfo();
      console.log(response);
      if (response.result.errorCode === "OK") {
        
        
        dispatch(
          userActions.setuser({
            user: response.data,
            users: users,
          })
        );
        if (dontShow === undefined) {
          dispatch(
            uiActions.showNotification({
              status: "success",
              title: "Datos Cargados",
              message: response.result.message,
            })
          );
        }
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          userActions.setuser({
            user: {},
            users: users,
          })
        );
        if (dontShow === undefined) {
          dispatch(
            uiActions.showNotification({
              status: "warning",
              title: "Advertencia",
              message: response.result.message,
            })
          );
        }
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo obtener el Usuario",
        })
      );
    }
  };
};
export const CreateUser = (user, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Creando Usuario",
        message: "Solicitando Creación del Usuario al servidor.",
      })
    );

    const getUsuarioInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/user/create`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            ...user,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo crear el usuario");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await getUsuarioInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          userActions.setuser({
            user: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Usuario Creado",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          userActions.setuser({
            user: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo crear el usuario",
        })
      );
    }
  };
};
export const UpdateUser = (user, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Actualizando Usuario",
        message: "Solicitando Actualización del Usuario al servidor.",
      })
    );

    const getCourseInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/user/update`,
        {
          method: "POST",
          body: JSON.stringify({
            ...user,
            UserName: email,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo actualizar el usuario");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await getCourseInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          userActions.setuser({
            user: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Usuario Actualizado",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          userActions.setuser({
            user: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo actualizar el usuario",
        })
      );
    }
  };
};
export const UpdatePasswordUser = (user, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Actualizando Usuario",
        message: "Solicitando Actualización del Usuario al servidor.",
      })
    );

    const getCourseInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/user/changepassword`,
        {
          method: "POST",
          body: JSON.stringify({
            Id: user.id,
            Password: user.password,
            UserName: email,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo actualizar el usuario");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await getCourseInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          userActions.setuser({
            user: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Usuario Actualizado",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          userActions.setuser({
            user: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo actualizar el usuario",
        })
      );
    }
  };
};
export const GetUserByemail = (email, userName, users, dontShow) => {
  return async (dispatch) => {
    if (dontShow === undefined) {
      dispatch(
        uiActions.showNotification({
          status: "pending",
          title: "Solicitando Usuario",
          message: "Solicitando Usuario al servidor.",
        })
      );
    }
    const getUsuarioInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/user/getByEmail`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: userName,
            Email: email,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo obtener el usuario");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await getUsuarioInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          userActions.setuser({
            user: response.data,
            users: users,
          })
        );
        if (dontShow === undefined) {
          dispatch(
            uiActions.showNotification({
              status: "success",
              title: "Datos Cargados",
              message: response.result.message,
            })
          );
        }
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          userActions.setuser({
            user: {},
            users: users,
          })
        );
        if (dontShow === undefined) {
          dispatch(
            uiActions.showNotification({
              status: "warning",
              title: "Advertencia",
              message: response.result.message,
            })
          );
        }
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo obtener el Usuario",
        })
      );
    }
  };
};
export const CleanUser = () => {
  return async (dispatch) => {
    try {
      dispatch(
        userActions.setuser({
          user: {
            email: "",
            name: "",
            lastName: "",
            identification: "",
            cellPhone: "",
            country: "",
            city: "",
            address: "",
            company: "",
            charge: {},
            department: {},
            status: false,
            token: "",
            fullName: "",
          },
        })
      );
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          // message: "No se pudo obtener el Usuario",
        })
      );
    }
  };
};
export const SendCertificate = (users, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Enviando Certificados",
        message: "Solicitando Envio de Certificados al servidor.",
      })
    );
    const formData = new FormData();

    users.forEach((user, index) => {
      formData.append(`body[${index}][Id]`, user.id);
      formData.append(`body[${index}][OrdenId]`, user.ordenId);
      formData.append(`body[${index}][Name]`, user.name);
      formData.append(`body[${index}][LastName]`, user.lastName);
      formData.append(`body[${index}][Email]`, user.email);
      formData.append(`body[${index}].File`, user.pdf);
      formData.append(`body[${index}][UserName]`, email);
    });

    const createConferenceInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/user/sendCertificate`,
        {
          method: "POST",
          body: formData,
          headers: {
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo enviar el certificado");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await createConferenceInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          userActions.setuser({
            conference: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Certificado Enviado",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          userActions.setuser({
            conference: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo Enviar el certificado",
        })
      );
    }
  };
};
export const SendAgain = (userId, ordenId, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Enviando el certificado de nuevo",
        message: "Solicitando Actualización del Usuario al servidor.",
      })
    );

    const getCourseInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/user/sendCertificateAgain`,
        {
          method: "POST",
          body: JSON.stringify({
            Id: userId,
            OrdenId: ordenId,
            UserName: email,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo Enviar el certificado");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await getCourseInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          userActions.setuser({
            user: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Usuario Actualizado",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          userActions.setuser({
            user: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo Enviar el certificado",
        })
      );
    }
  };
};
export const DownloadCertificate = (userId, ordenId, userName) => {
  return async (dispatch) => {
    const updateOrderInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/user/downloadCertificate`,
        {
          method: "POST",
          body: JSON.stringify({
            Id: userId,
            OrdenId: ordenId,
            UserName: userName,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo encontrar el certificado");
      }
      const data = await response.blob();
      const filename = response.headers.get("Content-Disposition");

      return { blob: data, filename };
    };
    try {
      const response = await updateOrderInfo();
      saveAs(response.blob, response.filename);
    } catch (error) {
      
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo descargar el certificado",
        })
      );
    }
  };
};

export const MarkAssistance = (userId, ordenId, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Actualizando Usuario",
        message: "Solicitando Actualización del Usuario al servidor.",
      })
    );

    const getCourseInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/user/attended`,
        {
          method: "POST",
          body: JSON.stringify({
            Id: userId,
            OrdenId: ordenId,
            UserName: email,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo actualizar el usuario");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await getCourseInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          userActions.setuser({
            user: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Usuario Actualizado",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          userActions.setuser({
            user: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo actualizar el usuario",
        })
      );
    }
  };
};

export const GetUsersByCourseId = (courseId, email, dontShow, usuario) => {
  return async (dispatch) => {
    if (dontShow !== undefined) {
      dispatch(
        uiActions.showNotification({
          status: "pending",
          title: "Solicitando users",
          message: "Solicitando Usuario al servidor.",
        })
      );
    }
    const GetUsersInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}training/user/getaAllByCourse`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            Payload: {
              id: courseId,
            },
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudieron obtener los users");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await GetUsersInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          userActions.setusers({
            usuario: usuario !== undefined ? usuario : {},
            users: response.data,
          })
        );
        if (dontShow !== undefined) {
          dispatch(
            uiActions.showNotification({
              status: "success",
              title: "Datos Cargados",
              message: response.result.message,
            })
          );
        }
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          userActions.setusers({
            usuario: usuario !== undefined ? usuario : {},
            users: [],
          })
        );
        if (dontShow !== undefined) {
          dispatch(
            uiActions.showNotification({
              status: "warning",
              title: "Advertencia",
              message: response.result.message,
            })
          );
        }
      }
    } catch (error) {
      dispatch(
        userActions.setusers({
          users: [],
        })
      );
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudieron obtener la información solicitada",
        })
      );
    }
  };
};
export const GetUsersByConferecenId = (
  conferecenId,
  email,
  dontShow,
  usuario
) => {
  return async (dispatch) => {
    if (dontShow !== undefined) {
      dispatch(
        uiActions.showNotification({
          status: "pending",
          title: "Solicitando users",
          message: "Solicitando Usuario al servidor.",
        })
      );
    }
    const GetUsersInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}training/user/getaAllByConference`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            Payload: {
              id: conferecenId,
            },
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudieron obtener los users");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await GetUsersInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          userActions.setusers({
            usuario: usuario !== undefined ? usuario : {},
            users: response.data.map((usuario) => {
              return {
                ...usuario,
                fullName: usuario.nombres + " " + usuario.apellidos,
              };
            }),
          })
        );
        if (dontShow !== undefined) {
          dispatch(
            uiActions.showNotification({
              status: "success",
              title: "Datos Cargados",
              message: response.result.message,
            })
          );
        }
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          userActions.setusers({
            usuario: usuario !== undefined ? usuario : {},
            users: [],
          })
        );
        if (dontShow !== undefined) {
          dispatch(
            uiActions.showNotification({
              status: "warning",
              title: "Advertencia",
              message: response.result.message,
            })
          );
        }
      }
    } catch (error) {
      dispatch(
        userActions.setusers({
          users: [],
        })
      );
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudieron obtener la información solicitada",
        })
      );
    }
  };
};
export const GetUsersForNetWorkingByConferecenId = (
  conferecenId,
  email,
  dontShow,
  usuario
) => {
  return async (dispatch) => {
    if (dontShow !== undefined) {
      dispatch(
        uiActions.showNotification({
          status: "pending",
          title: "Solicitando users",
          message: "Solicitando Usuario al servidor.",
        })
      );
    }
    const GetUsersInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}training/user/getaAllByConference`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            Payload: {
              id: conferecenId,
            },
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudieron obtener los users");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await GetUsersInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          userActions.setusers({
            usuario: usuario !== undefined ? usuario : {},
            users: response.data.map((usuario) => {
              return {
                ...usuario,
                fullName: usuario.nombres + " " + usuario.apellidos,
              };
            }),
          })
        );
        if (dontShow !== undefined) {
          dispatch(
            uiActions.showNotification({
              status: "success",
              title: "Datos Cargados",
              message: response.result.message,
            })
          );
        }
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          userActions.setusers({
            usuario: usuario !== undefined ? usuario : {},
            users: [],
          })
        );
        if (dontShow !== undefined) {
          dispatch(
            uiActions.showNotification({
              status: "warning",
              title: "Advertencia",
              message: response.result.message,
            })
          );
        }
      }
    } catch (error) {
      dispatch(
        userActions.setusers({
          users: [],
        })
      );
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudieron obtener la información solicitada",
        })
      );
    }
  };
};
export const GetaAllUserBySponsor = (sponsorId, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Solicitando users",
        message: "Solicitando Usuario al servidor.",
      })
    );
    const GetUsersInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}training/user/getaAllUserBySponsor`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            Payload: {
              Id: sponsorId,
            },
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudieron obtener los users");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await GetUsersInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          userActions.setusers({
            users: response.data.map((usuario) => {
              return {
                ...usuario,
                fullName: usuario.nombres + " " + usuario.apellidos,
              };
            }),
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Datos Cargados",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          userActions.setusers({
            usuario: {},
            users: [],
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        userActions.setusers({
          users: [],
        })
      );
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudieron obtener la información solicitada",
        })
      );
    }
  };
};

export const CreateBySponsor = (usuario, sponsorId, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Actualizando Usuario",
        message: "Solicitando Actualización del Usuario al servidor.",
      })
    );

    const getUsuarioInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}training/user/createBySponsor`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            Sponsorship: {
              Id: sponsorId,
            },
            User: { ...usuario },
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo crear el usuario");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await getUsuarioInfo();
      if (response.result.errorCode === "OK") {
        // dispatch(sponsorshipActions.setsponsorship({}));
        dispatch(
          userActions.setuser({
            usuario: {
              id: response.data.id,
              email: response.data.email,
              nombres: response.data.nombres,
              apellidos: response.data.apellidos,
              telefonoCelular: response.data.telefonoCelular,
              pais: response.data.pais,
              ciudad: response.data.ciudad,
              direccion: response.data.direccion,
              entidadLabora: response.data.entidadLabora,
              cargo: response.data.cargo,
              departamento: response.data.departamento,
              identificacion: response.data.identificacion,
              fechaCreacion: response.data.fechaCreacion,
            },
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Usuario Creado",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          userActions.setuser({
            usuario: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo crear el usuario",
        })
      );
    }
  };
};
export const RegisterUsuario = (usuario, email, conferecenId) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Actualizando Usuario",
        message: "Solicitando Registro del Usuario al servidor.",
      })
    );
    const getUsuarioInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}training/user/register`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            ConferecenId: conferecenId,
            Payload: {
              ...usuario,
              telefonoCelular: usuario.telefonoCelular.toString(),
            },
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo crear el usuario");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await getUsuarioInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          userActions.setuser({
            usuario: {
              email: response.data.email,
              nombres: response.data.nombres,
              apellidos: response.data.apellidos,
              telefonoCelular: response.data.telefonoCelular,
              pais: response.data.pais,
              ciudad: response.data.ciudad,
              direccion: response.data.direccion,
              entidadLabora: response.data.entidadLabora,
              cargo: response.data.cargo,
              departamento: response.data.departamento,
              identificacion: response.data.identificacion,
              fechaCreacion: response.data.fechaCreacion,
            },
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Usuario Creado",
            message: response.result.message,
          })
        );
        dispatch(userActions.setIsRegister(true));
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo crear el usuario",
        })
      );
    }
  };
};
export const ValidateToken = (TokenInfo, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Validando Token",
        message: "Solicitando Validación del Token al servidor.",
      })
    );

    const validateInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}training/user/validateToken`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            Payload: { ...TokenInfo },
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se validar el token");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await validateInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Token validado",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo validar el token",
        })
      );
    }
  };
};
export const IniciarSesion = (usuario, email, conferecenId) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Iniciando Sesión",
        message: "",
      })
    );
    const loginInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}training/user/login`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            Payload: { Email: usuario.email, password: usuario.password },
            ConferecenId: conferecenId,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se realizar el inicio de sesión");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await loginInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Bienvenido",
            message: response.data.fullName,
          })
        );
        dispatch(
          userActions.setuser({
            usuario: {
              email: response.data.email,
              fullName: response.data.fullName,
            },
          })
        );
        localStorage.setItem("token", response.data.token);
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo realizar el inicio de sesiónn",
        })
      );
    }
  };
};

export const ForgotPasswordAction = (usuario, email, conferecenId) => {
  return async (dispatch) => {
    const loginInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}training/user/forgotpassword`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            ConferecenId: conferecenId,
            Payload: { Email: usuario.email },
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo solicitar el cambio de contraseña");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await loginInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          userActions.setuser({
            usuario: {
              email: response.data.email,
              fullName: response.data.fullName,
            },
          })
        );
        localStorage.setItem("token", response.data.token);
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Solicitud realizada",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo solicitar el cambio de contraseña",
        })
      );
    }
  };
};

export const ChangePasswordAction = (
  TokenInfo,
  usuario,
  email,
  conferecenId
) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Solicitando Cambio de contraseña",
        message: "Solicitando Cambio de contraseña al servidor.",
      })
    );
    const validateInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}training/user/changepassword`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            ConferecenId: conferecenId,
            Payload: { id: usuario.id, password: usuario.password },
            TokenValidation: { ...TokenInfo },
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo realizar el cambio de contraseña ");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await validateInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "cambio de contraseña completado",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo realizar el cambio de contraseña",
        })
      );
    }
  };
};

export const SendUserMeetingRequest = (
  emailSender,
  idReceiver,
  conferenceId,
  moreInfo
) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Enviando Solicitud",
        message: "Enviando solicitud al Usuario.",
      })
    );

    const getUsuarioInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}user/sendUserMeetingRequest`,
        {
          method: "POST",
          body: JSON.stringify({
            EmailSender: emailSender,
            IdReceiver: idReceiver,
            ConferenceId: conferenceId,
            MoreInfo: moreInfo,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo crear el usuario");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await getUsuarioInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          userActions.setuser({
            usuario: {
              email: response.data.email,
              nombres: response.data.nombres,
              apellidos: response.data.apellidos,
              telefonoCelular: response.data.telefonoCelular,
              pais: response.data.pais,
              ciudad: response.data.ciudad,
              direccion: response.data.direccion,
              entidadLabora: response.data.entidadLabora,
              cargo: response.data.cargo,
              departamento: response.data.departamento,
              identificacion: response.data.identificacion,
              fechaCreacion: response.data.fechaCreacion,
            },
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Usuario Creado",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          userActions.setuser({
            usuario: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo crear el usuario",
        })
      );
    }
  };
};
