import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  GetCompany,
  UpdateCompany,
} from "../../../store/company-store/company-actions";
import CompanyForms from "../../../components/Forms/CompanyForms";

const CompanyEdit = () => {
  const param = useParams();
  const dispatch = useDispatch();
  const company = useSelector((state) => state.company.company);
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    if (company.id===undefined || company.id === 0) {
      dispatch(GetCompany(param.companyId, auth.UserInfo.email));
    }
  }, [param.companyId, auth.UserInfo.email, dispatch, company]);

  return (
    <>
      <CompanyForms actionCompany={UpdateCompany} company={company} />
    </>
  );
};
export default CompanyEdit;
