import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GetOrdenType, UpadateOrdenType } from "../../../store/ordentype-store/ordentype-actions";
import OrdenTypeForm from "../../../components/Forms/OrdenTypeForm";

const OrdenTypeEdit = () => {
  const param = useParams();
  const dispatch = useDispatch();
  const ordenType = useSelector((state) => state.ordenType.ordenType);
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(GetOrdenType(param.ordenTypeId, auth.UserInfo.email));
  }, [param.ordenTypeId, auth.UserInfo.email, dispatch]);

  return (
    <>
      <OrdenTypeForm actionOrdenType={UpadateOrdenType} ordenType={ordenType} />
    </>
  );
};
export default OrdenTypeEdit;
