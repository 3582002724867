import { useRef } from "react";

import { Avatar } from "primereact/avatar";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import { useNavigate } from "react-router-dom";

const UserMenu = (props) => {
  const menuLeft = useRef(null);
  const navigate = useNavigate();
  const items = [
    {
      template: () => {
        return (
          <Button
            label={props.userName}
            size="small"
            style={{
              width: "100%",
              marginTop: "0%",
              backgroundColor: "#143047",
              color: "#fff",
              border: "none",
              borderBottom: "solid rgb(255, 255, 255)",
              borderBottomStyle: "double",
            }}
          />
        );
      },
    },
    {
      template: () => {
        return (
          <>
            {props.ordens && (
              <Button
                label="Ver órdenes"
                severity="secondary"
                style={{
                  width: "100%",
                  marginTop: "1%",
                  backgroundColor: "#255885",
                }}
                onClick={() => {
                  navigate(props.ordens);
                }}
              />
            )}
          </>
        );
      },
    },
    {
      template: () => {
        return (
          <>
            {props.changePassword && (
              <Button
                label="Cambio de contraseña"
                size="small"
                style={{
                  width: "100%",
                  marginTop: "1%",
                  backgroundColor: "#255885",
                }}
                onClick={() => {
                  navigate(props.changePassword);
                }}
              />
            )}
          </>
        );
      },
    },
    {
      template: () => {
        return (
          <>
            <Button
              label="Cerrar sesión"
              severity="danger"
              size="small"
              icon="pi pi-fw pi-power-off"
              style={{
                width: "100%",
                marginTop: "1%",
              }}
              onClick={props.logoutHandler}
            />
          </>
        );
      },
    },
  ];
  return (
    <>
      <Menu
        model={items}
        popup
        ref={menuLeft}
        id="popup_menu_left"
        style={{ backgroundColor: "#143047", border: "solid #fff" }}
      />
      <Avatar
        icon="pi pi-user"
        size="xlarge"
        shape="circle"
        onClick={(event) => menuLeft.current.toggle(event)}
        style={{ marginTop: "1%" }}
      />
    </>
  );
};
export default UserMenu;
