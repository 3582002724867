import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  GetDepartment,
  UpdateDepartment,
} from "../../../store/department-store/department-actions";
import DepartmentForms from "../../../components/Forms/DepartmentForms";

const DepartmentEdit = () => {
  const param = useParams();
  const dispatch = useDispatch();
  const department = useSelector((state) => state.department.department);
  const auth = useSelector((state) => state.auth);
  useEffect(() => {
    if (department.id===undefined || department.id === 0) {
      dispatch(GetDepartment(param.departmentId, auth.UserInfo.email));
    }
  }, [param.departmentId, auth.UserInfo.email, dispatch, department]);

  return (
    <>
      <DepartmentForms actionDepartment={UpdateDepartment} department={department} />
    </>
  );
};
export default DepartmentEdit;
