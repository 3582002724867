import { uiActions } from "../ui-slice";

export const UploadReports = (file, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Subiendo Archivo",
        message: "Subiendo Archivo..",
      })
    );
    let formData = new FormData();

    formData.append("File", file);
    formData.append("UserName", email);

    const UploadReportsInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/DataLab/UploadReport`,
        {
          method: "POST",
          body: formData,
          headers: {
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo subir el documento");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await UploadReportsInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Documento subido correctamente",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo subir el documento",
        })
      );
    }
  };
};
