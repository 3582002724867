import { useSelector } from "react-redux";
import { CreateConferenceEvent } from "../../../store/conferencesEvent-store/conferenceEvent-actions";
import ConferenceEventForm from "../../../components/Forms/ConferenceEventForm";

const ConferenceEventCreate = () => {
  const conferenceEvent = useSelector((state) => state.conferenceEvent.conferenceEvent);

  return (
    <>
      <ConferenceEventForm   
        actionConference={CreateConferenceEvent}
        conferenceEvent={conferenceEvent}
      />
    </>
  );
};
export default ConferenceEventCreate;
