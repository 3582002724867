import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GetPaymentMethods } from "../../../store/paymentmethod-store/paymentmethod-actions";
import ABADatatable from "../../../components/ABADatatable";
import { paymentMethodActions } from "../../../store/paymentmethod-store/paymentmethod-slice";
import { Button } from "primereact/button";

const PaymentMethodHomePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const paymentMethod = useSelector((state) => state.paymentMethod);
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    if (auth.roles !== undefined && auth.roles.includes("paymentmethod.showAll")) {
      dispatch(GetPaymentMethods(auth.UserInfo.email));
    }
  }, [dispatch, auth]);

  const viewDetailHanlder = (val) => {
    navigate(`/orden/paymentmethod/detail/${val.id}`);
  };
  const editHanlder = (val) => {
    navigate(`/orden/paymentmethod/edit/${val.id}`);
  };
  const createHanlder = () => {
    dispatch(
      paymentMethodActions.setPaymentMethod({
        paymentMethod: {
          Descripcion: "",
        },
      })
    );
    navigate(`/orden/paymentmethod/create`);
  };
  const backHanlder = () => {
    navigate(`/`);
  };
  let actions = [];
  const buttons = (rowData) => {
    let buttons = [];
    const buttonEdit = (
      <Button
        label="Editar"
        icon="pi pi-file-edit"
        className="p-button-rounded p-button-success p-button-text"
        onClick={() => {
          editHanlder(rowData);
        }}
      />
    );
    const buttonView = (
      <Button
        label="Ver"
        icon="pi pi-info-circle"
        className="p-button-rounded p-button-text"
        onClick={() => {
          viewDetailHanlder(rowData);
        }}
      />
    );
    if (auth.roles !== undefined && auth.roles.includes("paymentmethod.edit")) {
      buttons.push(buttonEdit);
    }
    if (auth.roles !== undefined && auth.roles.includes("paymentmethod.view")) {
      buttons.push(buttonView);
    }
    return buttons;
  };
  actions.push(buttons);

  const columns = [
    {
      field: "name",
      header: "Tipo de orden",
      filterMatchMode: "contains",
    },
    {
      field: "status",
      header: "Estado",
      filterMatchMode: "contains",
    },
  ];
  return (
    <>
      <ABADatatable
        title="Métodos de pago"
        values={paymentMethod.paymentMethods}
        rows={5}
        rowsPerPageOptions={[5, 10, 20]}
        Columns={columns}
        actions={actions}
        createButton={{
          label: "Crear Método de pago",
          onClick: createHanlder,
          permission: "paymentmethod.create",
        }}
        backButton={{
          label: "Regresar",
          onClick: backHanlder,
          permission: "goBack",
        }}
      />
    </>
  );
};
export default PaymentMethodHomePage;
