import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { GetSponsorships } from "../../../store/sponsor-store/sponsor-actions";
import ABADatatable from "../../../components/ABADatatable";
import { sponsorActions } from "../../../store/sponsor-store/sponsor-slice";

const SponsorsHomePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sponsorship = useSelector((state) => state.sponsorship);
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    if (
      auth.roles !== undefined &&
      auth.roles.includes("sponsorship.showAll")
    ) {
      dispatch(GetSponsorships(auth.UserInfo.email));
    }
  }, [dispatch, auth]);

  const createHanlder = () => {
    dispatch(
      sponsorActions.setsponsorship({
        sponsorship: {
          Id: 0,
          CompanyName: "",
          WebUrl: "",
          Country: "",
          City: "",
          Phone: "",
          Email: "",
          ConferenceId: 0,
          Description: "",
          Multinational: false,
        },
      })
    );
    navigate(`/admin/createsponsorship`);
  };
  let actions = [];
  const buttons = (rowData) => {
    let buttons = [];
    const buttonPayLink = (
      <Button
        label="Link de Pago"
        icon="pi pi-money-bill"
        className="p-button-rounded p-button-success p-button-text"
        onClick={() => {
          navigate(`/admin/sponsorship/linkPayment/${rowData.id}`);
        }}
      />
    );
    const buttonDetail = (
      <Button
        label="Ver"
        icon="pi pi-info-circle"
        className="p-button-rounded p-button-text"
        onClick={() => {
          navigate(`/admin/sponsorship/detail/${rowData.id}`);
        }}
      />
    );

    if (auth.roles !== undefined && auth.roles.includes("sponsorship.edit")) {
      buttons.push(buttonPayLink);
    }
    if (auth.roles !== undefined && auth.roles.includes("sponsorship.view")) {
      buttons.push(buttonDetail);
    }
    return buttons;
  };
  actions.push(buttons);

  const multinationalBodyTemplate = (sponsorship) => {
    let isMultinational = "No";
    if (sponsorship.multinational) {
      isMultinational = "Si";
    }

    return isMultinational;
  };

  const columns = [
    {
      field: "companyName",
      header: "Empresa",
      filterMatchMode: "contains",
    },
    {
      field: "conference.name",
      header: "Congreso",
      filterMatchMode: "contains",
    },

    {
      field: "email",
      header: "Email Contacto",
      filterMatchMode: "contains",
    },
    {
      header: "Multinational",
      body: multinationalBodyTemplate,
      filterMatchMode: "contains",
    },
  ];

  return (
    <>
      <ABADatatable
        title="Patrocinadores"
        values={sponsorship.sponsorships}
        rows={5}
        rowsPerPageOptions={[5, 10, 20]}
        Columns={columns}
        actions={actions}
        width="20%"
        createButton={{
          label: "Crear Patrocinador",
          onClick: createHanlder,
          permission: "sponsorship.create",
        }}
        to="/admin/createsponsorship"
      />
    </>
  );
};
export default SponsorsHomePage;
