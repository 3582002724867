import { useSelector } from "react-redux";
import { CreateDepartment } from "../../../store/department-store/department-actions"; 
import DepartmentForms from "../../../components/Forms/DepartmentForms";

const DepartmentCreate = () => {
  const department = useSelector((state) => state.department.department);

  return (
    <>
      <DepartmentForms actionDepartment={CreateDepartment} department={department} />
    </>
  );
};
export default DepartmentCreate;
