import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  conference: {},
  conferenceEvents: [],
  questions: [],
  conferenceEvent: {
    id: 0,
    conferenceId: 0,
    name: "",
    startTime: "00:00",
    endTime: "00:00",
    location: "",
    eventDate: new Date().toDateString(),
  },
  conferenceEventValidation: {
    isValid: true,
    name: {
      isValid: true,
      errorMessage: "El campo nombre no debe estar vacio",
    },
    conferenceId: {
      isValid: true,
      errorMessage: "El campo descripción no debe estar vacio",
    },
  },
};

const conferenceEventSlice = createSlice({
  name: "conferenceEvent",
  initialState,
  reducers: {
    restartState(state) {
      state.conference = {};
      state.conferenceEvent = {};
      state.conferenceEvents = [];
      state.questions = [];
    },
    setConference(state, actions) {
      state.conference = actions.payload.conference;
    },
    setConferenceEvent(state, actions) {
      state.conferenceEvent = actions.payload.conferenceEvent;
    },
    setConferenceEvents(state, actions) {
      state.conferenceEvents = actions.payload;
    },
    setQuestions(state, actions) {
      state.questions = actions.payload;
    },
    
    modifyPropertyValue(state, actions) {
      state.conferenceEvent[actions.payload.id] = actions.payload.value;
    },
    setConferenceValidation(state, actions) {
      state.conferenceEventValidation = {
        name: {
          ...state.conferenceEventValidation.Name,
          isValid: actions.payload.Name !== "",
        },
        conferenceId: {
          ...state.conferenceEventValidation.ConferenceId,
          isValid: actions.payload.ConferenceId > 0,
        },
      };

      state.conferenceEventValidation.isValid =
        state.conferenceEventValidation.name.isValid &&
        state.conferenceEventValidation.conferenceId.isValid;
    },
    setConferenceValid(state, actions) {
      state.conferenceEventValidation.DiscountCode.isValid =
        actions.payload.isValid;
      state.conferenceEventValidation.DiscountCode.errorMessage =
        actions.payload.errorMessage;
    },
    setFieldValid(state, actions) {
      state.conferenceEventValidation[actions.payload.id].isValid =
        actions.payload.value;
    },
  },
});

export const conferenceEventActions = conferenceEventSlice.actions;
export default conferenceEventSlice;
