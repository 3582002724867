import { useSelector } from "react-redux";
import { CreateCompany } from "../../../store/company-store/company-actions";
import CompanyForms from "../../../components/Forms/CompanyForms";

const CompanyCreate = () => {
  const company = useSelector((state) => state.company.company);

  return (
    <>
      <CompanyForms actionCompany={CreateCompany} company={company} />
    </>
  );
};
export default CompanyCreate;
