import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  GetConferenceById,
  UpadateConference,
} from "../../../store/conferences-store/conference-actions";
import ConferenceForms from "../../../components/Forms/ConferenceForm";

const ConferencesEdit = () => {
  const param = useParams();
  const dispatch = useDispatch();
  const conference = useSelector((state) => state.conference.conference);
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(GetConferenceById(param.conferenceId, auth.UserInfo.email));
  }, [param.conferenceId, auth.UserInfo.email, dispatch]);

  return (
    <>
      <ConferenceForms
        actionConference={UpadateConference}
        conference={conference}
      />
    </>
  );
};
export default ConferencesEdit;
