import { useSelector } from "react-redux";
import { CreateSponsorship } from "../../../store/sponsor-store/sponsor-actions"; 
import SponsorshipForm from "../../../components/Forms/SponsorshipForm";

const SponsorCreate = () => {
  const sponsorship = useSelector((state) => state.sponsorship.sponsorship);

  return (
    <>
      <SponsorshipForm   
        actionSponsorship={CreateSponsorship}
        sponsorship={sponsorship}
      />
    </>
  );
};
export default SponsorCreate;
