import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  GetConferenceEventById,
  UpdatedConferenceEvent,
} from "../../../store/conferencesEvent-store/conferenceEvent-actions";
import ConferenceEventForm from "../../../components/Forms/ConferenceEventForm";

const ConferenceEventEdit = () => {
  const param = useParams();
  const dispatch = useDispatch();
  const conferenceEvent = useSelector(
    (state) => state.conferenceEvent.conferenceEvent
  );
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    if (
      conferenceEvent &&
      (conferenceEvent.id === undefined || conferenceEvent.id === 0)
    ) {
      dispatch(
        GetConferenceEventById(param.conferenceEventId, auth.UserInfo.email)
      );
    }
  }, [auth.UserInfo.email, conferenceEvent, dispatch, param.conferenceEventId]);
  return (
    <>
      {conferenceEvent && conferenceEvent.id > 0 && (
          <ConferenceEventForm
            actionConference={UpdatedConferenceEvent}
            conferenceEvent={conferenceEvent}
            editMode={true}
          />
        )}
    </>
  );
};
export default ConferenceEventEdit;
