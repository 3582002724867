import { ordenActions } from "./orden-slice";
import { uiActions } from "../ui-slice";
import { fileActions } from "../file-slice";
import { saveAs } from "file-saver";

export const CreateOrder = (order, userName, users) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Creando Orden",
        message: "Creando orden al servidor.",
      })
    );

    const createOrderInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/orden/create`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: userName,
            ...order,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo crear la orden");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await createOrderInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          ordenActions.setOrden({
            orden: response.data,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          ordenActions.setOrden({
            orden: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo crear la orden",
        })
      );
    }
  };
};
export const CreateOrderByAdmin = (payOrder, userName, users, isConference) => {
  let usuarios = [];
  if (users !== undefined) {
    usuarios = users.map((usuario) => {
      return {
        Email: usuario.email,
        Nombres: usuario.nombres,
        Apellidos: usuario.apellidos,
        TelefonoCelular: usuario.telefonoCelular,
        Pais: usuario.pais,
        Ciudad: usuario.ciudad,
        Direccion: usuario.direccion,
        EntidadLabora: usuario.entidadLabora,
        Cargo: usuario.cargo,
        Departamento: usuario.departamento,
        Identificacion: usuario.identificacion,
      };
    });
  }
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Creando Orden",
        message: "Creando orden al servidor.",
      })
    );

    const createOrderInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}Orden/createByAdmin`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: userName,
            IsConference: isConference,
            Payload: {
              ...payOrder,
            },
            usuarios: users !== undefined ? usuarios : null,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo crear la orden");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await createOrderInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          ordenActions.setOrden({
            orden: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Datos Cargados",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          ordenActions.setOrden({
            orden: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo crear la orden",
        })
      );
    }
  };
};
export const GetOrdensById = (OrdenId, UserName) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Solicitando Ordenes",
        message: "Solicitando ordenes al servidor.",
      })
    );
    const getOrdensInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}training/Orden/getOrdenById`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: UserName,
            Payload: {
              OrdenId: OrdenId,
            },
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudieron obtener los usuarios");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await getOrdensInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          ordenActions.setOrden({
            orden: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Datos Cargados",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          ordenActions.setOrden({
            orden: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo obtener las ordenes",
        })
      );
    }
  };
};
export const GetOrdenByConferenceId = (UserName, ConferenceId) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Solicitando Ordenes",
        message: "Solicitando ordenes al servidor.",
      })
    );
    const getOrdensInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}training/Orden/getOrdenByConferenceId`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: UserName,
            ConferecenId: ConferenceId,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudieron obtener los usuarios");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await getOrdensInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          ordenActions.setOrden({
            orden: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Datos Cargados",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          ordenActions.setOrden({
            orden: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo obtener las ordenes",
        })
      );
    }
  };
};
export const RemoveUser = (ordenId, usuarioId, price, userName) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Removiendo participante",
        message: "Removiendo participante a la orden # " + ordenId,
      })
    );
    const updateOrderInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/orden/removeUser`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: userName,
            Id: ordenId,
            User: {
              Id: usuarioId,
            },
            Conference: {
              Price: price,
            },
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo actualizar la orden");
      }
      const data = await response.json();
      return data;
    };
    try {
      await updateOrderInfo();
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo actualizar la orden",
        })
      );
    }
  };
};
export const AddUser = (ordenId, user, userName) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Agregando participante",
        message: "Agregando participante a la orden # " + ordenId,
      })
    );
    const updateOrderInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/orden/addUser`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: userName,
            Id: ordenId,
            User: user,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo actualizar la orden");
      }
      const data = await response.json();
      return data;
    };
    try {
      await updateOrderInfo();
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo actualizar la orden",
        })
      );
    }
  };
};
export const DownloadPaymentTransfer = (ordenId, userName) => {
  return async (dispatch) => {
    const updateOrderInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/orden/paymentReceipt`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: userName,
            Id: ordenId,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo encontrar el recibo de pago");
      }
      const data = await response.blob();
      const filename = response.headers.get("Content-Disposition");

      return { blob: data, filename };
    };
    try {
      const response = await updateOrderInfo();
      saveAs(response.blob, response.filename);
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo actualizar la orden",
        })
      );
    }
  };
};
export const CreateQuote = (quote, userName) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Creando Orden",
        message: "Creando orden al servidor.",
      })
    );

    const createOrderInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/orden/createQuote`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: userName,
            User: quote.user,
            Conference: quote.conference,
            DiscountCode: quote.discountCode,
            PaymentMethod: quote.paymentMethod,
            Amount: quote.amount,
            Category: quote.category,
            OrdenType: quote.ordenType,
            QuantityOfUsers: quote.quantity,
            Status: quote.status,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo crear la orden");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await createOrderInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          ordenActions.setQuote({
            quote: response.data,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          ordenActions.setOrden({
            orden: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo crear la orden",
        })
      );
    }
  };
};
export const SendQuote = (quote, file, userName) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Enviado Cotización",
        message: "Enviando cotización al servidor",
      })
    );
    let formData = new FormData();
    formData.append("Id", quote.orderNumber);
    formData.append("Name", quote.user.name);
    formData.append("LastName", quote.user.lastName);
    formData.append("Email", quote.user.email);
    formData.append("file", file);
    formData.append("UserName", userName);

    const createOrderInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/orden/quote`,
        {
          method: "POST",
          body: formData,
          headers: {
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Aqui>?");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await createOrderInfo();
      if (response.result.errorCode === "OK") {
        dispatch(ordenActions.restartState());
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          ordenActions.setOrden({
            orden: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "el lio",
        })
      );
    }
  };
};
export const SendBill = (bill, file, userName) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Enviado Cotización",
        message: "Enviando cotización al servidor",
      })
    );
    let formData = new FormData();
    formData.append("Id", bill.orderNumber);
    formData.append("Name", bill.user.name);
    formData.append("LastName", bill.user.lastName);
    formData.append("Email", bill.user.email);
    formData.append("file", file);
    formData.append("UserName", userName);

    const createOrderInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/orden/bill`,
        {
          method: "POST",
          body: formData,
          headers: {
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Aqui>?");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await createOrderInfo();
      if (response.result.errorCode === "OK") {
        dispatch(ordenActions.restartState());
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          ordenActions.setOrden({
            orden: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "el lio",
        })
      );
    }
  };
};
export const UpdateOrdenStatus = (ordenId, statusId, userName) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Actualizando Estado",
        message: "Actualizando Estado de la Orden",
      })
    );
    const updateOrderInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/orden/updateStatus`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: userName,
            Id: ordenId,
            OrdenStatusId: statusId,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo actualizar la orden");
      }
      const data = await response.json();
      return data;
    };
    try {
      await updateOrderInfo();
      dispatch(
        uiActions.showNotification({
          status: "success",
          title: "Datos Cargados",
          message:"Estado de la orden actualizado",
        })
      );
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo actualizar la orden",
        })
      );
    }
  };
};
export const UpdateDiscountCodeMethod = (ordenId, discountCode, userName) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Actualizando Código de Descuento",
        message: "Actualizando Código de Descuento de la Orden",
      })
    );
    const updateOrderInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/orden/updateDiscountCode`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: userName,
            Id: ordenId,
            DiscountCode: discountCode,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo actualizar la orden");
      }
    
      const data = await response.json();
      return data;
    };
    try {
      await updateOrderInfo();
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo actualizar la orden",
        })
      );
    }
  };
};
export const UpdatePaymenMethod = (ordenId, paymentMethodId, userName) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Actualizando Método de pago",
        message: "Actualizando Método de pago de la Orden",
      })
    );
    const updateOrderInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/orden/updatePaymentMethod`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: userName,
            Id: ordenId,
            PaymentMethodId: paymentMethodId,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo actualizar la orden");
      }
      const data = await response.json();
      return data;
    };
    try {
      await updateOrderInfo();
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo actualizar la orden",
        })
      );
    }
  };
};
export const UpdateTaxbill = (ordenId, taxbill, rnc, userName) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Actualizando comprbante de pago",
        message: "Actualizando comprbante de pago de la Orden",
      })
    );
    const updateOrderInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/orden/updateTaxBill`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: userName,
            Id: ordenId,
            TaxBill: taxbill,
            RNC: rnc,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo actualizar la orden");
      }
      const data = await response.json();
      return data;
    };
    try {
      await updateOrderInfo();
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo actualizar la orden",
        })
      );
    }
  };
};

export const UploadFileOrder = (file, paymentId, userName) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Subiendo comprobante de pago",
        message: "Subiendo comprobante de pago al servidor.",
      })
    );
    const updateOrderInfo = async () => {
      let formData = new FormData();
      formData.append("UserName", userName);
      formData.append("PaymentId", paymentId);
      formData.append("File", file);

      const response = await fetch(
        `${process.env.REACT_APP_URL}Orden/uploadFile`,
        {
          method: "POST",
          body: formData,
          headers: {
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo actualizar la orden");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await updateOrderInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Comprobante de pago",
            message: response.result.message,
          })
        );

        dispatch(
          fileActions.setIsload({
            file: {
              isLoad: true,
            },
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo actualizar la orden",
        })
      );
    }
  };
};
