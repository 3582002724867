import { createSlice } from "@reduxjs/toolkit";

const initialAuthState = {
  UserInfo: {
    id: "",
    name: "",
    email: "",
  },
  isAuthenticate: false,
  roles: [],
};

const AuthSlice = createSlice({
  name: "auth",
  initialState: initialAuthState,
  reducers: {
    login(state, action) {
      state.UserInfo = action.payload.UserInfo;
      state.isAuthenticate = action.payload.isAuthenticate;
      state.roles = action.payload.roles;
    },
    logout(state, action) {
      state.UserInfo = {
        id: "",
        name: "",
        email: "",
      };
      state.isAuthenticate = false;
      state.roles = [];
    },
  },
});
export const authActions = AuthSlice.actions;
export default AuthSlice;
