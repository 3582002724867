import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GetStatus, UpadateStatus } from "../../../store/ordenstatus-store/ordenstatus-actions";
import OrdenStatusForms from "../../../components/Forms/OrdenStatusForms";

const OrdenStatusEdit = () => {
  const param = useParams();
  const dispatch = useDispatch();
  const status = useSelector((state) => state.ordenStatus.status);
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(GetStatus(param.statusId, auth.UserInfo.email));
  }, [param.statusId, auth.UserInfo.email, dispatch]);

  return (
    <>
      <OrdenStatusForms actionStatus={UpadateStatus} status={status} />
    </>
  );
};
export default OrdenStatusEdit;
