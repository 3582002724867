import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import YesNoModal from "./YesNoModal";
import { InputText } from "primereact/inputtext";
import { conferenceActions } from "../store/conferences-store/conference-slice";

const AddConferenceInfo = (props) => {
  const dispatch = useDispatch();
  const conference = useSelector((state) => state.conference.conference);
  const [optionConference, setOptionConference] = useState({
    name: "",
    url: "",
  });

  const AddHandler = () => {
    const conferenceMenuInfoes = [...conference.conferenceMenuInfoes];
    conferenceMenuInfoes.push({ ...optionConference });
    
    dispatch(
      conferenceActions.setConferenceMenuInfoes({ conferenceMenuInfoes })
    );
    props.setAddOptionVisible(false);
  };

  const headerOptionConference = (
    <>
      <span style={{ fontSize: "x-large" }}>
        Agregar opción de menu conferenciaƒ
      </span>
    </>
  );
  const contentOptionConference = (
    <>
      <div className="formgrid grid">
        <div className="field col-12 md:col-12">
          <label
            htmlFor="taxBill"
            style={{ fontSize: "large", color: "#35c4dc" }}
          >
            Nombre:
          </label>
          <InputText
            id="name"
            value={optionConference.name}
            onChange={(e) =>
              setOptionConference({
                ...optionConference,
                name: e.target.value,
              })
            }
            style={{
              width: "100%",
              borderRadius: "6px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              height: "50px",
            }}
          />
        </div>
        <div className="field col-12 md:col-12">
          <label htmlFor="rnc" style={{ fontSize: "large", color: "#35c4dc" }}>
            Url:
          </label>

          <InputText
            id="url"
            value={optionConference.url}
            onChange={(e) =>
              setOptionConference({
                ...optionConference,
                url: e.target.value,
              })
            }
            style={{
              width: "100%",
              borderRadius: "6px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              height: "50px",
            }}
          />
        </div>
      </div>
    </>
  );
  return (
    <>
      <YesNoModal
        header={headerOptionConference}
        content={contentOptionConference}
        visible={props.addOptionVisible}
        setVisible={props.setAddOptionVisible}
        yesHandler={AddHandler}
        showButton={true}
        yesButtonLabel="Guardar"
        icon="pi pi-save"
      />
    </>
  );
};
export default AddConferenceInfo;
