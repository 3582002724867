import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";
import { dolarDiarioActions } from "../../store/dolar-store/dolardiario-slice";
import { uiActions } from "../../store/ui-slice";

const DolarDiarioForms = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isValid = useSelector(
    (state) => state.dolarDiario.dolarDiarioValidation
  );
  const auth = useSelector((state) => state.auth);
  const notification = useSelector((state) => state.ui.notification);
  const dolardiario = props.dolarDiario;

  const onChangeHandler = (id, value) => {
    dispatch(dolarDiarioActions.modifyPropertyValue({ id, value }));
    if (value) {
      dispatch(dolarDiarioActions.setFieldValid({ id, value: true }));
    } else {
      dispatch(dolarDiarioActions.setFieldValid({ id, value: false }));
    }
  };
  const getFormErrorMessage = () => {
    return !isValid.tcDolar.isValid ? (
      <small className="p-error">{isValid.tcDolar.errorMessage}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };
  const onSubmitHandler = (event) => {
    event.preventDefault();

    if (!isValid.isValid) {
      dispatch(
        uiActions.showNotification({
          status: "warning",
          title: "Advertencia",
          message: "Existen valores invalidos, favor de revisar.",
        })
      );
    } else {
      dispatch(props.actionDolarDiario(dolardiario, auth.UserInfo.email));
    }
  };

  return (
    <>
      <div className="mb-4 ml-5 bg-blue-900 p-3">
        <form onSubmit={onSubmitHandler}>
          <h6 style={{ color: "white", fontSize: "x-large" }}>
            Información general
          </h6>
          <div className="row g-3">
            <div className="col-md-6">
              <span className="p-float-label mt-4">
                <InputNumber
                  key="tcDolar"
                  value={dolardiario.tcDolar}
                  onChange={(e) => onChangeHandler("tcDolar", e.value)}
                  locale="en-US"
                  minFractionDigits={2}
                />
                <label
                  style={{ fontSize: "large", color: "white" }}
                  htmlFor={dolardiario.tcDolar}
                >
                  Valor del dolar
                </label>
              </span>
              {getFormErrorMessage()}
            </div>
          </div>
          <div className="pt-4">
            <Button
              rounded
              text
              raised
              style={{ backgroundColor: "#fff" }}
              label="Regresar"
              icon="pi pi-arrow-left"
              className="p-button-danger"
              onClick={() => {
                navigate("/");
              }}
            />
            {auth.roles !== undefined &&
              auth.roles.includes("dolardiario.update") && (
                <Button
                  rounded
                  text
                  raised
                  style={{ backgroundColor: "#fff", marginLeft: "1%"}}
                  label="Guardar"
                  className="p-button-success"
                  icon={
                    notification && notification.status === "pending"
                      ? "pi pi-spin pi-spinner"
                      : "pi pi-save"
                  }
                  disabled={notification && notification.status === "pending"}
                />
              )}
          </div>
        </form>
      </div>
    </>
  );
};
export default DolarDiarioForms;
