import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  paymentMethods: [],
  paymentMethod: {
    Id: 0,
    description: "",
    name: "",
    Status: false,
    creationDate: new Date().toDateString(),
    creationUser: new Date().toDateString(),
  },
  paymentMethodValidation: {
    isValid: true,
    name: {
      isValid: true,
      errorMessage: "El campo metodo de pago no debe estar vacio",
    },
  },
};

const paymentMethodSlice = createSlice({
  name: "paymentmethod",
  initialState,
  reducers: {
    restartState(state) {
      state.paymentMethod = {};
      state.paymentMethods = [];
    },
    setPaymentMethods(state, actions) {
      state.paymentMethods = actions.payload.paymentMethods;
      state.paymentMethod = {};
    },
    setPaymentMethod(state, actions) {
      state.paymentMethod = actions.payload.paymentMethod;
      state.paymentMethods = [];
    },
    modifyPropertyValue(state, actions) {
      state.paymentMethod[actions.payload.id] = actions.payload.value;
    },
    setPaymentMethodValidation(state, actions) {
      state.paymentMethodValidation = {
        name: {
          ...state.paymentMethodValidation.name,
          isValid: actions.payload.name !== "",
        },
      };
      state.paymentMethodValidation.isValid =
        state.paymentMethodValidation.description.isValid;
    },
    setFieldValid(state, actions) {
      state.paymentMethodValidation.name.isValid =
        actions.payload.value;
    },
  },
});

export const paymentMethodActions = paymentMethodSlice.actions;
export default paymentMethodSlice;
