import { Card } from "primereact/card";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { GetSponsorshipById } from "../../../store/sponsor-store/sponsor-actions";
import UserAccordion from "../../../components/Capacitaciones/UserAccordion";
import { Image } from "primereact/image";
import { Button } from "primereact/button";

const SponsorDetail = () => {
  const auth = useSelector((state) => state.auth);
  const sponsorship = useSelector((state) => state.sponsorship.sponsorship);
  const [conference, setConference] = useState(undefined);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    if (sponsorship && (sponsorship.Id <= 0 || sponsorship.Id === undefined)) {
      dispatch(GetSponsorshipById(params.sponsorshipId, auth.UserInfo.email));
    }
  }, [auth.UserInfo.email, dispatch, params.sponsorshipId, sponsorship]);

  useEffect(() => {
    if (sponsorship && sponsorship.Conference !== undefined) {
      setConference({
        Id: sponsorship.Conference.id,
        Name: sponsorship.Conference.name,
        Description: sponsorship.Conference.description,
        Location: sponsorship.Conference.location,
        Price: sponsorship.Conference.price,
        SoldOut: sponsorship.Conference.soldOut,
        Status: sponsorship.Conference.status,
        StartDate: sponsorship.Conference.startDate,
        EndDate: sponsorship.Conference.endDate,
        ImageUrl: sponsorship.Conference.imageUrl,
        LoginUrl: sponsorship.Conference.loginUrl,
        creationDate: sponsorship.Conference.creationDate,
        creationUser: sponsorship.Conference.creationUser,
        updateDate: sponsorship.Conference.updateDate,
        updateUser: sponsorship.Conference.updateUser,
      });
    }
  }, [auth.UserInfo.email, dispatch, params.conferenceId, sponsorship]);
  return (
    <>
      {conference !== undefined && (
        <Card
          style={{
            backgroundColor: "#0d3c61",
            marginLeft: "1%",
            marginTop: "8px",
            paddingBottom: "10px",
            boxShadow: "none",
            width: "97%",
            border: "solid #326fd1",
          }}
        >
          <div
            style={{
              backgroundColor: "#fff",
              paddingBottom: "80px",
              paddingLeft: "10px",
              boxShadow: "none",
              width: "97%",
              height: "100%",
            }}
          >
            <div className="formgrid grid" style={{ marginBottom: "-50px" }}>
              <div className="field col-12 md:col-12 mt-3">
                <label
                  style={{
                    color: "#000",
                    fontSize: "large",
                    fontWeight: "bolder",
                  }}
                >
                  Nombre del evento:
                </label>
                <label
                  style={{
                    marginLeft: "1%",
                    fontSize: "large",
                  }}
                >
                  {conference.Name.toUpperCase()}
                </label>
              </div>
              <div className="field col-12 md:col-6">
                <label
                  style={{
                    color: "#000",
                    fontSize: "large",
                    fontWeight: "bolder",
                  }}
                >
                  Logo:
                </label>
                <Image
                  src={sponsorship.WebUrl}
                  alt="Image"
                  preview
                  width="250"
                />
              </div>
              <div className="field col-12 md:col-6">
                <label
                  style={{
                    color: "#000",
                    fontSize: "large",
                    fontWeight: "bolder",
                  }}
                >
                  Empresa:
                </label>
                <label
                  style={{
                    marginLeft: "1%",
                    fontSize: "large",
                  }}
                >
                  {sponsorship.CompanyName.toUpperCase()}
                </label>
              </div>
              <div className="field col-12 md:col-4">
                <label
                  style={{
                    color: "#000",
                    fontSize: "large",
                    fontWeight: "bolder",
                  }}
                >
                  País:
                </label>
                <label
                  style={{
                    marginLeft: "1%",
                    fontSize: "large",
                  }}
                >
                  {sponsorship.Country.toUpperCase()}
                </label>
              </div>
              <div className="field col-12 md:col-4">
                <label
                  style={{
                    color: "#000",
                    fontSize: "large",
                    fontWeight: "bolder",
                  }}
                >
                  Ciudad:
                </label>
                <label
                  style={{
                    marginLeft: "1%",
                    fontSize: "large",
                  }}
                >
                  {sponsorship.City}
                </label>
              </div>
              <div className="field col-12 md:col-4">
                <label
                  style={{
                    color: "#000",
                    fontSize: "large",
                    fontWeight: "bolder",
                  }}
                >
                  Teléfono:
                </label>
                <label
                  style={{
                    marginLeft: "1%",
                    fontSize: "large",
                  }}
                >
                  {sponsorship.Phone}
                </label>
              </div>

              <div className="field col-12 md:col-12">
                <label
                  style={{
                    color: "#000",
                    fontSize: "large",
                    fontWeight: "bolder",
                  }}
                >
                  Descripción:
                </label>
                <label
                  style={{
                    marginLeft: "1%",
                    fontSize: "large",
                  }}
                >
                  {sponsorship.Description}
                </label>
              </div>
              <div className="field col-12 md:col-12">
                <UserAccordion usuarios={sponsorship.Users} />
              </div>
              <div className="field col-12 md:col-4">
                <Button
                  label="Atrás"
                  className="p-button-secondary"
                  style={{
                    width: "100%",
                    border: "solid 0.5px #2196F3",
                    borderRadius: "7px",
                  }}
                  onClick={() => {
                    navigate("/admin/sponsorships");
                  }}
                />
              </div>
            </div>
          </div>
        </Card>
      )}
    </>
  );
};
export default SponsorDetail;
