import { useSelector } from "react-redux";
import { CreatePaymentMethod } from "../../../store/paymentmethod-store/paymentmethod-actions";
import PaymentMethodForms from "../../../components/Forms/PaymentMethodForm";

const PaymentMethodCreate = () => {
  const paymentMethod = useSelector((state) => state.paymentMethod.paymentMethod);

  return (
    <>
      <PaymentMethodForms actionPaymentMethod={CreatePaymentMethod} paymentMethod={paymentMethod} />
    </>
  );
};
export default PaymentMethodCreate;
