import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Message } from "primereact/message";
import { Button } from "primereact/button";
import { Card } from "primereact/card";

import { courseActions } from "../../store/courses-store/course-slice";
import { useState } from "react";
import { Steps } from "primereact/steps";

import CourseStep1 from "../CourseSteps/CourseStep1";
import CourseStep2 from "../CourseSteps/CourseStep2";
import CourseStep3 from "../CourseSteps/CourseStep3";
import "./CourseForms.css";
import CourseStep4 from "../CourseSteps/CourseStep4";
import CourseStep5 from "../CourseSteps/CourseStep5";
import Loading from "../UI/Loading";

const CourseForms = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  const isValid = useSelector((state) => state.course.courseValidation);
  const notification = useSelector((state) => state.ui.notification);
  const [activeIndex, setActiveIndex] = useState(0);

  const [showLoading, setShowLoading] = useState(false);

  const course = props.course;
  const onChangeHandler = (id, value) => {
    if (id === "courseCategory" || id === "courseType") {
      dispatch(
        courseActions.modifyPropertyValue({
          id,
          value: {
            id: value.code,
          },
        })
      );
    } else {
      dispatch(courseActions.modifyPropertyValue({ id, value }));
    }
    if (
      id !== "courseModule" &&
      id !== "zoomUrl" &&
      id !== "videoUrl" &&
      id !== "template" &&
      id !== "image" &&
      id !== "brochure" &&
      id !== "isAsync" &&
      id !== "isOpen"
    ) {
      if (value !== "") {
        dispatch(courseActions.setFieldValid({ id, value: true }));
      } else {
        dispatch(courseActions.setFieldValid({ id, value: false }));
      }
    }
  };

  const onSubmitHandler = () => {
    const newCorse = { ...course };

    dispatch(props.actionCourse(newCorse, auth.UserInfo.email));
    setShowLoading(true);
    setTimeout(() => {
      window.location.href = "/courses";
    }, 6000);
  };
  const getFormErrorMessage = (id) => {
    return !isValid[id].isValid ? (
      <Message
        severity="warn"
        text={isValid[id].errorMessage}
        style={{ width: "100%" }}
      />
    ) : (
      <small className="p-warn">&nbsp;</small>
    );
  };

  const validateStep = () => {
    if (activeIndex === 0) {
      if (course.topic === "" || course.topic === undefined) {
        dispatch(courseActions.setFieldValid({ id: "topic", value: false }));
      } else if (
        course.shortdescription === "" ||
        course.shortdescription === undefined
      ) {
        dispatch(
          courseActions.setFieldValid({ id: "shortdescription", value: false })
        );
      } else if (
        course.description === "" ||
        course.description === undefined
      ) {
        dispatch(
          courseActions.setFieldValid({ id: "description", value: false })
        );
      } else if (
        course.courseCategory.id === 0 ||
        course.courseCategory.id === undefined
      ) {
        dispatch(
          courseActions.setFieldValid({ id: "courseCategory", value: false })
        );
      } else if (
        course.courseType.id === 0 ||
        course.courseType.id === undefined
      ) {
        dispatch(
          courseActions.setFieldValid({ id: "courseType", value: false })
        );
      } else if (course.startDate === "" || course.startDate === undefined) {
        dispatch(
          courseActions.setFieldValid({ id: "startDate", value: false })
        );
      } else if (course.endDate === "" || course.endDate === undefined) {
        dispatch(courseActions.setFieldValid({ id: "endDate", value: false }));
      } else if (course.startTime === "" || course.startTime === undefined) {
        dispatch(
          courseActions.setFieldValid({ id: "startTime", value: false })
        );
      } else if (course.endTime === "" || course.endTime === undefined) {
        dispatch(courseActions.setFieldValid({ id: "endTime", value: false }));
      } else if (course.duration === 0 || course.duration === undefined) {
        dispatch(courseActions.setFieldValid({ id: "duration", value: false }));
      } else if (course.price === 0 || course.price === undefined) {
        dispatch(courseActions.setFieldValid({ id: "price", value: false }));
      } else if (
        !course.courseDays.monday &&
        !course.courseDays.tuesday &&
        !course.courseDays.wednesday &&
        !course.courseDays.thursday &&
        !course.courseDays.friday &&
        !course.courseDays.saturday &&
        !course.courseDays.sunday
      ) {
        dispatch(
          courseActions.setFieldValid({ id: "courseDays", value: false })
        );
      } else {
        const next = activeIndex + 1;
        setActiveIndex(next);
      }
    } else if (activeIndex === 1) {
      if (course.courseModule.length === 0) {
        dispatch(
          courseActions.setFieldValid({ id: "courseModule", value: false })
        );
      } else {
        const next = activeIndex + 1;
        setActiveIndex(next);
      }
    } else if (activeIndex === 2) {
      if (
        course.courseTeachers.length === 0 ||
        course.courseTeachers === undefined
      ) {
        dispatch(
          courseActions.setFieldValid({ id: "courseTeachers", value: false })
        );
      } else {
        const next = activeIndex + 1;
        setActiveIndex(next);
      }
    } else if (activeIndex === 3) {
      if (course.courseTeachers.length > 0) {
        let hasModules = false;
        for (let index = 0; index < course.courseTeachers.length; index++) {
          const teacher = course.courseTeachers[index];
          if (teacher.modules !== undefined && teacher.modules.length > 0) {
            hasModules = true;
          }
        }
        if (hasModules) {
          const next = activeIndex + 1;
          setActiveIndex(next);
        } else {
          dispatch(
            courseActions.setFieldValid({
              id: "courseTeacherModule",
              value: false,
            })
          );
        }
      }
    }
  };

  const buttons = (
    <>
      <div className="formgrid grid pr-3 pl-3">
        {activeIndex === 0 && (
          <div className="field col-12 md:col-6">
            <Button
              label="Regresar"
              icon="pi pi-arrow-left"
              className="p-button-danger"
              style={{ marginLeft: "1%", borderRadius: "6px", width: "100%" }}
              onClick={() => {
                navigate("/courses");
              }}
            />
          </div>
        )}
        {activeIndex > 0 && activeIndex < 5 && (
          <div className="field col-12 md:col-6">
            <Button
              label="Regresar"
              icon="pi pi-arrow-left"
              className="p-button-danger"
              onClick={() => {
                const back = activeIndex - 1;
                setActiveIndex(back);
              }}
              style={{
                width: "100%",
                border: "solid 0.5px #2196f3",
                borderRadius: "6px",
              }}
            />
          </div>
        )}
        {activeIndex < 4 && (
          <div className="field col-12 md:col-6">
            <Button
              label="Siguiente"
              type="button"
              icon="pi pi-arrow-right"
              className="p-button-primary"
              onClick={() => {
                validateStep();
              }}
              style={{ width: "100%", borderRadius: "6px" }}
            />
          </div>
        )}
        {activeIndex === 4 && (
          <div className="field col-12 md:col-6">
            <Button
              label="Guardar"
              className="p-button-success"
              onClick={() => {
                onSubmitHandler();
              }}
              icon={
                notification && notification.status === "pending"
                  ? "pi pi-spin pi-spinner"
                  : "pi pi-save"
              }
              style={{ width: "100%", borderRadius: "6px" }}
            />
          </div>
        )}
      </div>
    </>
  );
  return (
    <>
      {showLoading && <Loading visible={showLoading} />}
      {!showLoading && (
        <Card
          style={{
            backgroundColor: "#142f47",
            boxShadow: "none",
            border: "solid #fff 0.5px",
            width: "97%",
            marginTop: "0%",
            padding: "0px",
            height: "740px",
          }}
        >
          <div className="card">
            <Steps
              activeIndex={activeIndex}
              style={{
                backgroundColor: "#142f47",
              }}
            />
          </div>
          <div className="formgrid grid">
            <div
              className="field col-12 md:col-12"
              style={{
                padding: "0px",
                marginBottom: "0px",
                maxHeight: "730px",
              }}
            >
              {activeIndex === 0 && (
                <CourseStep1
                  course={course}
                  onChangeHandler={onChangeHandler}
                  getFormErrorMessage={getFormErrorMessage}
                />
              )}
              {activeIndex === 1 && (
                <CourseStep2
                  course={course}
                  onChangeHandler={onChangeHandler}
                  getFormErrorMessage={getFormErrorMessage}
                />
              )}
              {activeIndex === 2 && (
                <CourseStep3
                  course={course}
                  getFormErrorMessage={getFormErrorMessage}
                />
              )}
              {activeIndex === 3 && (
                <CourseStep4
                  course={course}
                  getFormErrorMessage={getFormErrorMessage}
                />
              )}
              {activeIndex === 4 && (
                <CourseStep5
                  course={course}
                  onChangeHandler={onChangeHandler}
                />
              )}
            </div>
            <div className="field col-12 md:col-3" style={{ zIndex: "100" }}>
              {buttons}
            </div>
          </div>
        </Card>
      )}
    </>
  );
};
export default CourseForms;
