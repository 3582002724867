import { CreateCourse } from "../../../store/courses-store/course-actions";
import {  useSelector } from "react-redux";
import CourseForms from "../../../components/Forms/CourseForms";


const CourseCreate = () => {
  const course = useSelector((state) => state.course.course);
  return (
    <>
      <CourseForms actionCourse={CreateCourse} course={course} />
    </>
  );
};
export default CourseCreate;
