import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { uiActions } from "../../store/ui-slice";
import { Button } from "primereact/button";
import UserFormDetail from "./UserFormDetail";
import { getTokenInfo, validateToken } from "../../utils/Guard";
import { useEffect, useState } from "react";
import { GetSponsorshipById } from "../../store/sponsor-store/sponsor-actions";
import { userActions } from "../../store/user-store/user-slice";

let restartPage = false;

const UserForm = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  const user = props.user;
  const notification = useSelector((state) => state.ui.notification);
  const userinfo = getTokenInfo();
  const [autoFocus, setAutoFocus] = useState({
    name: false,
    lastName: false,
    identification: false,
    email: false,
    cellPhone: false,
    country: false,
  });
  useEffect(() => {
    if (autoFocus.name) {
      document.getElementById("name").focus();
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    } else if (autoFocus.lastName) {
      document.getElementById("lastName").focus();
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    } else if (autoFocus.identification) {
      document.getElementById("identification").focus();
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    } else if (autoFocus.email) {
      document.getElementById("email").focus();
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    } else if (autoFocus.cellPhone) {
      document.getElementById("cellPhone").focus();
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    } else if (autoFocus.country) {
      document.getElementById("country").focus();
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [
    autoFocus.cellPhone,
    autoFocus.country,
    autoFocus.email,
    autoFocus.identification,
    autoFocus.lastName,
    autoFocus.name,
  ]);

  useEffect(() => {
    if (!validateToken()) {
      if (
        userinfo &&
        props.create &&
        user.id &&
        user.id > 0 &&
        userinfo.sponsorId &&
        userinfo.sponsorId > 0
      ) {
        dispatch(GetSponsorshipById(userinfo.sponsorId, userinfo.email));
        navigate("SponsorShip/AllUsers");
      }
    }
  }, [dispatch, navigate, props.create, userinfo, user.id]);
  useEffect(() => {
    if (
      notification &&
      notification.status === "success" &&
      auth.UserInfo.email &&
      user.id &&
      user.id > 0 &&
      restartPage
    ) {
      dispatch(userActions.restartState());
      window.location.href = "/users";
    }
  }, [auth.UserInfo.email, dispatch, navigate, notification, user.id]);

  const onSubmitHandler = (event) => {
    event.preventDefault();
    if (user.name === "") {
      dispatch(userActions.setFieldValid({ id: "name", value: false }));
      setAutoFocus({ name: true });
    } else if (user.lastName === "") {
      dispatch(userActions.setFieldValid({ id: "lastName", value: false }));
      setAutoFocus({ lastName: true });
    } else if (user.email === "") {
      dispatch(userActions.setFieldValid({ id: "email", value: false }));
      setAutoFocus({ email: true });
    } else if (user.identification === "") {
      dispatch(
        userActions.setFieldValid({ id: "identification", value: false })
      );
      setAutoFocus({ identification: true });
    } else if (user.cellPhone === "") {
      dispatch(userActions.setFieldValid({ id: "cellPhone", value: false }));
      setAutoFocus({ cellPhone: true });
    } else if (user.country === "") {
      dispatch(userActions.setFieldValid({ id: "country", value: false }));
      setAutoFocus({ country: true });
    }

    if (
      user.name === "" ||
      user.lastName === "" ||
      user.email === "" ||
      user.identification === "" ||
      user.cellPhone === "" ||
      user.country === ""
    ) {
      dispatch(
        uiActions.showNotification({
          status: "warning",
          title: "Advertencia",
          message: "Existen valores invalidos, favor de revisar.",
        })
      );
    } else {
      if (!validateToken() || auth.UserInfo.email) {
        dispatch(props.actionUser(user, auth.UserInfo.email));
        restartPage = true;
      } else {
        props.actionUser(user);
      }
    }
  };

  const onClickHandler = () => {
    navigate(props.goBack ? props.goBack : "/users");
  };

  const buttons = (
    <div className="pt-4">
      <Button
        rounded
        text
        raised
        style={{ backgroundColor: "#fff" }}
        label="Regresar"
        icon="pi pi-arrow-left"
        className="p-button-danger"
        onClick={onClickHandler}
      />
      <Button
        rounded
        text
        raised
        label="Guardar"
        className="p-button-success"
        style={{ marginLeft: "1%", backgroundColor: "#fff" }}
        icon={
          notification && notification.status === "pending"
            ? "pi pi-spin pi-spinner"
            : "pi pi-save"
        }
        disabled={notification && notification.status === "pending"}
      />
    </div>
  );

  return (
    <>
      <UserFormDetail
        user={user}
        onSubmitHandler={onSubmitHandler}
        buttons={buttons}
        notEmailEdit={props.notEmailEdit}
        autoFocus={autoFocus}
      />
    </>
  );
};
export default UserForm;
