import { conferenceEventActions } from "./conferenceEvent-slice";
import { uiActions } from "../ui-slice";

export const GetConferenceEvents = (email, conferenceId) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Solicitando conferencias",
        message: "Solicitando las conferencias del congreso.",
      })
    );
    const GetConferenceInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/conferences/event/getall`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            Id: conferenceId,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo obtener las conferencias");
      }
      const data = await response.json();
      return data;
    };

    try {
      const response = await GetConferenceInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          conferenceEventActions.setConference({ conference: response.data })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Datos Cargados",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          conferenceEventActions.setConferenceEvents({
            conferenceEvents: [],
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo obtener congresos",
        })
      );
    }
  };
};
export const GetConferenceEventById = (id, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Solicitando Conferencia",
        message: "Solicitando conferencia al servidor.",
      })
    );
    const getConferenceInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/conferences/event/getById`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            Id: id,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo obtener la conferencia");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await getConferenceInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          conferenceEventActions.setConferenceEvent({
            conferenceEvent: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Datos Cargados",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          conferenceEventActions.setConferenceEvent({
            conferenceEvent: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo obtener la conferencia",
        })
      );
    }
  };
};
export const GetQuestionByEventId = (conferenceEventId, email) => {
  return async (dispatch) => {
    // dispatch(
    //   uiActions.showNotification({
    //     status: "pending",
    //     title: "Solicitando Preguntas",
    //     message: "Solicitando preguntas al servidor.",
    //   })
    // );
    const getQuestionInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}conference/event/getall/questions`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            ConferenceEventId: conferenceEventId,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo obtener la conferencia");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await getQuestionInfo();
      if (response.result.errorCode === "OK") {
        dispatch(conferenceEventActions.setQuestions(response.data));
        // dispatch(
        //   uiActions.showNotification({
        //     status: "success",
        //     title: "Datos Cargados",
        //     message: response.result.message,
        //   })
        // );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pueden obtener las preguntas",
        })
      );
    }
  };
};
export const AddQuestion = (questionEvent, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Enviando Pregunta",
        message: "Enviando pregunta al servidor.",
      })
    );
    const getConferenceInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}conference/event/add/question`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            ConferenceId: questionEvent.ConferenceId,
            ConferenceEventId: questionEvent.ConferenceEventId,
            Question: questionEvent.Question,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo obtener la conferencia");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await getConferenceInfo();
      if (response.result.errorCode === "OK") {
        // dispatch(
        //   conferenceEventActions.setConferenceEvent({
        //     conferenceEvent: {
        //       Id: response.data.id,
        //       ConferenceId: response.data.conferenceId,
        //       Name: response.data.name,
        //       StartTime: response.data.startTime,
        //       EndTime: response.data.endTime,
        //       Location: response.data.location,
        //       EventDate: response.data.eventDate,
        //       Rating: response.data.rating,
        //       StartQuestions: response.data.startQuestions,
        //       StartRating: response.data.startRating,
        //       Speakers: response.data.speakers,
        //       Questions: response.data.questions,
        //     },
        //   })
        // );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Datos Cargados",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          conferenceEventActions.setConferenceEvent({
            conferenceEvent: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo obtener la conferencia",
        })
      );
    }
  };
};
export const AddQuestionRating = (id, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Votando por la pregunta",
        message: "Enviando pregunta al servidor.",
      })
    );
    const getConferenceInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}conference/event/add/question/rating`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            Id: id,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo obtener la conferencia");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await getConferenceInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          conferenceEventActions.setConferenceEvent({
            conferenceEvent: {
              Id: response.data.id,
              ConferenceId: response.data.conferenceId,
              Name: response.data.name,
              StartTime: response.data.startTime,
              EndTime: response.data.endTime,
              Location: response.data.location,
              EventDate: response.data.eventDate,
              Rating: response.data.rating,
              StartQuestions: response.data.startQuestions,
              StartRating: response.data.startRating,
              Speakers: response.data.speakers,
              Questions: response.data.questions,
            },
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Datos Cargados",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          conferenceEventActions.setConferenceEvent({
            conferenceEvent: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo obtener la conferencia",
        })
      );
    }
  };
};
export const AddConferenceEventRating = (conferenceEvent, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Valorando la conferencia",
        message: "Enviando valoración al servidor.",
      })
    );
    const getConferenceInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}conference/event/add/rating`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            Payload: {
              ConferenceId: conferenceEvent.ConferenceId,
              ConferenceEventId: conferenceEvent.ConferenceEventId,
              Rating: conferenceEvent.Rating,
            },
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo obtener la conferencia");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await getConferenceInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          conferenceEventActions.setConferenceEvent({
            conferenceEvent: {
              Id: response.data.id,
              ConferenceId: response.data.conferenceId,
              Name: response.data.name,
              StartTime: response.data.startTime,
              EndTime: response.data.endTime,
              Location: response.data.location,
              EventDate: response.data.eventDate,
              Rating: response.data.rating,
              StartQuestions: response.data.startQuestions,
              StartRating: response.data.startRating,
              Speakers: response.data.speakers,
              Questions: response.data.questions,
            },
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Datos Cargados",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          conferenceEventActions.setConferenceEvent({
            conferenceEvent: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo obtener la conferencia",
        })
      );
    }
  };
};
export const AddSpeakerEventRating = (conferenceEvent, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Valorando la conferencia",
        message: "Enviando valoración al servidor.",
      })
    );
    const getConferenceInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}conference/event/add/speaker/rating`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            Payload: {
              ConferenceId: conferenceEvent.ConferenceId,
              ConferenceEventId: conferenceEvent.ConferenceEventId,
              SpeakerId: conferenceEvent.SpeakerId,
              Rating: conferenceEvent.Rating,
            },
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo obtener la conferencia");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await getConferenceInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          conferenceEventActions.setConferenceEvent({
            conferenceEvent: {
              Id: response.data.id,
              ConferenceId: response.data.conferenceId,
              Name: response.data.name,
              StartTime: response.data.startTime,
              EndTime: response.data.endTime,
              Location: response.data.location,
              EventDate: response.data.eventDate,
              Rating: response.data.rating,
              StartQuestions: response.data.startQuestions,
              StartRating: response.data.startRating,
              Speakers: response.data.speakers,
              Questions: response.data.questions,
            },
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Datos Cargados",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          conferenceEventActions.setConferenceEvent({
            conferenceEvent: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo obtener la conferencia",
        })
      );
    }
  };
};
export const UpdatedConferenceEvent = (conferenceEvent, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Solicitando Actualización de Conferencia",
        message: "Solicitando actualización al servidor.",
      })
    );
    const getConferenceInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/conferences/event/update`,
        {
          method: "POST",
          body: JSON.stringify({
            ...conferenceEvent,
            UserName: email,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo obtener la conferencia");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await getConferenceInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          conferenceEventActions.setConferenceEvent({
            conferenceEvent: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Datos Cargados",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          conferenceEventActions.setConferenceEvent({
            conferenceEvent: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo obtener la conferencia",
        })
      );
    }
  };
};
export const CreateConferenceEvent = (conferenceEvent, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Solicitando Creación de Conferencia",
        message: "Solicitando actualización al servidor.",
      })
    );
    const getConferenceInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/conferences/event/create`,
        {
          method: "POST",
          body: JSON.stringify({
            ...conferenceEvent,
            UserName: email,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo obtener la conferencia");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await getConferenceInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          conferenceEventActions.setConferenceEvent({
            conferenceEvent: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Datos Cargados",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          conferenceEventActions.setConferenceEvent({
            conferenceEvent: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo obtener la conferencia",
        })
      );
    }
  };
};
export const AddSpeaker = (conferenceEvent, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Solicitando Creación de Conferencia",
        message: "Solicitando actualización al servidor.",
      })
    );
    const getConferenceInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/conferences/event/addspeaker`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            ConferenceId: conferenceEvent.conferenceId,
            ConferenceEventId: conferenceEvent.id,
            UserId: conferenceEvent.userId,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo obtener la conferencia");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await getConferenceInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          conferenceEventActions.setConferenceEvent({
            conferenceEvent: {
              Id: response.data.id,
              ConferenceId: response.data.conferenceId,
              Name: response.data.name,
              StartTime: response.data.startTime,
              EndTime: response.data.endTime,
              Location: response.data.location,
              EventDate: response.data.eventDate,
              Rating: response.data.rating,
              StartQuestions: response.data.startQuestions,
              StartRating: response.data.startRating,
              Speakers: response.data.speakers,
              Questions: response.data.questions,
            },
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Datos Cargados",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          conferenceEventActions.setConferenceEvent({
            conferenceEvent: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo obtener la conferencia",
        })
      );
    }
  };
};
export const RemoveSpeaker = (Id, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Solicitando Creación de Conferencia",
        message: "Solicitando actualización al servidor.",
      })
    );
    const getConferenceInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/conferences/event/removespeaker`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            Id: Id,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo obtener la conferencia");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await getConferenceInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          conferenceEventActions.setConferenceEvent({
            conferenceEvent: {
              Id: response.data.id,
              ConferenceId: response.data.conferenceId,
              Name: response.data.name,
              StartTime: response.data.startTime,
              EndTime: response.data.endTime,
              Location: response.data.location,
              EventDate: response.data.eventDate,
              Rating: response.data.rating,
              StartQuestions: response.data.startQuestions,
              StartRating: response.data.startRating,
              Speakers: response.data.speakers,
              Questions: response.data.questions,
            },
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Datos Cargados",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          conferenceEventActions.setConferenceEvent({
            conferenceEvent: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo obtener la conferencia",
        })
      );
    }
  };
};
