import { useSelector } from "react-redux";
import { CreateCharge } from "../../../store/charge-store/charge-actions"; 
import ChargeForms from "../../../components/Forms/ChargeForms";

const ChargeCreate = () => {
  const charge = useSelector((state) => state.charge.charge);

  return (
    <>
      <ChargeForms actionCharge={CreateCharge} charge={charge} />
    </>
  );
};
export default ChargeCreate;
