import PaymentDetail from "../Payments/PaymentDetail";
import Loading from "../UI/Loading";
import YesNoModal from "../YesNoModal";

const OrdenDetailComponent = (props) => {
  const headerOrdenDetail = (
    <>
      {(props.orden === undefined || props.orden === null) && (
        <>
          <Loading visible={true} />
        </>
      )}
      {props.orden !== undefined && props.orden !== null && (
        <span style={{ fontSize: "x-large" }}>
          Detalle de la orden:{" "}
          <span style={{ color: "#F9C02D" }}>{props.orden.id}</span>
        </span>
      )}
    </>
  );
  const contentOrdenDetail = (
    <>
      {(props.orden === undefined || props.orden === null) && (
        <>
          <Loading visible={true} />
        </>
      )}
      {props.orden !== undefined && props.orden !== null && (
        <PaymentDetail orden={props.orden} />
      )}
    </>
  );
  return (
    <>
      <YesNoModal
        className="p-"
        header={headerOrdenDetail}
        content={contentOrdenDetail}
        visible={props.visibleOrdenDetail}
        setVisible={props.setVisibleOrdenDetail}
        showButton={false}
      />
    </>
  );
};
export default OrdenDetailComponent;
