import { courseTypeActions } from "./coursetype-slice";
import { uiActions } from "../ui-slice";

export const GetCourseTypes = (email, showNotification, getAll) => {
  return async (dispatch) => {
    if (showNotification) {
      dispatch(
        uiActions.showNotification({
          status: "pending",
          title: "Solicitando tipos de cursos",
          message: "Solicitando tipos de cursos al servidor.",
        })
      );
    }
    const GetCoursetypesInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/course/types/getall`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo obtener los tipos de cursos");
      }
      const data = await response.json();
      return data;
    };

    try {
      const response = await GetCoursetypesInfo();

      if (response.result.errorCode === "OK") {
        if (getAll) {
          dispatch(
            courseTypeActions.setcourseTypes({
              courseTypes: response.data.map((courseType) => {
                return {
                  ...courseType,
                  status: courseType.status === true ? "Activo" : "Inactivo",
                };
              }),
            })
          );
        } else {
          dispatch(
            courseTypeActions.setcourseTypes({
              courseTypes: response.data
                .filter((courseType) => courseType.status === true)
                .map((courseType) => {
                  return {
                    ...courseType,
                  };
                }),
            })
          );
        }
        if (showNotification) {
          dispatch(
            uiActions.showNotification({
              status: "success",
              title: "Datos Cargados",
              message: response.result.message,
            })
          );
        }
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          courseTypeActions.setcourseTypes({
            courseTypes: [],
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo obtener los tipos de cursos",
        })
      );
    }
  };
};

export const GetCourseType = (id, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Solicitando Tipo de curso",
        message: "Solicitando tipo de curso al servidor.",
      })
    );
    const getOrdenTypeInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/course/types/getById`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            Id: id,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo obtener el tipo de curso");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await getOrdenTypeInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          courseTypeActions.setcourseType({
            courseType: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Datos Cargados",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          courseTypeActions.setcourseType({
            courseType: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo obtener el tipo de curso",
        })
      );
    }
  };
};

export const CreateCourseType = (courseType, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Creando Tipo de curso",
        message: "Solicitando creación del tipo de curso al servidor.",
      })
    );
    const createOrdenTypeInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/course/types/create`,
        {
          method: "POST",
          body: JSON.stringify({
            ...courseType,
            UserName: email,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo crear el tipo de curso");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await createOrdenTypeInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          courseTypeActions.setcourseType({
            courseType: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Tipo de curso Creado",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          courseTypeActions.setcourseType({
            courseType: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo crear el tipo de curso",
        })
      );
    }
  };
};

export const UpadateCourseType = (courseType, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Actualizando el tipo de curso",
        message: "Solicitando actualización del tipo de curso al servidor.",
      })
    );
    const updateOrdenTypeInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/course/types/update`,
        {
          method: "POST",
          body: JSON.stringify({
            ...courseType,
            UserName: email,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo actualizar el tipo de curso");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await updateOrdenTypeInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          courseTypeActions.setcourseType({
            courseType: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Tipo de curso actualizado",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          courseTypeActions.setcourseType({
            courseType: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo actualizar el tipo de curso",
        })
      );
    }
  };
};

export const ActiveCourseType = (id, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Activando tipo de curso",
        message: "Solicitando activación del tipo de curso al servidor.",
      })
    );
    const activeOrdenTypeInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/course/types/active`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            Id: id,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo activar el tipo de curso");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await activeOrdenTypeInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          courseTypeActions.setcourseType({
            courseType: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Tipo de curso Activado",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          courseTypeActions.setcourseType({
            courseType: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo activar el tipo de curso",
        })
      );
    }
  };
};

export const InactiveCourseType = (id, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Inactivando tipo de curso",
        message: "Solicitando inactivación del tipo de curso al servidor.",
      })
    );
    const inactiveCouretypeInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/course/types/inactive`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            Id: id,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo inactivar el tipo de curso");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await inactiveCouretypeInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          courseTypeActions.setcourseType({
            courseType: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Tipo de curso Inactivado",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          courseTypeActions.setcourseType({
            courseType: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo inactivar el tipo de curso",
        })
      );
    }
  };
};
