import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  ordens: [],
  orden: {
    id: 0,
    usuarioId: "",
    certificado: "",
    entero: "",
    tipo: "",
    origen: "",
    cantidad: 0,
    comprobante: false,
    rnc: "",
    facturaFiscal: false,
    monto: 0.0,
    tasa: 0.0,
    extraActivity: false,
    course: {
      speaker: "",
      type: "",
      topic: "",
      description: "",
      format: "",
      price: 0,
      discount: 0,
      startDate: new Date().toDateString(),
      endDate: new Date().toDateString(),
      startTime: new Date().toTimeString(),
      endTime: new Date().toTimeString(),
      status: false,
    },
    metodoPago: {
      descripcion: "",
    },
    estado: {
      descripcion: "",
    },
    ordenType: {
      descripcion: "",
    },
    discount: {
      discountCode: "",
      percentage: false,
      discountPercentage: 0.0,
      nominal: false,
      discountNominal: 0.0,
      startDate: new Date().toDateString(),
    },
    usuarios: [],
    fecha: new Date().toDateString(),
    fechaOrden: new Date().toDateString(),
  },
  quote: {},
  ordenValidation: {
    isValid: true,
    //hay que agregar validaciones
    email: {
      isValid: true,
      errorMessage: "El campo email no debe estar vacio",
    },
  },
};

const ordenSlice = createSlice({
  name: "Orden",
  initialState,
  reducers: {
    restartState(state) {
      state.orden = {};
      state.ordens = [];
      state.quote = {};
    },
    setQuote(state, actions) {
      state.quote = actions.payload.quote;
      state.ordens = [];
      state.orden = {};
    },
    setOrdens(state, actions) {
      state.ordens = actions.payload.ordens;
      state.orden = {};
    },
    setOrden(state, actions) {
      state.orden = actions.payload.orden;
      state.ordens = [];
    },
    modifyPropertyValue(state, actions) {
      state.orden[actions.payload.id] = actions.payload.orden;
    },
  },
});

export const ordenActions = ordenSlice.actions;
export default ordenSlice;
