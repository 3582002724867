import { Outlet } from "react-router-dom";
import NavBarMenu from "./NavBarMenu";
import Footer from "./Footer";
import Login from "../pages/Admin/Login";
import { authProvider } from "../store/AuthProvider";
import { AzureAD } from "react-aad-msal";
import { useSelector } from "react-redux";
import Notification from "../components/UI/Notification";
import { ScrollPanel } from "primereact/scrollpanel";
import "./ScrollSize.css"
const AdminLayout = () => {
  const auth = useSelector((state) => state.auth);
  const notification = useSelector((state) => state.ui.notification);

  return (
    <>
      <div>
        <AzureAD provider={authProvider}>
          {auth.isAuthenticate && (
            <>
              <NavBarMenu />
              {notification && (
                <Notification
                  status={notification.status}
                  title={notification.title}
                  message={notification.message}
                />
              )}

              <div>
                <div className="card scrollpanel-demo">
                  <div className="flex flex-column md:flex-row gap-5">
                    <div className="flex-auto ">
                      <ScrollPanel className="scrollsize" style={{ width: "103%", height: "800px" }}>
                        <Outlet />
                      </ScrollPanel>
                      <Footer />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </AzureAD>

        {!auth.isAuthenticate && (
          <>
            <div>
              <div className="card scrollpanel-demo">
                <div className="flex flex-column md:flex-row gap-5">
                  <div className="flex-auto ">
                    <ScrollPanel className="scrollsizeLogin" style={{ width: "100%", height: "920px" }}>
                      <Login />
                    </ScrollPanel>
                    <Footer />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default AdminLayout;
