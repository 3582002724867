import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  GetUserById,
  UpdateUser,
} from "../../../store/user-store/user-actions";
import UserForm from "../../../components/Forms/UserForm";

const UserEdit = () => {
  const param = useParams();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    if (user && user.email === undefined) {
      dispatch(GetUserById(param.userId, auth.UserInfo.email));
    }
  }, [param.userId, auth, dispatch, user]);

  return (
    <>
      <UserForm
        actionUser={UpdateUser}
        user={user}
        isCreation={false}
      />
    </>
  );
};
export default UserEdit;
