import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  GetCourse,
  UpadateCourse,
} from "../../../store/courses-store/course-actions";
import CourseForms from "../../../components/Forms/CourseForms";

const CourseEdit = () => {
  const param = useParams();
  const dispatch = useDispatch();
  const course = useSelector((state) => state.course.course);
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    if (course && course.id !== param.courseId) {
      dispatch(GetCourse(param.courseId, auth.UserInfo.email));
    }
  }, [param.courseId, auth.UserInfo.email, dispatch, course]);

  return (
    <>
      <CourseForms actionCourse={UpadateCourse} course={course} />
    </>
  );
};
export default CourseEdit;
