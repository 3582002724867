import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { GetDiscountById } from "../../../store/discount-store/discount-actions";
import { useEffect, useState } from "react";

import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";
import Loading from "../../../components/UI/Loading";

const DiscountDetail = () => {
  const param = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const discount = useSelector((state) => state.discount.discount);
  const auth = useSelector((state) => state.auth);
  const [isCourse, setIsCourse] = useState(false);
  const [isCongreso, setIsCongreso] = useState(false);

  useEffect(() => {
    dispatch(GetDiscountById(param.discountId, auth.UserInfo.email));
  }, [param.discountId, auth, dispatch]);


  return (
    <>
      {discount && discount.id === 0 && (
        <>
           <Loading visible={true} />
        </>
      )}
      {discount && discount.id && discount.id > 0 && (
        <Card
          style={{
            backgroundColor: "#183462",
            marginLeft: "1%",
            marginTop: "8px",
            boxShadow: "none",
            width: "99%",
            border: "solid #326fd1",
          }}
        >
          <div
            className="user-info text-center"
            style={{ borderBottom: "solid #fff", paddingBottom: "4px" }}
          >
            <h2
              id="header"
              className="mb-2"
              style={{ color: "#fff", textDecoration: "underline" }}
            >
              {discount.name}
            </h2>
            <span
              className="badge bg-label-secondary"
              style={{
                fontSize: "large",
                color: "lightskyblue",
                fontWeight: "bold",
              }}
            >
              Nombre Descuento
            </span>
          </div>
          <div className="formgrid grid">
            <div className="field col-12 md:col-12 mt-3">
              <label style={{ color: "#fff", fontSize: "large" }}>
                Descripción:
              </label>
              <label
                style={{
                  color: "lightskyblue",
                  marginLeft: "1%",
                  fontSize: "large",
                }}
              >
                {discount.description}
              </label>
            </div>
            {isCourse && (
              <div className="field col-12 md:col-3">
                <>
                  <label style={{ color: "#fff", fontSize: "large" }}>
                    Curso:{" "}
                  </label>
                  <label
                    style={{
                      color: "lightskyblue",
                      marginLeft: "1%",
                      fontSize: "large",
                    }}
                  >
                    {" "}
                    {discount.course.topic}
                  </label>
                </>
              </div>
            )}
            {isCongreso && (
              <div className="field col-12 md:col-3">
                <>
                  <label style={{ color: "#fff", fontSize: "large" }}>
                    Congreso:{" "}
                  </label>
                  <label
                    style={{
                      color: "lightskyblue",
                      marginLeft: "1%",
                      fontSize: "large",
                    }}
                  >
                    {" "}
                    {discount.conference.name}
                  </label>
                </>
              </div>
            )}
            <div className="field col-12 md:col-3">
              <label style={{ color: "#fff", fontSize: "large" }}>
                Código Descuento:
              </label>
              <label
                style={{
                  color: "lightskyblue",
                  marginLeft: "1%",
                  fontSize: "large",
                }}
              >
                {discount.discountCode}
              </label>
            </div>
            <div className="field col-12 md:col-3">
              {discount.percentage && (
                <i
                  className="pi pi-check"
                  style={{
                    color: "green",
                    fontSize: "18px",
                    fontWeight: "bold",
                  }}
                >
                  &nbsp;&nbsp;
                </i>
              )}
              {!discount.percentage && (
                <i
                  className="pi pi-times "
                  style={{ color: "red", fontSize: "18px", fontWeight: "bold" }}
                >
                  &nbsp;&nbsp;
                </i>
              )}
              <label style={{ color: "#fff", fontSize: "large" }}>
                Descuento Porcentual:
              </label>
              <label
                className="form-label"
                style={{ color: "lightskyblue", marginLeft: "1%" }}
              >
                {discount.discountPercentage}%
              </label>
            </div>
            <div className="field col-12 md:col-3">
              {discount.nominal && (
                <i
                  className="pi pi-check"
                  style={{
                    color: "green",
                    fontSize: "18px",
                    fontWeight: "bold",
                  }}
                >
                  &nbsp;&nbsp;
                </i>
              )}
              {!discount.nominal && (
                <i
                  className="pi pi-times "
                  style={{ color: "red", fontSize: "18px", fontWeight: "bold" }}
                >
                  &nbsp;&nbsp;
                </i>
              )}
              <label style={{ color: "#fff", fontSize: "large" }}>
                Descuento nominal:
              </label>
              <label
                className="form-label"
                style={{ color: "lightskyblue", marginLeft: "1%" }}
              >
                US$ {discount.discountNominal}.00
              </label>
            </div>
            <div className="field col-12 md:col-3">
              <label style={{ color: "#fff", fontSize: "large" }}>
                Cantidad de usos:
              </label>
              <label
                style={{
                  color: "lightskyblue",
                  marginLeft: "1%",
                  fontSize: "large",
                }}
              >
                {discount.numberOfUses}
              </label>
            </div>
            <div className="field col-12 md:col-3">
              <label style={{ color: "#fff", fontSize: "large" }}>
                Cantidad de usos disponible:
              </label>
              <label
                style={{
                  color: "lightskyblue",
                  marginLeft: "1%",
                  fontSize: "large",
                }}
              >
                {discount.availableUses}
              </label>
            </div>
            <div className="field col-12 md:col-3">
              <label style={{ color: "#fff", fontSize: "large" }}>
                Fecha de inicio:
              </label>
              <label
                style={{
                  color: "lightskyblue",
                  marginLeft: "1%",
                  fontSize: "large",
                }}
              >
                {discount.startDate}
              </label>
            </div>
            <div className="field col-12 md:col-3">
              <label style={{ color: "#fff", fontSize: "large" }}>
                Fecha fin:
              </label>
              <label
                style={{
                  color: "lightskyblue",
                  marginLeft: "1%",
                  fontSize: "large",
                }}
              >
                {discount.endDate}
              </label>
            </div>
            <div className="field col-12 md:col-3">
              <label style={{ color: "#fff", fontSize: "large" }}>
                Creado por:
              </label>
              <label
                style={{
                  color: "lightskyblue",
                  marginLeft: "1%",
                  fontSize: "large",
                }}
              >
                {discount.creationUser}
              </label>
            </div>
            <div className="field col-12 md:col-3">
              <label style={{ color: "#fff", fontSize: "large" }}>
                Es grupal:
              </label>{" "}
              {discount.grupal && (
                <i
                  className="pi pi-check"
                  style={{
                    color: "green",
                    fontSize: "18px",
                    fontWeight: "bold",
                  }}
                >
                  &nbsp;&nbsp;
                </i>
              )}
              {!discount.grupal && (
                <i
                  className="pi pi-times "
                  style={{ color: "red", fontSize: "18px", fontWeight: "bold" }}
                >
                  &nbsp;&nbsp;
                </i>
              )}
            </div>
            <div className="field col-12 md:col-3">
              <label style={{ color: "#fff", fontSize: "large" }}>
                Es Automático:
              </label>{" "}
              {discount.automatic && (
                <i
                  className="pi pi-check"
                  style={{
                    color: "green",
                    fontSize: "18px",
                    fontWeight: "bold",
                  }}
                >
                  &nbsp;&nbsp;
                </i>
              )}
              {!discount.automatic && (
                <i
                  className="pi pi-times "
                  style={{ color: "red", fontSize: "18px", fontWeight: "bold" }}
                >
                  &nbsp;&nbsp;
                </i>
              )}
            </div>
            <div className="field col-12 md:col-12">
              <Button
                rounded
                text
                raised
                style={{ backgroundColor: "#fff" }}
                label="Regresar"
                icon="pi pi-arrow-left"
                className="p-button-danger"
                onClick={() => {
                  navigate("/discounts");
                }}
              />
              {auth.roles !== undefined &&
                auth.roles.includes("discount.edit") && (
                  <Button
                    rounded
                    text
                    raised
                    style={{ backgroundColor: "#fff", marginLeft: "1%" }}
                    label="Editar"
                    icon="pi pi-file-edit"
                    className="p-button-primary"
                    onClick={() => {
                      navigate(`/discount/edit/${param.discountId}`);
                    }}
                  />
                )}
            </div>
          </div>
        </Card>
      )}
    </>
  );
};
export default DiscountDetail;
